import { Input } from 'antd';
import classNames from 'classnames';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import SearchIcon from '../../../assets/svg/icons/search';
import { IInputSearch } from './types';
import CloseBigIcon from '../../../assets/svg/icons/closeBig';

const InputSearch: FC<IInputSearch> = (props) => {
  const {
    title,
    placeholder = '',
    value = '',
    onSearch = () => {},
    inputClassName = '',
    containerClassName = '',
    isDisabledStyle = false,
    showClear = false,
    onClear,
    onPressEnter = () => {},
  } = props;

  const [realValue, setRealValue] = useState('');

  const lastCall = useRef<number>();
  const lastCallTimer = useRef<any>();

  useEffect(() => {
    if (realValue !== value) {
      setRealValue(value);
    }
  }, [value]);

  const debounce = useCallback(
    (callback: (a: string) => void, timeoutMs: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value: val } = e.target;
      setRealValue(val);
      const previousCall = lastCall.current;
      lastCall.current = Date.now();
      if (previousCall && lastCall.current - previousCall <= timeoutMs) {
        clearTimeout(lastCallTimer.current);
      }
      lastCallTimer.current = setTimeout(() => callback(val), timeoutMs);
    },
    []
  );

  const handleOnSearch = useCallback((val: string) => onSearch(val), [onSearch]);

  const handleOnClear = useCallback(() => {
    if (onClear) {
      onClear();
      return;
    }

    onSearch('');
  }, [onClear, onSearch]);

  return (
    <div className={classNames('input-search', containerClassName)}>
      <Input
        title={title}
        className={classNames('input-search__input', inputClassName, {
          'input-search__input_default-disabled': isDisabledStyle,
          'input-search__input_clear-enabled': showClear,
        })}
        placeholder={placeholder}
        value={realValue}
        onChange={debounce(handleOnSearch, 500)}
        onPressEnter={onPressEnter}
      />
      <div className="input-search__icon-wrapper">
        <SearchIcon />
      </div>
      {showClear && value && (
        <div className="input-search__icon-clear-wrapper" onClick={handleOnClear} role="presentation">
          <CloseBigIcon />
        </div>
      )}
    </div>
  );
};

export default InputSearch;
