import React, { FC, useMemo } from 'react';
import { EPaymentsFilters, IOrganizationsFilters } from './types';
import { ISelectOption } from '../ui/select/types';
import { dateFormatNoTime } from '../../constants/date';
import FiltersTool from '../ui/filtersTool';
import { EFilterTool } from '../../typings/filtersTool';

const PaymentsFilters: FC<IOrganizationsFilters> = (props) => {
  const {
    objects = [],
    eventsApiParams = {},
    apiSettings = null,
    isAdmin = false,
    companies = [],
    handleOnChangeDate = () => {},
    handleOnSearch = () => {},
    handleOnChangeSelect = () => () => {},
    statusMap = new Map(),
    setValuesFromUrl = () => {},
  } = props;

  const organizationOptions = useMemo(
    () =>
      isAdmin
        ? companies.map<ISelectOption>((object) => ({
            value: object.companyId || '',
            title: object.companyName || '',
          }))
        : [],
    [companies, isAdmin]
  );

  const statusOptions = useMemo(() => {
    const result: ISelectOption[] = [];
    statusMap.forEach((value, key) => {
      result.push({
        value: key || '',
        title: value || '',
      });
    });
    return result;
  }, [statusMap]);

  const objectOptions = useMemo(
    () =>
      objects.map((item) => ({
        value: item.objectId || '',
        title: item.objectName || '',
      })),
    [objects]
  );

  return (
    <div className="payments-filters">
      <FiltersTool
        setValuesWithDelay
        setValuesFromUrl={setValuesFromUrl}
        rows={[
          [
            {
              type: EFilterTool.select,
              id: EPaymentsFilters.companyId,
              hidden: !isAdmin,
              props: {
                isAllOption: true,
                showClear: true,
                title: 'Организация',
                value: eventsApiParams.companyId || '',
                onChange: handleOnChangeSelect('companyId'),
                options: organizationOptions,
              },
            },
            {
              type: EFilterTool.select,
              id: EPaymentsFilters.objectId,
              props: {
                isAllOption: true,
                showClear: true,
                title: 'Объект',
                value: eventsApiParams.objectId || '',
                onChange: handleOnChangeSelect('objectId'),
                options: objectOptions,
              },
            },
            {
              type: EFilterTool.select,
              id: EPaymentsFilters.status,
              props: {
                isAllOption: true,
                showClear: true,
                title: 'Статус',
                value: eventsApiParams.status || '',
                onChange: handleOnChangeSelect('status'),
                options: statusOptions,
              },
            },
            {
              type: EFilterTool.datePicker,
              id: EPaymentsFilters.date,
              props: {
                title: 'Период',
                placeholder: 'Выберите период',
                value: eventsApiParams.status || '',
                onChange: handleOnChangeDate,
                showTime: true,
                format: dateFormatNoTime,
                firstDate: eventsApiParams.startDate,
                secondDate: eventsApiParams.endDate,
              },
            },
            {
              type: EFilterTool.search,
              id: EPaymentsFilters.search,
              hidden: isAdmin,
              props: {
                placeholder: 'Поиск по ФИО, телефон, адресу',
                value: apiSettings?.search || '',
                onSearch: handleOnSearch,
              },
            },
          ],
          isAdmin
            ? [
                {
                  type: EFilterTool.search,
                  id: EPaymentsFilters.search,
                  props: {
                    placeholder: 'Поиск по ФИО, телефон, адресу',
                    value: apiSettings?.search || '',
                    onSearch: handleOnSearch,
                  },
                },
              ]
            : [],
        ]}
      />
    </div>
  );
};

export default PaymentsFilters;
