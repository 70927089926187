import { DataNode } from 'antd/es/tree';

export enum TreeNodeType {
  background = 'Background',
  object = 'Object',
  restrictedArea = 'RestrictedArea',
  building = 'Building',
  section = 'Section',
  accessPoint = 'AccessPoint',
  camera = 'Camera',
  flat = 'Flat',
  device = 'Device',
  keyDevice = 'KeyDevice',
  commutator = 'Commutator',
  undefined = 'Undefined',
  securityPostConsole = 'SecurityPostConsole',
}

export interface ITreeEntityKey {
  index: number;
  type: TreeNodeType;
}

export enum AccessPointType {
  door = 'Door',
  wicket = 'Wicket',
}

export enum CameraIconType {
  default = 'default',
}

export interface IAccessPointDeviceCategory {
  id?: string;
  displayName?: string;
}

export interface IAccessPointStatus {
  date: string;
  errors: string[];
  id: string;
  isLastVersion: boolean;
  status: string;
  warnings: string[];
}

export interface IAccessPointDevice {
  id?: string;
  isNew?: boolean;
  name?: string;
  isInput?: boolean;
  isOutput?: boolean;
  serialNumber?: string;
  deviceType?: string;
  revision?: string;
  shortName?: string;
  networkAddress?: string;
  deviceCategory?: IAccessPointDeviceCategory;
  selectedDeviceTypeId?: string;
  temporaryId?: string;
  status?: null | IAccessPointStatus;
  camExternal?: string;
}

export interface ICameraPoint {
  id?: string;
  name: string;
  isNew?: boolean;
  type: TreeNodeType;
  address?: string;
  shortName: string;
  mac?: string | null;
  layout?: string;
  camExternal?: string;
}

export interface IRelatedAccessPoint {
  accessPointId: string;
  shortName: string;
  isAutoRelation: boolean;
}

export interface IAccessPoint {
  id?: string;
  relatedAccessPoints?: IRelatedAccessPoint[];
  name: string;
  shortName: string;
  isNew?: boolean;
  layout?: string;
  type: TreeNodeType;
  deviceType?: AccessPointType;
  isEdit?: boolean;
  isSelected?: boolean;
  dataNodeProps?: any;
  inputDevice?: IAccessPointDevice | null;
  outputDevice?: IAccessPointDevice | null;
}

export interface ITreeNode {
  id?: string;
  layout?: string;
  prefix?: number | null;
  name?: string;
  shortName?: string;
  address?: string;
  isCheck?: boolean;
  type?: TreeNodeType;
  childItems?: ITreeNode[];
  children?: ITreeNode[];
  accessPoints?: IAccessPoint[];
  cameras?: ICameraPoint[];
  objectItemTypes?: TreeNodeType;
  dataNodeProps?: DataNode;
  isNew?: boolean;
  fiasId?: string;
}

export type IFoundNode = {
  node: ITreeNode | IAccessPoint | null;
  parent: ITreeNode | null;
  parentKeys: string[];
  nodeIndex: number;
};
