import { ERestrictsType, IHardwareCameraSettings, IHardwareSettings } from '../../../typings/systems/hardwareSettings';
import { InputStatus } from '../../ui/input/types';
import { ISevenThousandOptionsData } from './config';
import { getCameraNumberFieldError } from './hardware/camera/advancedCameraOptions/numberField/utils';
import { IArrayFieldError } from './hardware/device/advancedOptions/arrayField/types';
import { getArrayFieldError } from './hardware/device/advancedOptions/arrayField/utils';
import { IArrayNumberRangeFieldError } from './hardware/device/advancedOptions/arrayNumberRangeField/types';
import { getArrayNumberRangeFieldError } from './hardware/device/advancedOptions/arrayNumberRangeField/utils';
import { getNumberFieldError } from './hardware/device/advancedOptions/numberField/utils';
import { INumberRangeFieldError } from './hardware/device/advancedOptions/numberRangeField/types';
import { getNumberRangeFieldError } from './hardware/device/advancedOptions/numberRangeField/utils';
import { getStringFieldError } from './hardware/device/advancedOptions/stringField/utils';
import { emptyFieldErrorText } from './hardware/device/advancedOptions/types';
import { IHardwareInput, IHardwareOptionValue } from './types';
import { IValidHardware, invalidFieldToMainOption } from './validateButtons/types';

export const replacedOptionValues = (wasChange: boolean, settings: any, options: any) => {
  if (!wasChange) {
    Object.keys(settings).forEach((name) => {
      if (name in options) {
        options[name as keyof ISevenThousandOptionsData] = {
          ...options[name as keyof ISevenThousandOptionsData],
          value: settings[name],
          status: InputStatus.normal,
          errorText: '',
        };
      }
    });
  } else {
    Object.keys(options).forEach((name) => {
      if (name in settings) {
        settings[name] = options[name as keyof ISevenThousandOptionsData].value;
      }
    });
  }
};

export const setInvalidParams = (invalidData: IValidHardware, options: any) => {
  Object.keys(invalidData).forEach((key) => {
    const field = invalidData[key as keyof IValidHardware];
    if (typeof field === 'boolean' || !field) {
      const option = options[invalidFieldToMainOption.get(key) as keyof ISevenThousandOptionsData];
      if (option && !option.value) {
        option.status = InputStatus.error;
      }
    }
  });
};

export const findHardwareInInvalidList = (invalidHardwareList: IValidHardware[], id: string): IValidHardware | null =>
  invalidHardwareList.find((item) => item.id === id) || null;

export const updateOptionValues = (values: IHardwareOptionValue[], options: any, settings: any) => {
  const newOptionsCopy = { ...options };
  const newSettingsCopy = { ...settings };

  values.forEach((item) => {
    const { value, fieldName } = item;
    const fieldData = options[fieldName];

    if (fieldData) {
      newOptionsCopy[fieldName] = {
        ...fieldData,
        value: value || (value === false ? false : value === 0 ? 0 : null),
        errorText: '',
        status: InputStatus.normal,
      };
    }

    if (newSettingsCopy) {
      newSettingsCopy[fieldName] = value;
    }
  });
  return [newOptionsCopy, newSettingsCopy];
};

export const setError = (input: IHardwareInput, errorText = emptyFieldErrorText) => {
  input.status = InputStatus.error;
  input.errorText = errorText;
  return false;
};

export const validateOption = (name: string, options: any) => {
  const field = options[name];
  if (
    field.isRequired &&
    (typeof field.value === 'boolean'
      ? false
      : typeof field.value === 'number'
      ? !field.value && field.value !== 0
      : !field.value || !field.value.trim() || (field.test && !field.test?.test(field.value)))
  ) {
    return setError(field, field.defaultErrorText || emptyFieldErrorText);
  }
  return true;
};

export const validateMainOption = (
  name: string,
  newMainOptions: ISevenThousandOptionsData,
  errorText = emptyFieldErrorText
) => {
  const input = newMainOptions[name as keyof ISevenThousandOptionsData];
  if (input && input.isRequired && (!input.value || !input.value.trim())) {
    return setError(input, errorText);
  }
  return true;
};

export const validateDeviceAdvancedOptions = (settingsCopy: IHardwareSettings) => {
  let isValid = true;
  settingsCopy.accessPointTypeParams?.forEach((setting) => {
    switch (setting.restricts.type) {
      case ERestrictsType.string: {
        const errorText = getStringFieldError(setting);
        setting.isError = Boolean(errorText);
        setting.errorText = errorText;
        break;
      }
      case ERestrictsType.number: {
        const errorText = getNumberFieldError(setting);
        setting.isError = Boolean(errorText);
        setting.errorText = errorText;
        break;
      }
      case ERestrictsType.array: {
        const errorData: IArrayFieldError = getArrayFieldError(setting);
        if (errorData.errorText || (errorData.items?.length || 0) > 0) {
          setting.isError = true;
          setting.errorText = errorData.errorText || '';
          setting.errorData = errorData.items;
        }
        break;
      }
      case ERestrictsType.arrayNumberRange: {
        const errorData: IArrayNumberRangeFieldError = getArrayNumberRangeFieldError(setting);
        if (errorData.errorText || (errorData.items?.length || 0) > 0) {
          setting.isError = true;
          setting.errorText = errorData.errorText || '';
          setting.errorData = errorData.items;
        }
        break;
      }
      case ERestrictsType.numberRange: {
        const errorData: INumberRangeFieldError = getNumberRangeFieldError(setting);
        setting.isError = Boolean(errorData.errorText || errorData.fromErrorText || errorData.toErrorText);
        setting.errorText = errorData.errorText || '';
        setting.errorData = errorData;
        break;
      }
      default:
        break;
    }
    isValid = isValid && !setting.isError;
  });
  return isValid;
};

export const validateCameraAdvancedOptions = (cameraSettingsCopy: IHardwareCameraSettings) => {
  let isValid = true;
  cameraSettingsCopy.parameters?.forEach((param) => {
    switch (param.type) {
      case ERestrictsType.number: {
        const errorText = getCameraNumberFieldError(param);
        param.isError = Boolean(errorText);
        param.errorText = errorText;
        break;
      }
      default:
        break;
    }
    isValid = isValid && !param.isError;
  });
  return isValid;
};
