import { Key } from 'antd/lib/table/interface';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { getAccessGroupsTree, getAccessGroupsTable } from '../../../api/accessGroups';
import { accessGroupInitApiSettings, ACCESS_GROUPS_TREE_COUNT } from '../../../api/accessGroups/config';
import { getAccessGroupsTreeUrl, getAccessGroupsTableUrl, autoConfigureDeviceUrl } from '../../../constants/api';
import { useApi } from '../../../hooks/useApi';
import { IAccessGroup } from '../../../typings/systems/accessGroups';
import { ITreeNode, TreeNodeType } from '../../../typings/treeNode';
import { getAccessGroupTreeKey, getTreeKey, resolveAccessGroupTreeKey } from '../../../utils/tree';
import TabNavButtons from '../../tabs/tabNavButtons';
import { tabNavButtonsDefault } from '../../tabs/tabNavButtons/utils';
import { ISystemTabComponent } from '../types';
import AccessGroupsTable from './accessGroupsTable';
import AccessGroupsTree from './accessGroupsTree';
import { ISelectedNode } from './types';
import InputSearch from '../../ui/inputSearch';
import Checkbox from '../../ui/checkbox';
import Button from '../../ui/button';
import { ButtonType } from '../../ui/button/types';
import UniversalModal from '../../ui/universalModal';
import { IConfirmData } from '../../ui/universalModal/types';
import { defaultConfirm } from '../../ui/universalModal/config';
import { postRequest } from '../../../api';
import { EFilterTool } from '../../../typings/filtersTool';
import FiltersTool from '../../ui/filtersTool';

const AccessGroupsTab: FC<ISystemTabComponent> = (props) => {
  const {
    systemId = '',
    onNextTab = () => {},
    tabId = '',
    activeTabKey = '',
    onChangeTab = () => {},
    updateAvailableTabs = () => {},
    nextTabIsAvailable,
    permissions = {},
    settings = {},
  } = props;
  const { data: tree, sendRequest: getTree, loading: treeLoading } = useApi<ITreeNode>(getAccessGroupsTree);
  const { sendRequest: autoConfigure } = useApi<ITreeNode>(postRequest);
  const [searchValue, setSearchValue] = useState<string>('');
  const {
    data: tableData,
    sendRequest: requestTableData,
    loading: tableDataLoading,
  } = useApi<IAccessGroup>(getAccessGroupsTable, (responseTableData: IAccessGroup) => ({
    ...responseTableData,
    headers: responseTableData.headers.sort((a, b) => (a.shortName || '').localeCompare(b.shortName || '')),
  }));
  const [selectedNode, setSelectedNode] = useState<ISelectedNode | null>(null);
  const [apiSettings, setApiSettings] = useState(accessGroupInitApiSettings);

  const [confirmData, setConfirmData] = useState<IConfirmData>(defaultConfirm);

  const closeConfirm = useCallback(() => setConfirmData(defaultConfirm), []);

  useEffect(() => {
    if (tabId === activeTabKey) {
      setSelectedNode(null);
      setApiSettings(accessGroupInitApiSettings);
      getTree(getAccessGroupsTreeUrl(systemId), { params: { count: ACCESS_GROUPS_TREE_COUNT } });
    }
  }, [systemId, activeTabKey, tabId]);

  const requestData = useCallback(
    async (id = selectedNode?.itemId, reqSettings = apiSettings) => {
      if (id) {
        await requestTableData(getAccessGroupsTableUrl(id), {
          params: { ...reqSettings, type: reqSettings.type || TreeNodeType.object },
        });
      }
    },
    [apiSettings, requestTableData, selectedNode?.itemId]
  );

  const onChangeCheckbox = useCallback(
    (field: string) => (val: boolean) => {
      const newApiSettings = { ...apiSettings, [field]: !val };
      setApiSettings(newApiSettings);
      requestData(selectedNode?.itemId, newApiSettings);
    },
    [apiSettings, requestData, selectedNode?.itemId]
  );

  useEffect(() => {
    if (!selectedNode && tree) {
      const itemId = tree.id || '';
      const itemType = tree.objectItemTypes || '';
      setSelectedNode({
        itemId,
        itemType,
        originalKey: getAccessGroupTreeKey(itemId, itemType, getTreeKey('', 0, itemType as TreeNodeType)),
      });
      const newApiSettings = { ...apiSettings, search: '', type: itemType };
      requestData(itemId, newApiSettings);
      setApiSettings(newApiSettings);
    }
  }, [tree]);

  const onConfigure = useCallback(() => {
    setConfirmData({
      isOpen: true,
      description: 'Хотите проставить доступы автоматически согласно иерархии плана?',
      buttons: [
        {
          label: 'Да',
          type: ButtonType.primary,
          onClick: async () => {
            closeConfirm();
            await autoConfigure(autoConfigureDeviceUrl(systemId), {});
            requestData();
          },
        },
        {
          label: 'Нет',
          type: ButtonType.secondary,
          onClick: closeConfirm,
        },
      ],
    });
  }, [autoConfigure, closeConfirm, requestData, systemId]);

  const handleOnSearch = useCallback(
    async (value: string) => {
      setSearchValue(value);
      const newApiSettings = { ...apiSettings, page: 0, search: value };
      await requestData(selectedNode?.itemId, newApiSettings);
      setApiSettings(newApiSettings);
    },
    [apiSettings, requestData, selectedNode?.itemId]
  );

  const handleOnChangeTablePage = useCallback(
    async (page: number) => {
      const newApiSettings = { ...apiSettings, page: page - 1 };
      await requestData(selectedNode?.itemId, newApiSettings);
      setApiSettings(newApiSettings);
    },
    [apiSettings, requestData, selectedNode?.itemId]
  );

  const onSelect = useCallback(
    async (selectedKeys: Key[]) => {
      const key = selectedKeys[0];
      if (key) {
        const [itemId, itemType] = resolveAccessGroupTreeKey(key);
        if (itemType !== 'GroupFlats') {
          setSearchValue('');
          setSelectedNode({ itemId, itemType, originalKey: key.toString() });
          const newApiSettings = { ...apiSettings, search: '', type: itemType, page: 0 };
          requestData(itemId, newApiSettings);
          setApiSettings(newApiSettings);
        }
      } else {
        setSelectedNode(null);
      }
    },
    [apiSettings, requestData]
  );

  const onEditRow = useCallback(async () => {
    await requestData();
    updateAvailableTabs();
  }, [requestData, updateAvailableTabs]);

  const setValuesFromUrl = useCallback(
    (values: any) => {
      handleOnSearch(values.search);
    },
    [handleOnSearch]
  );

  return (
    <div className="access-groups-tab">
      <UniversalModal data={confirmData} onClose={closeConfirm} />

      <FiltersTool
        setValuesFromUrl={setValuesFromUrl}
        rows={[
          [
            {
              type: EFilterTool.search,
              id: 'search',
              props: {
                placeholder: 'Поиск по названию',
                value: searchValue,
                onSearch: handleOnSearch,
              },
            },
            {
              type: EFilterTool.button,
              hidden: !permissions.edit,
              props: {
                disabled: !permissions.edit,
                type: ButtonType.outline,
                onClick: onConfigure,
                children: 'Настроить автоматически',
              },
            },
          ],
        ]}
      />
      <div className="access-groups-tab__content">
        {!tree?.isCheck && (
          <div className="access-groups-tab__notification">Необходимо настроить доступы хотя бы для одного ключа.</div>
        )}
        <div className="access-groups-tab__content-wrapper">
          <div className="access-groups-tab__tree">
            <AccessGroupsTree
              loading={treeLoading}
              selectedNode={selectedNode}
              data={tree ? [tree] : []}
              onSelect={onSelect}
            />
          </div>
          <div className="access-groups-tab__content">
            <div className="access-groups-tab__filters">
              <Checkbox
                disabled={!permissions?.edit}
                label="Отображать точки доступа"
                checked={!apiSettings.hideAccessPoints}
                onChange={onChangeCheckbox('hideAccessPoints')}
              />
              {settings.camera && selectedNode?.itemType !== TreeNodeType.flat && (
                <Checkbox
                  disabled={!permissions?.edit}
                  label="Отображать камеры"
                  checked={!apiSettings.hideCameras}
                  onChange={onChangeCheckbox('hideCameras')}
                />
              )}
            </div>
            <div className="access-groups-tab__table">
              <AccessGroupsTable
                selectedNode={selectedNode}
                data={tableData}
                loading={tableDataLoading}
                onChangeTab={onChangeTab}
                onChangePage={handleOnChangeTablePage}
                systemId={systemId}
                onEditRow={onEditRow}
                isSearch={!!searchValue}
                permissions={permissions}
                settings={settings}
              />
            </div>
          </div>
        </div>
      </div>
      <TabNavButtons
        buttons={tabNavButtonsDefault(
          { isHidden: true },
          { isHidden: true },
          {
            isHidden: !permissions.edit,
            callBack: onNextTab,
            classNameOption: 'call-codes-tab__button-next',
            disabled: !nextTabIsAvailable,
          }
        )}
      />
    </div>
  );
};

export default AccessGroupsTab;
