import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { checkIsAdmin, getProfilePermission } from '../../store/selectors/profile';
import { ESidebarItemIds } from '../../typings/sidebar';
import { useApi } from '../../hooks/useApi';
import { getFileRequest, getRequest, postRequest } from '../../api';
import { setHeaderTitle } from '../../store/slices/header';
import CommonHead from '../../components/commonHead';
import { ButtonType } from '../../components/ui/button/types';
import DownloadIcon from '../../assets/svg/icons/download';
import { paths } from '../../constants/paths';
import { IRolesApiSettings } from '../../api/roles/types';
import { getRolesApiSettings } from '../../api/roles/config';
import { companyUrl, getRolesListUrl, roleExportUrl } from '../../constants/api';
import { IApiResponse } from '../../typings/api';
import { IRole } from '../../typings/roles';
import RolesTable from '../../components/roles/rolesTable';
import { ISelectOption } from '../../components/ui/select/types';
import { ISystem } from '../../typings/systems/system';
import FiltersTool from '../../components/ui/filtersTool';
import { EFilterTool } from '../../typings/filtersTool';

const RolesPage: FC = () => {
  const isAdmin = useAppSelector(checkIsAdmin);

  const { data: companies, sendRequest: getCompanies } = useApi<ISystem[]>(getRequest);
  const [companyId, setCompanyId] = useState<string | number>('');

  const dispatch = useAppDispatch();

  const permissions = useAppSelector(getProfilePermission(ESidebarItemIds.roles));

  const { data: roles, sendRequest: getRoles, loading: rolesLoading } = useApi<IApiResponse<IRole>>(postRequest);
  const { data: exportData, sendRequest: getExportData } = useApi<any>(getFileRequest);

  const navigate = useNavigate();

  const [apiSettings, setApiSettings] = useState<IRolesApiSettings>(getRolesApiSettings());

  const requestData = useCallback(
    async (reqSettings: IRolesApiSettings = apiSettings, company = companyId) => {
      setApiSettings(reqSettings);
      await getRoles(getRolesListUrl(), reqSettings, company ? { params: { companyId: company } } : {});
    },
    [apiSettings, companyId, getRoles]
  );

  useEffect(() => {
    if (isAdmin) {
      getCompanies(companyUrl());
    }
  }, [isAdmin]);

  useEffect(() => {
    requestData();
    dispatch(setHeaderTitle('Настройка ролей'));
  }, []);

  const saveFile = useCallback(async () => {
    if (exportData) {
      const fileOptions = {
        suggestedName: exportData.headers['content-disposition'].split('"')[1] || 'roles.xls',
        types: [
          {
            description: 'xls',
            accept: {
              'application/msexcel': '.xls',
            },
          },
        ],
        excludeAcceptAllOption: true,
      };
      const fileHandle = await (window as any).showSaveFilePicker(fileOptions);
      const writableStream = await fileHandle.createWritable();
      await writableStream.write(exportData.data);
      await writableStream.close();
    }
  }, [exportData]);

  useEffect(() => {
    saveFile();
  }, [exportData]);

  const handleOnChangeTablePage = useCallback(
    async (page: number) => {
      const newApiSettings = { ...apiSettings, page: page - 1 };
      await requestData(newApiSettings);
    },
    [apiSettings, requestData]
  );

  const handleOnSearch = useCallback(
    async (value: string) => {
      const newApiSettings: IRolesApiSettings = { ...apiSettings, page: 0, search: value };
      requestData(newApiSettings);
    },
    [apiSettings, requestData]
  );

  const onExport = useCallback(() => {
    getExportData(roleExportUrl());
  }, [getExportData]);

  const onCreateClick = useCallback(() => {
    navigate(`${paths.roles}/create`);
  }, [navigate]);

  const handleOnChangeCompanyId = useCallback(
    async (id: string | number) => {
      setCompanyId(id);
      requestData(apiSettings, id);
    },
    [apiSettings, requestData]
  );

  const setValuesFromUrl = useCallback(
    (values: any) => {
      const newApiSettings: IRolesApiSettings = { ...apiSettings, page: 0, search: values.search || '' };
      setCompanyId(values.companyId || '');
      requestData(newApiSettings, values.companyId || '');
    },
    [apiSettings, requestData]
  );

  const organizationOptions = useMemo(
    () =>
      isAdmin && companies
        ? companies.map<ISelectOption>((object) => ({
            value: object.id || '',
            title: object.companyName || '',
          }))
        : [],
    [companies, isAdmin]
  );

  return (
    <>
      <CommonHead seo={{ title: 'Настройка ролей' }} />
      <div className="roles">
        <FiltersTool
          setValuesFromUrl={setValuesFromUrl}
          rows={[
            [
              {
                type: EFilterTool.select,
                hidden: !isAdmin,
                id: 'companyId',
                props: {
                  isAllOption: true,
                  showClear: true,
                  title: 'Организация',
                  value: companyId,
                  onChange: handleOnChangeCompanyId,
                  options: organizationOptions,
                },
              },
              {
                type: EFilterTool.search,
                id: 'search',
                props: {
                  placeholder: 'Поиск по роли',
                  value: apiSettings.search,
                  onSearch: handleOnSearch,
                },
              },
              {
                type: EFilterTool.button,
                hidden: !permissions?.export,
                props: {
                  onClick: onExport,
                  type: ButtonType.outline,
                  rightIcon: <DownloadIcon />,
                  children: 'Экспорт',
                },
              },
              {
                type: EFilterTool.button,
                hidden: !permissions?.create,
                props: {
                  onClick: onCreateClick,
                  children: 'Создать роль',
                },
              },
            ],
          ]}
        />
        <RolesTable
          isAdmin={isAdmin}
          permissions={permissions}
          roles={roles?.items || []}
          loading={rolesLoading}
          pageSize={roles?.pageSize}
          total={roles?.totalCount}
          currentPage={roles?.page}
          onChangePage={handleOnChangeTablePage}
          isSearch={!!apiSettings.search}
          requestData={requestData}
        />
      </div>
    </>
  );
};

export default RolesPage;
