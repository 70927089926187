import React, { FC, useCallback, useEffect } from 'react';
import classNames from 'classnames';
import Select from '../../../../../../ui/select';
import { ITractsOptions } from './types';
import { useApi } from '../../../../../../../hooks/useApi';
import { getRequest } from '../../../../../../../api';
import { devicesListUrl } from '../../../../../../../constants/api';
import { ITreeNode } from '../../../../../../../typings/treeNode';
import Checkbox from '../../../../../../ui/checkbox';

const TractsOptions: FC<ITractsOptions> = (props) => {
  const {
    commutatorSettings,
    isDisabledStyle = false,
    permissions = {},
    systemId,
    inLine = false,
    isDisabled = true,
    onChange = () => {},
  } = props;

  const {
    data: devicesList,
    sendRequest: getDevicesList,
    loading: devicesListLoading,
  } = useApi<ITreeNode[]>(getRequest);

  useEffect(() => {
    getDevicesList(devicesListUrl(systemId || ''));
  }, []);

  const getValue = useCallback(
    (index: number) => commutatorSettings?.find((item) => item.sinkIndex === index),
    [commutatorSettings]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const devices = [
    ...(devicesList
      ?.filter((device) => !commutatorSettings?.find((item) => item.selectedDeviceId === device.id))
      .map((device) => ({
        title: device.name || '',
        value: device.id,
      })) || []),
    {
      title: 'Не выбрано',
      value: '',
    },
  ];

  const renderSelect = useCallback(
    (index: number) => {
      const value = getValue(index);
      return (
        <div className="tracts-options__select" key={`tract-select-${index}`}>
          <div className="tracts-options__select-content">
            <div className="tracts-options__index">{index + 1}</div>
            <Select
              loading={devicesListLoading}
              title="Подключенное оборудование"
              placement="topLeft"
              value={value?.selectedDeviceId}
              onChange={(e) => onChange(index, 'selectedDeviceId', e)}
              disabled={!permissions.edit || isDisabled}
              options={
                value?.selectedDeviceId
                  ? [
                      ...devices,
                      {
                        title: devicesList?.find((item) => item.id === value.selectedDeviceId)?.name || '',
                        value: value.selectedDeviceId,
                      },
                    ]
                  : devices
              }
              isDisabledStyle={isDisabledStyle}
            />
          </div>
          <div className="tracts-options__select-checkboxes">
            <Checkbox
              disabled={!permissions.edit || isDisabled || !value?.selectedDeviceId}
              label="Видео для абонента"
              checked={!!value?.isCameraValid}
              onChange={(e) => onChange(index, 'isCameraValid', e)}
            />
            <Checkbox
              disabled={!permissions.edit || isDisabled || !value?.selectedDeviceId}
              label="Видео для ППО"
              checked={!!value?.isGcCameraValid}
              onChange={(e) => onChange(index, 'isGcCameraValid', e)}
            />
          </div>
        </div>
      );
    },
    [devices, devicesList, devicesListLoading, getValue, isDisabled, isDisabledStyle, onChange, permissions.edit]
  );

  return (
    <div
      className={classNames('tracts-options', {
        'tracts-options_line': inLine,
      })}
    >
      {[...new Array(8)].map((_, index) => renderSelect(index))}
    </div>
  );
};

export default TractsOptions;
