import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { IInformationPanelStatus } from '../types';
import PieChart from '../../../ui/pieChart';
import ChevronDown from '../../../../assets/svg/icons/chevronDown';
import PlanIcon from '../../../../assets/svg/icons/plan';
import { paths } from '../../../../constants/paths';
import { objectInfoDefaultStatuses } from '../config';
import { getDevicesNumber, getPercent, updateStatuses } from '../functions';
import { IInformationPanelItem } from './types';
import InformationPanelDropDownItem from './DropdownItem';

const InformationPanelItem: FC<IInformationPanelItem> = ({
  showPointsName = false,
  wrapClassName,
  item,
  dispatcherPanelApiParams,
  setDispatcherPanelApiParams = () => {},
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();

  const [statuses, setStatuses] = useState<IInformationPanelStatus[]>([...objectInfoDefaultStatuses]);

  const [searchParams, setSearchParams] = useSearchParams();

  const onClick = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const filterByStatus = useCallback(
    (status: IInformationPanelStatus) => {
      const prevStatus = dispatcherPanelApiParams?.statuses && dispatcherPanelApiParams.statuses[0];
      if (
        dispatcherPanelApiParams &&
        dispatcherPanelApiParams.objectId === item?.objectId &&
        prevStatus === status.id
      ) {
        setDispatcherPanelApiParams({ ...dispatcherPanelApiParams, objectId: '', statuses: [] });
        searchParams.delete('statuses');
        searchParams.delete('objectId');
      } else {
        setDispatcherPanelApiParams({ ...dispatcherPanelApiParams, objectId: item?.objectId, statuses: [status.id] });
        searchParams.set('statuses', status.id);
        searchParams.set('objectId', item?.objectId || '');
      }
      setSearchParams(searchParams);
    },
    [dispatcherPanelApiParams, item?.objectId, searchParams, setDispatcherPanelApiParams, setSearchParams]
  );

  useEffect(() => {
    if (item) {
      setStatuses(updateStatuses(item, dispatcherPanelApiParams || {}));
    }
  }, [dispatcherPanelApiParams, item]);

  const devicesNumber = useMemo(() => getDevicesNumber(statuses), [statuses]);

  return (
    <div className={`dispatcher-info-item ${wrapClassName}`}>
      <div className="dispatcher-info-item__title" role="presentation" onClick={onClick}>
        <div
          className={classNames('dispatcher-info-item__title-arrow', {
            'dispatcher-info-item__title-arrow_open': isOpen,
          })}
        >
          <ChevronDown />
        </div>
        <div className="dispatcher-info-item__title-chart">
          <PieChart
            label={devicesNumber}
            segments={statuses.map((element) => ({
              color: element.colorCode,
              value: getPercent(element?.value, devicesNumber),
            }))}
          />
        </div>
        <div className="dispatcher-info-item__title-label">{item?.objectName}</div>
        <div
          className="dispatcher-info-item__title-plan"
          role="presentation"
          onClick={() => navigate(`${paths.dispatcherPanel}/${item?.objectId}/plan`)}
        >
          <PlanIcon />
        </div>
      </div>
      {isOpen && (
        <div className="dispatcher-info-item__list">
          {showPointsName
            ? statuses.map((status, index) => (
                <InformationPanelDropDownItem key={`status-${status.id}-${index}`} data={status} />
              ))
            : statuses.map((status, index) => (
                <div
                  key={`status-${status.id}-${index}`}
                  className={`dispatcher-info-item__list-item dispatcher-panel__status dispatcher-panel__status_${status.colorName}`}
                  role="presentation"
                  onClick={() => filterByStatus(status)}
                >
                  {status.value} {status.label}
                </div>
              ))}
        </div>
      )}
    </div>
  );
};

export default InformationPanelItem;
