import React, { FC, useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { IFlatDisablingPayment } from './types';
import FLatDisablingPaymentItem from './disablingPaymentItem';
import Button from '../../../ui/button';
import { ButtonType } from '../../../ui/button/types';
import { useApi } from '../../../../hooks/useApi';
import { IFlatDisablePayment } from '../../../../typings/flat';
import { getRequest } from '../../../../api';
import { getFlatPaymentDisableListUrl } from '../../../../constants/api';
import DisablingPaymentModal from './disablingPaymentModal';
import { IConfirmData } from '../../../ui/universalModal/types';
import { defaultConfirm } from '../../../ui/universalModal/config';
import UniversalModal from '../../../ui/universalModal';
import Tooltip from '../../../ui/tooltip';

const FLatDisablingPayment: FC<IFlatDisablingPayment> = (props) => {
  const { permissions, flatId, tabId, activeTabKey, subscriptionData = null } = props;

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const { data: disablePayments, sendRequest: getDisablePayments } = useApi<IFlatDisablePayment[] | null>(getRequest);

  const [periods, setPeriods] = useState<IFlatDisablePayment[]>();

  const [activePeriodId, setActivePeriodId] = useState('');
  const [futurePeriodId, setFuturePeriodId] = useState('');

  const [confirmData, setConfirmData] = useState<IConfirmData>(defaultConfirm);

  const closeConfirm = useCallback(() => setConfirmData(defaultConfirm), []);

  const findActivePeriod = useCallback(() => {
    let activeId = '';
    let futureId = '';

    if (disablePayments)
      for (const item of disablePayments) {
        if (moment(item.endDate).isAfter(moment()) && moment().isAfter(item.startDate)) activeId = item.id;
        if (moment(item.startDate).isAfter(moment())) futureId = item.id;
      }

    setActivePeriodId(activeId);
    setFuturePeriodId(futureId);
  }, [disablePayments]);

  useEffect(() => {
    if (tabId === activeTabKey && flatId) {
      getDisablePayments(getFlatPaymentDisableListUrl(flatId));
    }
  }, [tabId, activeTabKey]);

  useEffect(() => {
    setPeriods((disablePayments || []).sort((a, b) => moment(b.startDate).unix() - moment(a.startDate).unix()));
    findActivePeriod();
  }, [disablePayments]);

  const onAddPeriod = useCallback(() => {
    setModalIsOpen(true);
  }, []);

  const onCloseModal = useCallback(() => setModalIsOpen(false), []);

  const onAccept = useCallback(() => {
    onCloseModal();
    if (flatId) {
      getDisablePayments(getFlatPaymentDisableListUrl(flatId));
    }
  }, [flatId, getDisablePayments, onCloseModal]);

  return (
    <div className="disabling-payment">
      <UniversalModal data={confirmData} onClose={closeConfirm} />
      <DisablingPaymentModal
        subscriptionData={subscriptionData}
        isOpen={modalIsOpen}
        onClose={onCloseModal}
        flatId={flatId}
        onAccept={onAccept}
      />
      <div className="disabling-payment__title">
        <div className="disabling-payment__title-label">Отключение оплаты</div>
        <Tooltip
          hide={!(!permissions?.edit || !!activePeriodId || !!futurePeriodId || !!subscriptionData)}
          title="Вы не можете отключить оплату, пока действует текущая подписка"
          placement="bottom"
        >
          <div className="disabling-payment__title-btn">
            <Button
              disabled={!permissions?.edit || !!activePeriodId || !!futurePeriodId || !!subscriptionData}
              type={ButtonType.outline}
              onClick={onAddPeriod}
            >
              Добавить период
            </Button>
          </div>
        </Tooltip>
      </div>
      <div>
        {periods?.map((item, index) => (
          <FLatDisablingPaymentItem activePeriodId={activePeriodId} index={index} key={item.id} data={item} />
        ))}
      </div>
    </div>
  );
};

export default FLatDisablingPayment;
