import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useApi } from '../../../../hooks/useApi';
import Button from '../../../ui/button';
import { ButtonType } from '../../../ui/button/types';
import { ERecipientsFilters, IMailingEditorTabComponent } from '../types';
import RecipientsModal from './recipientsModal';
import { getRequest, postRequest } from '../../../../api';
import { companiesObjectsUrl, mailingRecipientsFillUrl, mailingStatusListUrl } from '../../../../constants/api';
import RecipientsTable from './recipientsTable';
import { IApiSortField } from '../../../../typings/api';
import InputSearch from '../../../ui/inputSearch';
import Select from '../../../ui/select';
import { IObject } from '../../../../typings/payments';
import { IMailingStatus } from '../../../../typings/mailing';
import FiltersTool from '../../../ui/filtersTool';
import { EFilterTool } from '../../../../typings/filtersTool';

const MailingRecipientsTab: FC<IMailingEditorTabComponent> = ({
  recipientsList,
  permissions,
  requestRecipients = () => {},
  apiSettings,
  eventsApiParams,
  removeRecipients,
  setRemoveRecipients,
  onDeleteRecipients,
  mailingData,
  onlyView,
  mailingType,
}) => {
  const { sendRequest: fillRecipients, loading: fillRecipientsLoading } = useApi(postRequest);

  const {
    data: statusList,
    sendRequest: getStatusList,
    loading: getStatusListLoading,
  } = useApi<IMailingStatus[]>(getRequest);

  const [recipientModalOpen, setRecipientModalOpen] = useState(false);

  const { data: objects, sendRequest: getObjects, loading: objectsLoading } = useApi<IObject[]>(getRequest);

  useEffect(() => {
    getObjects(companiesObjectsUrl());
  }, []);

  const handleOnChangeTablePage = useCallback(
    async (page: number) => {
      if (apiSettings) {
        const newApiSettings: any = { ...apiSettings, page: page - 1 };
        requestRecipients(newApiSettings);
      }
    },
    [apiSettings, requestRecipients]
  );

  const handleOnSort = useCallback(
    async (sortResults: IApiSortField<any>[]) => {
      const newApiSettings: any = { ...apiSettings, sortFields: sortResults };
      requestRecipients(newApiSettings);
    },
    [apiSettings, requestRecipients]
  );

  const handleOnSearch = useCallback(
    async (value: string) => {
      const newApiSettings: any = { ...apiSettings, page: 0, search: value };
      requestRecipients(newApiSettings);
    },
    [apiSettings, requestRecipients]
  );

  const handleOnChangeSelect = useCallback(
    (key: string) => (val: string | number) => {
      const newApiSettings: any = { ...apiSettings, page: 0 };
      const newEventsParams = {
        ...eventsApiParams,
        [key]: val,
      };
      requestRecipients(newApiSettings, newEventsParams);
    },
    [apiSettings, eventsApiParams, requestRecipients]
  );

  const getRecipients = useCallback(
    async (objectList: string[], subscriberList: string[]) => {
      await fillRecipients(mailingRecipientsFillUrl(mailingData?.id || ''), {
        objectList: objectList.length ? objectList : null,
        subscriberList: subscriberList.length ? subscriberList : null,
      });
      requestRecipients();
    },
    [fillRecipients, mailingData?.id, requestRecipients]
  );

  const setValuesFromUrl = useCallback(
    (values: any) => {
      const newApiSettings: any = { ...apiSettings, page: 0, search: values[ERecipientsFilters.search] };
      const newEventsParams = {
        ...eventsApiParams,
        objectId: values[ERecipientsFilters.objectId],
        status: values[ERecipientsFilters.status],
      };
      requestRecipients(newApiSettings, newEventsParams);
    },
    [apiSettings, eventsApiParams, requestRecipients]
  );

  const objectOptions = useMemo(
    () =>
      objects?.map((item) => ({
        value: item.objectId || '',
        title: item.objectName || '',
      })),
    [objects]
  );

  const statusOptions = useMemo(
    () => [
      {
        value: '0',
        title: 'Отправлен',
      },
      {
        value: '1',
        title: 'Не отправлен',
      },
      {
        value: '2',
        title: 'В очереди',
      },
      {
        value: '3',
        title: 'Отменен',
      },
    ],
    []
  );

  return (
    <div className="email-recipients">
      <RecipientsModal
        mailingType={mailingType}
        isOpen={recipientModalOpen}
        onCancel={() => setRecipientModalOpen(false)}
        saveData={getRecipients}
      />

      {recipientsList?.items.length === 0 &&
      !apiSettings?.search &&
      !eventsApiParams?.objectId &&
      !eventsApiParams?.status ? (
        <>
          <div className="email-recipients__add-button">
            <Button type={ButtonType.outline} onClick={() => setRecipientModalOpen(true)} disabled={!permissions?.edit}>
              Добавить получателей
            </Button>
          </div>

          <div className="email-recipients__info">Пока не добавлено ни одного получателя</div>
        </>
      ) : (
        <>
          <FiltersTool
            setValuesWithDelay
            setValuesFromUrl={setValuesFromUrl}
            rows={[
              [
                {
                  type: EFilterTool.search,
                  id: ERecipientsFilters.search,
                  props: {
                    placeholder: 'Поиск по ФИО, телефону, E-mail, адресу',
                    value: apiSettings?.search || '',
                    onSearch: handleOnSearch,
                  },
                },
                {
                  type: EFilterTool.select,
                  id: ERecipientsFilters.objectId,
                  props: {
                    isAllOption: true,
                    title: 'Объект (краткое наименование)',
                    value: eventsApiParams?.objectId || '',
                    onChange: handleOnChangeSelect('objectId'),
                    options: objectOptions,
                    loading: objectsLoading,
                  },
                },
                {
                  type: EFilterTool.select,
                  id: ERecipientsFilters.status,
                  hidden: !onlyView,
                  props: {
                    isAllOption: true,
                    title: 'Статус',
                    value: eventsApiParams?.status || '',
                    onChange: handleOnChangeSelect('status'),
                    options: statusOptions,
                    loading: getStatusListLoading,
                  },
                },
                {
                  type: EFilterTool.button,
                  hidden: onlyView,
                  props: {
                    type: ButtonType.outline,
                    onClick: () => setRecipientModalOpen(true),
                    disabled: !permissions?.edit,
                    children: 'Добавить получателей',
                  },
                },
              ],
            ]}
          />
          <RecipientsTable
            onlyView={onlyView}
            onDeleteRecipients={onDeleteRecipients}
            removeRecipients={removeRecipients}
            setRemoveRecipients={setRemoveRecipients}
            recipients={recipientsList?.items}
            loading={fillRecipientsLoading}
            permission={permissions}
            pageSize={recipientsList?.pageSize}
            total={recipientsList?.totalCount}
            currentPage={recipientsList?.page}
            onChangePage={handleOnChangeTablePage}
            onSort={handleOnSort}
            sortOrders={apiSettings?.sortFields}
            isSearch={!!apiSettings?.search}
          />
        </>
      )}
    </div>
  );
};

export default MailingRecipientsTab;
