import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import moment, { Moment } from 'moment';
import { AxiosError } from 'axios';
import classNames from 'classnames';
import { IDisablingPaymentModal } from './types';
import Modal from '../../../../ui/modal';
import Button from '../../../../ui/button';
import Input from '../../../../ui/input';
import { postRequest } from '../../../../../api';
import { useApi } from '../../../../../hooks/useApi';
import { dateFormatNoTime } from '../../../../../constants/date';
import { setFlatPaymentDisableUrl } from '../../../../../constants/api';
import Select from '../../../../ui/select';
import { ISelectOption } from '../../../../ui/select/types';
import { ETariffVersions } from '../../../../../typings/management/tariff';
import InfoIcon from '../../../../../assets/svg/icons/info';
import { ButtonSize, ButtonType } from '../../../../ui/button/types';
import SingleDatePicker from '../../../../ui/singleDatePicker';

const DisablingPaymentModal: FC<IDisablingPaymentModal> = (props) => {
  const { isOpen = false, onClose = () => {}, onAccept = () => {}, flatId = '', subscriptionData } = props;

  const { sendRequest: setDisablePayment, loading } = useApi(postRequest);

  const [isFinish, setIsFinish] = useState(false);

  const [comment, setComment] = useState<string>('');
  const [toDate, setToDate] = useState<Moment | null>(null);
  const [tariff, setTariff] = useState('');

  useEffect(() => {
    if (isOpen) {
      setComment('');
      setToDate(null);
    }
  }, [isOpen]);

  const handleOnClose = useCallback(() => {
    onClose();
    setComment('');
    setToDate(null);
    setTariff('');
    setIsFinish(false);
  }, [onClose]);

  const handleOnSend = useCallback(async () => {
    const startDate = moment().startOf('day').toISOString(true);
    const endDate = toDate ? toDate?.startOf('day').toISOString(true) : null;
    const resError = (await setDisablePayment(setFlatPaymentDisableUrl(flatId), {
      description: comment,
      startDate,
      endDate,
      tariff,
    })) as AxiosError;

    if (!resError?.response?.data) {
      onAccept();
    } else {
      setIsFinish(false);
    }
  }, [comment, flatId, onAccept, setDisablePayment, tariff, toDate]);

  const typeOptions = useMemo(
    (): ISelectOption[] => [
      {
        value: ETariffVersions.lite,
        title: ETariffVersions.lite,
      },
      {
        value: ETariffVersions.medium,
        title: ETariffVersions.medium,
      },
      {
        value: ETariffVersions.comfort,
        title: ETariffVersions.comfort,
      },
    ],
    []
  );

  return (
    <Modal
      isOpen={isOpen}
      title={
        isFinish
          ? 'Изменить период действия будет невозможно. Вы действительно хотите отключить оплату?'
          : 'Отключение оплаты'
      }
      centered
      width={isFinish ? 416 : 644}
      onCancel={handleOnClose}
      showCloseIcon
      wrapClassName={classNames('disabling-payment-modal__wrapper', {
        'disabling-payment-modal__wrapper_finish': isFinish,
      })}
      footer={
        isFinish ? (
          <div className="disabling-payment-modal__button-container">
            <Button size={ButtonSize.small} type={ButtonType.secondary} onClick={handleOnClose}>
              Нет
            </Button>
            <Button size={ButtonSize.small} onClick={handleOnSend}>
              Да
            </Button>
          </div>
        ) : (
          <div className="disabling-payment-modal__button-container">
            <Button
              size={ButtonSize.small}
              disabled={!comment || !comment.trim() || !tariff || !toDate}
              loading={loading}
              onClick={() => setIsFinish(true)}
            >
              Применить
            </Button>
          </div>
        )
      }
    >
      {!isFinish && (
        <div className="disabling-payment-modal">
          <div className="disabling-payment-modal__title">
            Отключение оплаты предоставляет бесплатное использование всех функций выбранного тарифа за установленный
            период всем абонентам квартиры
          </div>
          <div className="disabling-payment-modal__inputs">
            <SingleDatePicker
              format={dateFormatNoTime}
              onlyFuture
              isDisabledStyle
              title="Дата окончания периода"
              placeholder="- выберите -"
              dateValue={toDate}
              onChange={setToDate}
              textInfo="Время завершения периода 23:59 UTC"
            />
            <div>
              <Select
                isRequired
                title="Тариф"
                value={tariff}
                onChange={(e) => setTariff(e.toString())}
                options={typeOptions}
              />
              {subscriptionData?.tariff?.version && (
                <div className="disabling-payment-modal__select-info">
                  <InfoIcon />
                  Текущий тариф - {subscriptionData?.tariff?.version}
                </div>
              )}
            </div>
          </div>
          <div className="disabling-payment-modal__inputs">
            <Input
              isRequired
              title="Комментарий"
              placeholder="Оставьте комментарий"
              value={comment}
              onChange={setComment}
              maxLength={100}
            />
          </div>
        </div>
      )}
    </Modal>
  );
};

export default DisablingPaymentModal;
