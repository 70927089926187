import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import moment, { Moment } from 'moment';
import { useSearchParams } from 'react-router-dom';
import { IHardwareValidateButtons, IValidHardware } from './types';
import { useApi } from '../../../../hooks/useApi';
import { getHardwareValidation } from '../../../../api/hardwareSettings';
import { allDeviceUpdateAvailableUrl, deviceUpdateTimeUrl, getHardwareValidationUrl } from '../../../../constants/api';
import Button from '../../../ui/button';
import { ButtonSize, ButtonType } from '../../../ui/button/types';
import ChevronLeft from '../../../../assets/svg/icons/chevronLeft';
import ChevronRight from '../../../../assets/svg/icons/chevronRight';
import SingleDatePicker from '../../../ui/singleDatePicker';
import { postRequest } from '../../../../api';
import { dateISOFormatNoTimeZone } from '../../../../constants/date';
import { EPageQueryParams } from '../../../../typings/searchParams';

const HardwareValidateButtons: FC<IHardwareValidateButtons> = (props) => {
  const {
    system,
    systemId = '',
    invalidHardware = [],
    setInvalidHardware = () => {},
    onChangeActive = () => {},
    selectedNode = null,
    getSystemData = () => {},
    permissions = {},
  } = props;

  const [wasErrors, setWasErrors] = useState(false);

  const {
    data: validatedHardware,
    loading: validatedHardwareLoading,
    sendRequest: getValidateHardware,
  } = useApi<IValidHardware[]>(getHardwareValidation);

  const { sendRequest: setAllDeviceUpdateAvailable, loading: allDeviceUpdateAvailableLoading } = useApi(postRequest);

  const { sendRequest: setDeviceUpdateTime } = useApi(postRequest);

  const [currentInvalidHardware, setCurrentInvalidHardware] = useState<number>(0);

  const [currentDate, setCurrentDate] = useState<Moment | null>(null);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (system?.deviceUpdateTime) {
      setCurrentDate(moment(system?.deviceUpdateTime));
    }
  }, [system]);

  useEffect(() => {
    if (selectedNode && selectedNode.itemId !== invalidHardware[currentInvalidHardware]?.id) {
      const newIndex = invalidHardware.findIndex((item) => item.id === selectedNode.itemId);
      if (newIndex !== -1) {
        setCurrentInvalidHardware(newIndex);
      }
    }
  }, [selectedNode]);

  useEffect(() => {
    if (!validatedHardwareLoading && validatedHardware && validatedHardware.length > 0) {
      const newInvalidHardware: IValidHardware[] = [];
      validatedHardware.forEach((item) => {
        if (!item.invalidParams || (item.invalidParams && item.invalidParams.length > 0)) {
          return newInvalidHardware.push(item);
        }
        const keys = Object.keys(item);
        for (let i = 0; i < keys.length; i++) {
          const field = item[keys[i] as keyof IValidHardware];
          if (typeof field === 'boolean' && !field) {
            newInvalidHardware.push(item);
            break;
          }
        }
      });
      setInvalidHardware(newInvalidHardware);
      setCurrentInvalidHardware(0);
      if (newInvalidHardware[0]) {
        onChangeActive(newInvalidHardware[0]);
      }
    }
  }, [validatedHardwareLoading]);

  const handleOnChangeDate = useCallback(
    (e: Moment | null) => {
      setCurrentDate(e);
      if (system) {
        setDeviceUpdateTime(deviceUpdateTimeUrl(systemId), {
          time: moment.utc(e?.startOf('hours')).format(dateISOFormatNoTimeZone),
        });
      }
    },
    [setDeviceUpdateTime, system, systemId]
  );

  const handleCheckErrors = useCallback(() => {
    if (!wasErrors) {
      setWasErrors(true);
    }
    getValidateHardware(getHardwareValidationUrl(systemId));
  }, [getValidateHardware, setWasErrors, systemId, wasErrors]);

  useEffect(() => {
    if (searchParams.get(EPageQueryParams.needCheckErrors)) {
      searchParams.delete(EPageQueryParams.needCheckErrors);
      setSearchParams(searchParams);
      handleCheckErrors();
    }
  }, [searchParams]);

  const onSetUpdates = useCallback(async () => {
    await setAllDeviceUpdateAvailable(allDeviceUpdateAvailableUrl(systemId), {});
    getSystemData();
  }, [getSystemData, setAllDeviceUpdateAvailable, systemId]);

  const goToInvalidHardware = useCallback(
    (step: number) => {
      const { length } = invalidHardware;
      if (length > 1) {
        const index = currentInvalidHardware + step;
        const newCurrentInvalidHardware = index < 0 ? length - 1 : index >= length ? 0 : index;
        setCurrentInvalidHardware(newCurrentInvalidHardware);
        if (invalidHardware[newCurrentInvalidHardware]) {
          onChangeActive(invalidHardware[newCurrentInvalidHardware]);
        }
      } else if (length === 1) {
        setCurrentInvalidHardware(0);
        onChangeActive(invalidHardware[0]);
      }
    },
    [currentInvalidHardware, invalidHardware, onChangeActive]
  );

  const isOneElemInList = useMemo(
    () =>
      invalidHardware.length === 0 ||
      (invalidHardware.length === 1 &&
        selectedNode &&
        selectedNode.itemId === invalidHardware[currentInvalidHardware]?.id),
    [currentInvalidHardware, invalidHardware, selectedNode]
  );

  return (
    <div className="hardware-validate-buttons default-scrollbar-override">
      {permissions.edit && (
        <div className="hardware-validate-buttons__container">
          <Button
            onClick={onSetUpdates}
            loading={allDeviceUpdateAvailableLoading}
            type={ButtonType.outline}
            size={ButtonSize.small}
            disabled={system?.isUpdateAllowForAllDevices}
          >
            Разрешить обновление всем
          </Button>
          <SingleDatePicker
            containerClassName="hardware-validate-buttons__date-picker"
            onlyFuture
            isDisabledStyle
            placeholder="Выберите время и дату"
            dateValue={currentDate}
            onChange={handleOnChangeDate}
          />
        </div>
      )}
      <div className="hardware-validate-buttons__container">
        <Button
          className="hardware-validate-buttons__check-errors-button"
          onClick={handleCheckErrors}
          type={ButtonType.outline}
          size={ButtonSize.small}
        >
          Проверить ошибки
        </Button>

        {wasErrors && (
          <div className="hardware-validate-buttons__errors">
            <div
              role="presentation"
              onClick={() => goToInvalidHardware(-1)}
              className={classNames('hardware-validate-buttons__errors-button', {
                'hardware-validate-buttons__errors-button_disabled': invalidHardware.length <= 1,
              })}
            >
              <ChevronLeft />
            </div>
            <div
              className={classNames('hardware-validate-buttons__errors-label', {
                'hardware-validate-buttons__errors-label_disabled': invalidHardware.length === 0,
              })}
            >
              Найдено невалидного оборудования: {invalidHardware.length}
            </div>
            <div
              role="presentation"
              onClick={() => goToInvalidHardware(1)}
              className={classNames('hardware-validate-buttons__errors-button', {
                'hardware-validate-buttons__errors-button_disabled': isOneElemInList,
              })}
            >
              <ChevronRight />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default HardwareValidateButtons;
