import { IProfilePermission } from '../../../../typings/profile';
import { ISystemSection } from '../../../../typings/systems/section';

export interface ISectionCallCodes {
  systemId?: string;
  buildingId?: string;
  sections?: ISystemSection[];
  updateAvailableTabs?: () => void;
  permissions?: IProfilePermission;
  isFiveThousandth?: boolean;
}

export enum ECallCodesTabsFilters {
  search = 'search',
  section = 'section',
}
