import { TooltipPlacement } from 'antd/lib/tooltip';
import AccessGroupsTab from '../../../components/systemTabs/accessGroupsTab';
import CallCodesTab from '../../../components/systemTabs/callCodesTab';
import ConfigurationTab from '../../../components/systemTabs/configurationTab';
import HardwareSettingsTab from '../../../components/systemTabs/hardwareSettingsTab';
import ObjectKeysTab from '../../../components/systemTabs/objectKeysTab';
import SystemSchemaTab from '../../../components/systemTabs/schemaTab';
import SystemSectionStructureTab from '../../../components/systemTabs/sectionStructureTab';
import { EAvailableTabs } from '../../../components/tabs/types';
import { ESystemTabsIds } from './types';
import SystemBaseTab from '../../../components/systemTabs/baseTab';
import ServiceKeysTab from '../../../components/systemTabs/serviceKeysTab';

const systemTabsTooltipProps = {
  placement: 'bottomRight' as TooltipPlacement,
  styles: { width: 166 },
};

export const getTabs = (isFiveThousandth: boolean) => [
  {
    id: ESystemTabsIds.basic,
    position: 0,
    title: 'Основное',
    component: SystemBaseTab,
    availableTab: EAvailableTabs.isBaseAvailable,
  },
  {
    id: ESystemTabsIds.plan,
    position: 1,
    title: 'План',
    component: SystemSchemaTab,
    availableTab: EAvailableTabs.isPlanAvailable,
  },
  {
    id: ESystemTabsIds.distributionFlats,
    position: 2,
    title: 'Распределение квартир',
    component: SystemSectionStructureTab,
    availableTab: EAvailableTabs.isFlatsAvailable,
    notification: 'Для перехода на вкладку "Распределение квартир" заполните подъезды у строений',
    tooltipProps: systemTabsTooltipProps,
  },
  {
    id: ESystemTabsIds.securityDeck,
    position: 3,
    title: 'ППО для квартир',
    component: SystemSectionStructureTab,
    availableTab: EAvailableTabs.isSecurityDeckAvailable,
    notification:
      'Для перехода на вкладку "ППО для квартир" включите "наличие ППО" в основном разделе объекта и заполните квартиры',
    tooltipProps: systemTabsTooltipProps,
    isOnlyFiveThousandth: true,
  },
  {
    id: ESystemTabsIds.callCodes,
    position: 4,
    title: 'Коды вызова',
    component: CallCodesTab,
    availableTab: EAvailableTabs.isCallCodesAvailable,
    notification: 'Для перехода на вкладку "Коды вызова" заполните квартиры',
    tooltipProps: systemTabsTooltipProps,
  },
  {
    id: ESystemTabsIds.objectKeys,
    position: 5,
    title: 'Квартирные ключи',
    component: ObjectKeysTab,
    availableTab: EAvailableTabs.isObjectKeysAvailable,
    notification: 'Для перехода на вкладку "Квартирные ключи" заполните квартиры',
    tooltipProps: systemTabsTooltipProps,
  },
  {
    id: ESystemTabsIds.serviceKeys,
    position: 5,
    title: 'Служебные ключи',
    component: ServiceKeysTab,
    availableTab: EAvailableTabs.isServiceKeyAvailable,
    notification: 'Для перехода на вкладку «Служебные ключи» добавьте точку доступа',
    tooltipProps: systemTabsTooltipProps,
  },
  {
    id: ESystemTabsIds.accessGroups,
    position: 6,
    title: 'Группы доступа',
    component: AccessGroupsTab,
    availableTab: EAvailableTabs.isAccessGroupsAvailable,
    notification: 'Для перехода на вкладку "Группы доступа" заполните квартиры и добавьте точки доступа на плане',
    tooltipProps: systemTabsTooltipProps,
  },
  {
    id: ESystemTabsIds.BVConfiguration,
    position: 7,
    title: 'Настройки оборудования',
    component: HardwareSettingsTab,
    availableTab: EAvailableTabs.isAccessPointSettingsAvailable,
    notification: 'Для перехода на вкладку "Настройки оборудования" заполните точки доступа на плане',
    tooltipProps: systemTabsTooltipProps,
  },
  {
    id: ESystemTabsIds.configurationFiles,
    position: 8,
    title: 'Конфигурирование',
    component: ConfigurationTab,
    availableTab: EAvailableTabs.isSynchronizationAvailable,
    notification: isFiveThousandth
      ? 'Для перехода на вкладку "Конфигурирование" привяжите хотя бы одну камеру'
      : 'Для перехода на вкладку "Конфигурирование" заполните квартиры и точки доступа на плане',
    tooltipProps: systemTabsTooltipProps,
  },
];
