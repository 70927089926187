import moment, { Moment } from 'moment';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { getEntitiesForSubscriber } from '../../../../api/subscribers';
import { IAccessPointLogApiParams } from '../../../../api/subscribers/accessPointLog';
import { subscriberDeviceLogInitApiParams } from '../../../../api/subscribers/config';
import { getSubscriberDeviceLogUrl } from '../../../../constants/api';
import { dateFormatNoTime, dateISOFormatNoTimeZone } from '../../../../constants/date';
import { selectAllOptionKey } from '../../../../constants/select';
import { useApi } from '../../../../hooks/useApi';
import { IApiResponse } from '../../../../typings/api';
import { IDeviceLog } from '../../../../typings/subscribers/deviceLog';
import { ISelectOption } from '../../../ui/select/types';
import { ISelectMultipleOption } from '../../../ui/selectMultiple/types';
import DeviceLogList from '../deviceLogList';
import { EDeviceLogsFilters, IDeviceLogData } from './types';
import FiltersTool from '../../../ui/filtersTool';
import { EFilterTool } from '../../../../typings/filtersTool';

const DeviceLogData: FC<IDeviceLogData> = (props) => {
  const { flatId = '', devices = [], actionTypeList = [], actionTypeListLoading = false } = props;

  const [selectedDeviceId, setSelectedDeviceId] = useState<string>(selectAllOptionKey);
  const [selectedActionTypeList, setSelectedActionTypeList] = useState<string[]>([selectAllOptionKey]);
  const {
    data: logs,
    loading: logsLoading,
    sendRequest: sendRequestLogs,
  } = useApi<IApiResponse<IDeviceLog>>(getEntitiesForSubscriber);
  const [dateFrom, setDateFrom] = useState<string>(
    moment().subtract(1, 'month').startOf('day').format(dateISOFormatNoTimeZone)
  );
  const [dateTo, setDateTo] = useState<string>(moment().endOf('day').format(dateISOFormatNoTimeZone));
  const [accessPointLogApiParams, setAccessPointLogApiParams] = useState<IAccessPointLogApiParams>({
    ...subscriberDeviceLogInitApiParams,
    dateFrom: dateFrom,
    dateTo: dateTo,
  });

  useEffect(() => {
    if (flatId) {
      sendRequestLogs(getSubscriberDeviceLogUrl(flatId), { params: { ...accessPointLogApiParams } });
    }
  }, [flatId]);

  useEffect(() => {
    setSelectedActionTypeList([selectAllOptionKey]);
    const updatedApiParams: IAccessPointLogApiParams = {
      ...accessPointLogApiParams,
      type: actionTypeList?.map((item) => item.type || '').filter((item) => item),
    };
    setAccessPointLogApiParams(updatedApiParams);
    if (flatId) {
      sendRequestLogs(getSubscriberDeviceLogUrl(flatId), { params: { ...updatedApiParams } });
    }
  }, [actionTypeList]);

  const deviceSelectDisable = useMemo(() => !devices || !(devices?.length > 0), [devices]);
  const actionTypeListSelectDisable = useMemo(() => !actionTypeList || !(actionTypeList?.length > 0), [actionTypeList]);

  const handleOnChangeSelectDevice = useCallback(
    async (value: string | number) => {
      const updatedApiParams: IAccessPointLogApiParams = {
        ...accessPointLogApiParams,
        deviceId: value === selectAllOptionKey ? '' : value.toString(),
        page: 0,
      };
      await sendRequestLogs(getSubscriberDeviceLogUrl(flatId), { params: { ...updatedApiParams } });
      setSelectedDeviceId(value.toString());
      setAccessPointLogApiParams(updatedApiParams);
    },
    [accessPointLogApiParams, flatId, sendRequestLogs]
  );

  const handleOnChangeSelectActionTypeList = useCallback(
    async (values: string[]) => {
      const updatedApiParams: IAccessPointLogApiParams = {
        ...accessPointLogApiParams,
        type: values.includes(selectAllOptionKey)
          ? actionTypeList?.map((item) => item.type || '').filter((item) => item)
          : values,
      };
      await sendRequestLogs(getSubscriberDeviceLogUrl(flatId), {
        params: { ...updatedApiParams },
      });
      setSelectedActionTypeList(values);
      setAccessPointLogApiParams(updatedApiParams);
    },
    [accessPointLogApiParams, actionTypeList, flatId, sendRequestLogs]
  );

  const handleOnChangeDate = useCallback(
    async (firstDate?: Moment, secondDate?: Moment) => {
      const currentDateFrom = firstDate?.startOf('day').format(dateISOFormatNoTimeZone);
      const currentDateTo = secondDate?.endOf('day').format(dateISOFormatNoTimeZone);

      setDateFrom(currentDateFrom || '');
      setDateTo(currentDateTo || '');

      const updatedApiParams: IAccessPointLogApiParams = {
        ...accessPointLogApiParams,
        dateFrom: currentDateFrom || '',
        dateTo: currentDateTo || '',
        page: 0,
      };
      await sendRequestLogs(getSubscriberDeviceLogUrl(flatId), { params: { ...updatedApiParams } });
      setAccessPointLogApiParams(updatedApiParams);
    },
    [accessPointLogApiParams, flatId, sendRequestLogs]
  );

  const handleOnChangePageDeviceLogList = useCallback(
    async (page: number) => {
      const updatedApiParams: IAccessPointLogApiParams = {
        ...accessPointLogApiParams,
        page: page - 1,
      };
      await sendRequestLogs(getSubscriberDeviceLogUrl(flatId), { params: { ...updatedApiParams } });
      setAccessPointLogApiParams(updatedApiParams);
    },
    [accessPointLogApiParams, flatId, sendRequestLogs]
  );

  const setValuesFromUrl = useCallback(
    async (values: any) => {
      const updatedApiParams: IAccessPointLogApiParams = {
        ...accessPointLogApiParams,
        page: 0,
      };

      if (values.dateFrom && values.dateTo) {
        setDateFrom(values.dateFrom || '');
        setDateTo(values.dateTo || '');
        updatedApiParams.dateFrom = values.dateFrom || '';
        updatedApiParams.dateTo = values.dateTo || '';
      }

      if (values[EDeviceLogsFilters.deviceId]) {
        updatedApiParams.deviceId =
          values[EDeviceLogsFilters.deviceId] === selectAllOptionKey
            ? ''
            : values[EDeviceLogsFilters.deviceId]?.toString() || '';
        setSelectedDeviceId(values[EDeviceLogsFilters.deviceId]);
      }

      if (values[EDeviceLogsFilters.type]) {
        const typeValuse = (values[EDeviceLogsFilters.type] || '').split(',').filter((item: string) => item !== ',');
        const type = typeValuse[0] === '' ? [] : typeValuse;
        updatedApiParams.type = type.includes(selectAllOptionKey)
          ? actionTypeList?.map((item) => item.type || '').filter((item) => item)
          : type;
        setSelectedActionTypeList(type);
      }

      await sendRequestLogs(getSubscriberDeviceLogUrl(flatId), { params: { ...updatedApiParams } });
      setAccessPointLogApiParams(updatedApiParams);
    },
    [accessPointLogApiParams, actionTypeList, flatId, sendRequestLogs]
  );

  const selectDeviceOptions = useMemo(
    () => [
      { value: selectAllOptionKey, data: 'Все', title: 'Все' },
      ...devices.map<ISelectOption>((device) => ({ value: device.id || '', title: device.name || '' })),
    ],
    [devices]
  );

  const selectActionTypeListOptions = useMemo(
    () => [
      { value: selectAllOptionKey, title: 'Все' },
      ...actionTypeList.map<ISelectMultipleOption>((action) => ({
        value: action.type || '',
        title: action.displayName || '',
      })),
    ],
    [actionTypeList]
  );

  return (
    <div className="device-log-data">
      <FiltersTool
        setValuesWithDelay
        setValuesFromUrl={setValuesFromUrl}
        rows={[
          [
            {
              type: EFilterTool.select,
              id: EDeviceLogsFilters.deviceId,
              props: {
                title: 'Оборудование',
                value: selectedDeviceId,
                onChange: handleOnChangeSelectDevice,
                disabled: deviceSelectDisable,
                options: selectDeviceOptions,
              },
            },
            {
              type: EFilterTool.multiSelect,
              id: EDeviceLogsFilters.type,
              props: {
                title: 'Тип события',
                values: selectedActionTypeList,
                onChange: handleOnChangeSelectActionTypeList,
                disabled: actionTypeListSelectDisable,
                options: selectActionTypeListOptions,
                loading: actionTypeListLoading,
              },
            },
            {
              type: EFilterTool.datePicker,
              id: EDeviceLogsFilters.date,
              props: {
                title: 'Период',
                placeholder: 'дд.мм.гггг - дд.мм.гггг',
                firstDate: dateFrom,
                secondDate: dateTo,
                onChange: handleOnChangeDate,
                showTime: false,
                format: dateFormatNoTime,
              },
            },
          ],
        ]}
      />
      <DeviceLogList
        logs={logs?.items}
        loading={logsLoading}
        pagination={{
          pageSize: logs?.pageSize || 0,
          currentPage: logs?.page || 0,
          total: logs?.totalCount || 0,
          onChangePage: handleOnChangePageDeviceLogList,
        }}
      />
    </div>
  );
};

export default DeviceLogData;
