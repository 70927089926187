import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import lodash from 'lodash';
import classNames from 'classnames';
import { useSearchParams } from 'react-router-dom';
import CommonHead from '../../../components/commonHead';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { setHeaderTitle } from '../../../store/slices/header';
import { useApi } from '../../../hooks/useApi';
import {
  companyUrl,
  getFAQQuestionsListUrl,
  getObjectSystemIdUrl,
  getObjectSystemsListUrl,
} from '../../../constants/api';
import { checkIsAdmin, getProfilePermission } from '../../../store/selectors/profile';
import { IApiResponse } from '../../../typings/api';
import { ESidebarItemIds } from '../../../typings/sidebar';
import FaqSections from '../../../components/faqSubscribers/faqSections';
import FaqTable from '../../../components/faqSubscribers/faqTable';
import { getRequest } from '../../../api';
import { EFAQAdminTabs, IFAQQuestion } from '../../../typings/faq';
import { ISelectOption } from '../../../components/ui/select/types';
import { ISystem } from '../../../typings/systems/system';
import { getSystem } from '../../../api/systems';
import { IFAQSubscribersApiSettings } from '../../../api/faqSubscribers/types';
import { getFAQSubscriberApiSettings } from '../../../api/faqSubscribers/config';
import TabSwitch from '../../../components/ui/tabSwitch';
import { ITabSwitchItem } from '../../../typings/tabSwitch';
import { getPaymentsData } from '../../../api/payments';
import { StatusIconColor } from '../../../components/statusSelect/types';
import { IOrganization } from '../../../typings/organization';
import { setChange } from '../../../store/slices/changes';
import FiltersTool from '../../../components/ui/filtersTool';
import { EFilterTool } from '../../../typings/filtersTool';
import { EFAQFilters } from './types';

const SubscribersFaqPage: FC = () => {
  const dispatch = useAppDispatch();
  const isAdmin = useAppSelector(checkIsAdmin);

  const [activeSection, setActiveSection] = useState<string>('');

  const [isChangeMode, setIsChangeMode] = useState(false);

  const { data: questions, sendRequest: getQuestions } = useApi<IApiResponse<IFAQQuestion>>(getRequest);
  const { data: systems, sendRequest: sendSystemRequest } = useApi<IApiResponse<ISystem>>(getSystem);
  const { data: companies, sendRequest: getCompanies } = useApi<IOrganization[]>(getPaymentsData);

  const [questionsList, setQuestionsList] = useState<IFAQQuestion[]>([]);

  const [isLastList, setLastList] = useState(false);

  const permissions = useAppSelector(getProfilePermission(ESidebarItemIds.subscriberFAQ));

  const [apiSettings, setApiSettings] = useState<IFAQSubscribersApiSettings>(getFAQSubscriberApiSettings());

  const [searchParams, setSearchParams] = useSearchParams();

  const adminTabs: ITabSwitchItem[] = [
    {
      id: EFAQAdminTabs.all,
      title: 'Все вопросы',
    },
    {
      id: EFAQAdminTabs.platform,
      title: 'Вопросы платформы',
    },
  ];

  const [activeTab, setActiveTab] = useState(adminTabs[0].id);

  const setWasChange = useCallback(
    (val: boolean) => {
      dispatch(setChange(val));
      setIsChangeMode(val);
    },
    [dispatch]
  );

  useEffect(() => {
    if (questions) {
      setQuestionsList([...questionsList, ...questions.items]);
      if (questions.items.length === 0) {
        setLastList(true);
        if (apiSettings.page !== 0) {
          setApiSettings({ ...apiSettings, page: apiSettings.page - 1 });
        }
      }
    } else {
      setQuestionsList([]);
    }
  }, [questions]);

  useEffect(() => {
    if (apiSettings.companyId) {
      sendSystemRequest(getObjectSystemIdUrl(apiSettings.companyId), { params: { count: 0, isOnlyIpSeries: true } });
    } else {
      sendSystemRequest(getObjectSystemsListUrl(), { params: { count: 0, isOnlyIpSeries: true } });
    }
  }, [apiSettings.companyId]);

  useEffect(() => {
    dispatch(setHeaderTitle('Конструктор FAQ абонентов'));
  }, []);

  useEffect(() => {
    if (isAdmin) {
      getCompanies(companyUrl());
    }
  }, [isAdmin]);

  const requestQuestions = useCallback(
    async (
      section = activeSection,
      reqSettings: IFAQSubscribersApiSettings = apiSettings,
      list: IFAQQuestion[] = []
    ) => {
      setApiSettings(reqSettings);
      if (section) {
        setQuestionsList(list);
        await getQuestions(
          getFAQQuestionsListUrl(section),
          isAdmin && activeTab === EFAQAdminTabs.platform
            ? { params: { ...reqSettings, isPlatform: true } }
            : { params: reqSettings }
        );
      }
    },
    [activeSection, activeTab, apiSettings, getQuestions, isAdmin]
  );

  const handleOnSearch = useCallback(
    async (value: string) => {
      setLastList(false);
      const newApiSettings: IFAQSubscribersApiSettings = { ...apiSettings, page: 0, search: value };
      await requestQuestions(activeSection, newApiSettings);
    },
    [activeSection, apiSettings, requestQuestions]
  );

  const handleOnChangeSelect = useCallback(
    (key: string) => (val: string | number) => {
      setLastList(false);
      const newApiSettings: IFAQSubscribersApiSettings = { ...apiSettings, page: 0, [key]: val };
      requestQuestions(activeSection, newApiSettings);
    },
    [activeSection, apiSettings, requestQuestions]
  );

  const handleOnChangeTablePage = useCallback(
    async (page: number) => {
      const newApiSettings: IFAQSubscribersApiSettings = { ...apiSettings, page };
      await requestQuestions(activeSection, newApiSettings, questionsList);
    },
    [activeSection, apiSettings, questionsList, requestQuestions]
  );

  const onChangeActiveSection = useCallback(
    (section: string) => {
      if (section !== activeSection) {
        setLastList(false);
        setActiveSection(section);
        requestQuestions(section, { ...apiSettings, page: 0 });
        searchParams.set(EFAQFilters.section, section);
        setSearchParams(searchParams);
      }
    },
    [activeSection, apiSettings, requestQuestions, searchParams, setSearchParams]
  );

  const onChangeActiveTab = useCallback((id: string) => {
    setApiSettings(getFAQSubscriberApiSettings());
    setActiveTab(id);
  }, []);

  const setValuesFromUrl = useCallback(
    (values: any) => {
      setLastList(false);
      const newApiSettings: IFAQSubscribersApiSettings = {
        ...apiSettings,
        page: 0,
        companyId: values[EFAQFilters.companyId] || '',
        objectId: values[EFAQFilters.objectId] || '',
        status: values[EFAQFilters.status] || '',
        search: values[EFAQFilters.search] || '',
      };
      requestQuestions(searchParams.get(EFAQFilters.section) || '', newApiSettings);
    },
    [apiSettings, requestQuestions, searchParams]
  );

  const objectSelectItems = useMemo(
    () =>
      systems?.items?.map<ISelectOption>((system) => ({
        value: system.id || '',
        title: system.shortName || system.objectName || '',
      })),
    [systems?.items]
  );

  const organizationOptions = useMemo(
    () =>
      isAdmin
        ? companies?.map<ISelectOption>((object) => ({
            value: object.id || object.id || '',
            title: object.companyName || '',
          }))
        : [],
    [companies, isAdmin]
  );

  const blockDragAndDrop = useMemo(
    () =>
      !(
        apiSettings.search === '' &&
        !apiSettings.objectId &&
        !apiSettings.status &&
        apiSettings.status?.toString() !== '0'
      ),
    [apiSettings]
  );

  const getSearch = useCallback(
    (hidden: boolean) => ({
      type: EFilterTool.search,
      id: EFAQFilters.search,
      hidden,
      props: {
        showClear: true,
        placeholder: 'Поиск по вопросу',
        value: apiSettings.search,
        onSearch: handleOnSearch,
        disabled: isChangeMode,
        isDisabledStyle: isChangeMode,
      },
    }),
    [apiSettings.search, handleOnSearch, isChangeMode]
  );

  return (
    <>
      <CommonHead seo={{ title: 'Конструктор FAQ абонентов' }} />
      <div className="faq-subscribers">
        <FiltersTool
          setValuesFromUrl={setValuesFromUrl}
          rows={[
            isAdmin
              ? [
                  {
                    type: EFilterTool.extraContent,
                    props: {
                      children: <TabSwitch tabs={adminTabs} active={activeTab} onChange={onChangeActiveTab} />,
                    },
                  },
                  getSearch(activeTab !== EFAQAdminTabs.all),
                ]
              : [
                  {
                    type: EFilterTool.select,
                    id: EFAQFilters.status,
                    props: {
                      showClear: true,
                      isAllOption: true,
                      title: 'Статус',
                      value: apiSettings.status || '',
                      onChange: handleOnChangeSelect('status'),
                      containerClassName: 'faq-subscribers__first-select',
                      disabled: isChangeMode,
                      isDisabledStyle: true,
                      options: [
                        { value: '1', title: 'Активен', color: StatusIconColor.green },
                        { value: '0', title: 'Неактивен', color: StatusIconColor.red },
                      ],
                    },
                  },
                  {
                    type: EFilterTool.select,
                    id: EFAQFilters.companyId,
                    hidden: !(isAdmin && activeTab === EFAQAdminTabs.all),
                    props: {
                      containerClassName: 'faq-subscribers__second-select',
                      showClear: true,
                      isAllOption: true,
                      title: 'Организация',
                      value: apiSettings.companyId || '',
                      onChange: handleOnChangeSelect('companyId'),
                      disabled: isChangeMode,
                      isDisabledStyle: isChangeMode,
                      options: organizationOptions,
                    },
                  },
                  {
                    type: EFilterTool.select,
                    id: EFAQFilters.objectId,
                    hidden: !(!isAdmin || activeTab === EFAQAdminTabs.all),
                    props: {
                      showClear: true,
                      isAllOption: true,
                      containerClassName: 'faq-subscribers__second-select',
                      title: 'Объект',
                      value: apiSettings.objectId || '',
                      onChange: handleOnChangeSelect('objectId'),
                      disabled: isChangeMode,
                      isDisabledStyle: isChangeMode,
                      options: objectSelectItems,
                    },
                  },
                  getSearch(!((isAdmin && activeTab === EFAQAdminTabs.platform) || !isAdmin)),
                ],
          ]}
        />

        <div
          className={classNames('faq-subscribers__content', {
            'faq-subscribers__content_short': isAdmin,
          })}
        >
          <FaqSections
            blockDragAndDrop={blockDragAndDrop}
            permissions={permissions}
            isChangeMode={isChangeMode}
            apiSettings={apiSettings}
            isAdmin={isAdmin}
            activeTab={activeTab}
            activeSection={activeSection}
            setActiveSection={onChangeActiveSection}
          />
          <FaqTable
            blockDragAndDrop={blockDragAndDrop}
            permissions={permissions}
            isChangeMode={isChangeMode}
            setIsChangeMode={setWasChange}
            currPage={apiSettings.page}
            isSearch={!lodash.isEqual(getFAQSubscriberApiSettings(), apiSettings)}
            isLastList={isLastList}
            isAdmin={isAdmin}
            handleOnChangeTablePage={handleOnChangeTablePage}
            activeSection={activeSection}
            requestQuestions={requestQuestions}
            systems={systems?.items || []}
            activeTab={activeTab}
            questions={questionsList}
          />
        </div>
      </div>
    </>
  );
};

export default SubscribersFaqPage;
