import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { FC, useCallback, useMemo } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router';
import classNames from 'classnames';
import { IUsersTable } from './types';
import Scrollbar from '../ui/scrollbar';
import Loader from '../ui/loader';
import { ELoaderColor } from '../ui/loader/types';
import ErrorPlaceholder from '../ui/errorPlaceholder';
import SearchFailIcon from '../../assets/svg/icons/searchFail';
import ChevronLeft from '../../assets/svg/icons/chevronLeft';
import ChevronRight from '../../assets/svg/icons/chevronRight';
import { paths } from '../../constants/paths';
import { dateFormatNoTime } from '../../constants/date';
import { IUser } from '../../typings/users';

const UsersTable: FC<IUsersTable> = (props) => {
  const {
    isAdmin = false,
    users = [],
    loading = false,
    pageSize = 10,
    total = 0,
    onChangePage = () => {},
    currentPage = 0,
    isSearch = false,
  } = props;

  const navigate = useNavigate();

  const onRowClick = useCallback(
    (id: string) => {
      navigate(`${paths.users}/${id}`);
    },
    [navigate]
  );

  const columns = useMemo<ColumnsType<IUser>>(
    () =>
      [
        {
          title: 'ФИО',
          dataIndex: 'fio',
          key: 'fio',
          render: (_: any, record: IUser) => (
            <div className="editable-table__data-container">
              <div className={classNames('users-table__status', { 'users-table__status_active': record.isActive })} />
              {record.fio || '-'}
            </div>
          ),
        },
        {
          title: 'Объекты',
          render: (record: IUser) => (
            <div className="editable-table__data-container editable-table__data-container_no-flex">
              {record.availableObjects === null ? (
                <div className="users-table__object">Все</div>
              ) : (
                record.availableObjects.map((item, index) => (
                  <div className="users-table__object" key={`${index}-${item.objectId}`}>
                    {item.objectName}
                    {index !== record.availableObjects.length - 1 ? ', ' : ''}
                  </div>
                ))
              )}
            </div>
          ),
        },
        {
          hidden: !isAdmin,
          title: 'Организация',
          render: (record: IUser) => <div className="editable-table__data-container">{record.companyName}</div>,
        },
        {
          title: 'E-mail',
          dataIndex: 'email',
          key: 'email',
          render: (_: any, record: IUser) => <div className="editable-table__data-container">{record.email}</div>,
        },
        {
          title: 'Дата изменения',
          dataIndex: 'updateDate',
          key: 'updateDate',
          render: (_: any, record: IUser) => (
            <div className="editable-table__data-container">
              {record.updateDate ? moment(record.updateDate).format(dateFormatNoTime) : '-'}
            </div>
          ),
        },
        {
          title: 'Статус приглашения',
          dataIndex: 'inviteStatus',
          key: 'inviteStatus',
          render: (_: any, record: IUser) => (
            <div className="editable-table__data-container">
              {record.inviteStatus ? 'Принял приглашение' : 'Не принял приглашение'}
            </div>
          ),
        },
      ].filter((item) => !item.hidden),
    [isAdmin]
  );

  const data = useMemo(
    () =>
      users.map((item, index) => ({
        ...item,
        key: index,
      })),
    [users]
  );

  return (
    <div className="users-table editable-table">
      <Scrollbar>
        <Table
          scroll={{ x: '100vh' }}
          onRow={(record) => ({
            onClick: () => onRowClick(record.id || ''),
          })}
          columns={columns}
          dataSource={data}
          loading={{
            spinning: loading,
            indicator: <Loader color={ELoaderColor.blue} />,
          }}
          showSorterTooltip={false}
          locale={{
            emptyText: isSearch ? (
              <ErrorPlaceholder text="По вашему запросу ничего не найдено" icon={<SearchFailIcon />} />
            ) : (
              <ErrorPlaceholder text={<span>Ничего не найдено</span>} icon={<SearchFailIcon />} />
            ),
          }}
          pagination={{
            current: currentPage + 1,
            pageSize,
            hideOnSinglePage: true,
            total,
            onChange: onChangePage,
            showSizeChanger: false,
            showQuickJumper: false,
            prevIcon: <ChevronLeft />,
            nextIcon: <ChevronRight />,
            className: 'editable-table__pagination',
          }}
        />
      </Scrollbar>
    </div>
  );
};

export default UsersTable;
