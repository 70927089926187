import { IAccessPointDeviceCategory } from '../treeNode';

export enum ERestrictsType {
  number = 'Number',
  string = 'String',
  array = 'Array',
  boolean = 'Boolean',
  arrayNumberRange = 'ArrayNumberRange',
  numberRange = 'NumberRange',
  range = 'Range',
  text = 'Text',
  select = 'Select',
}

export interface IHardwareRange {
  from: number | null;
  to: number | null;
}

export interface IHardwareType {
  id: string | null;
  name: string | null;
  description: string | null;
  code: string | null;
  isIpCamAvailable: false;
  deviceCategoryId?: string;
}

export interface IRestrict {
  type: ERestrictsType;
  restricts: any;
}

export interface INumberRestrict extends IRestrict {
  restricts: {
    maxValue: number | string;
    minValue: number | string;
  };
}

export interface IStringRestrict extends IRestrict {
  restricts: {
    maxLength: number | string;
    minLength: number | string;
  };
}

export interface IArrayRestrict extends IRestrict {
  restricts: {
    type: ERestrictsType.array;
    maxCount: number | string;
  };
}

export interface INumberRangeRestrict extends IRestrict {
  restricts: {
    type: ERestrictsType.numberRange;
    ToMaxValue: number | string;
    ToMinValue: number | string;
    FromMaxValue: number | string;
    FromMinValue: number | string;
  };
}

export interface IHardwareParam {
  id: string | null;
  name: string | null;
  description: string | null;
  groupName: string | null;
  groupNameOrder: number | null;
  order: number | null;
  isRequired: boolean;
  readOnly?: boolean;
  defaultValue: any;
  value: any;
  type: string;
  isError?: boolean;
  errorText?: string;
  errorData?: any;
  wasChange?: boolean;
  restricts: IRestrict;
}

export interface ICommutatorSetting {
  sinkIndex: number;
  commutatorId?: string;
  selectedDeviceId: string;
  isCameraValid: boolean;
  isGcCameraValid: boolean;
}

export interface IHardwareParamNumber extends IHardwareParam {
  value: number | null;
  defaultValue: number | null;
  restricts: INumberRestrict;
}

export interface IHardwareParamString extends IHardwareParam {
  value: string | null;
  defaultValue: string | null;
  restricts: IStringRestrict;
}

export interface IHardwareParamBool extends IHardwareParam {
  value: boolean;
  defaultValue: boolean;
}

export interface IHardwareParamArray extends IHardwareParam {
  value: null | Array<number | null>;
  defaultValue: null | Array<number | null>;
  restricts: IArrayRestrict;
}

export interface IHardwareParamArrayNumberRange extends IHardwareParam {
  value: null | Array<IHardwareRange>;
  defaultValue: null | Array<IHardwareRange>;
  restricts: IArrayRestrict;
}

export interface IHardwareParamNumberRange extends IHardwareParam {
  value: null | IHardwareRange;
  defaultValue: null | IHardwareRange;
  restricts: INumberRangeRestrict;
}

export interface IHardwareCameraParam {
  autoAvailable: boolean;
  defaultValue: string;
  description: string;
  groupName: string;
  id: string;
  name: string;
  systemName: string;
  type: ERestrictsType;
  value: string;
  availableValues: string[];
  isError?: boolean;
  errorText?: string;
  errorData?: any;
  wasChange?: boolean;
  isRequired: boolean;
}

export interface IHardwareCameraSettings {
  description: string;
  deviceId: string;
  id: string;
  innerLogin: string;
  innerPassword: string;
  mac: string;
  name: string;
  rtspServerId: string;
  rtspStream: string;
  videoServerId: string;
  videoServerUrl: string;
  externalId?: string;
  parameters: IHardwareCameraParam[];
}

export interface IHardwareSettingsShort {
  id: string | null;
  selectedDeviceTypeId: string | null;
  selectedDeviceVersionRevisionId?: string;
  name: string | null;
  serialNumber: string | null;
  networkAddress: string | null;
  sipNumber?: string | null;
  sipPassword?: string | null;
  deviceCategory?: IAccessPointDeviceCategory;
  isArchiveSoftwareVersion: boolean;
  isFileDownloadAvailable: boolean;
  selectedDeviceVersionFileName: string;
  isUpdateAvailable: boolean;
  selectedDeviceRevisionId: string;
  selectedDeviceVersionId: string;
  isIpCamAvailable: boolean;
  isNew: boolean;
  selectedDeviceRevision: string;
  selectedDeviceType: string;
  selectedDeviceTypeCode: string;
  selectedDeviceVersion: string;
  cameraId: string;
  isConnected: boolean;
  isLocalConnection: boolean;
  lastSyncDateTime: string;
  netAddress: string;
  currentNetAddress: string;
  netPassword: string;
  syncVersion: string;
  cameraSetting: IHardwareCameraSettings;
}

export interface IHardwareSettings extends IHardwareSettingsShort {
  isUpdateAvailable: boolean;
  accessPointTypeParams: IHardwareParam[] | null;
  accessPointTypeValues?: IHardwareParam[] | null;
  commutatorSettings?: ICommutatorSetting[] | null;
}

export interface IHardwareSettingsSave extends IHardwareSettingsShort {
  isUpdateAvailable: boolean;
  accessPointTypeParams: IHardwareShortParam[];
}

export interface IHardwareShortParam {
  paramId: string;
  value: string | number | IHardwareRange[] | number[] | boolean | IHardwareRange | null;
}

export interface IHardwareSettingsRtspServer {
  id: string;
  name: string;
  description: string;
  apiUrl: string;
  rtspConnectPort: number;
  login: string;
  password: string;
}

export interface IHardwareSettingsVideoServer {
  apiUrl: string;
  address: string;
  description: string;
  id: string;
  login: string;
  name: string;
  password: string;
  rangeExcludeIpAddresses: string[];
  rangeIpAddress: string;
  rangeMask: string;
  rtspConnectPort: number;
}

export interface IHardwareRevision {
  id: string;
  name: string;
  order: number;
}

export interface IHardwareVersion {
  id: string;
  isActive: boolean;
  isFileDownloadAvailable: boolean;
  isIpCamAvailable: boolean;
  name: string;
  order: number;
}
