import React, { FC, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { createSearchParams } from 'react-router-dom';
import { serviceKeyUrl } from '../../../../constants/api';
import { useApi } from '../../../../hooks/useApi';
import { ISectionServiceKeys } from './types';
import { getRequest } from '../../../../api';
import EquipmentTree from '../../../ui/equipmentTree';
import { IAccessPoint, ITreeEntityKey, ITreeNode, TreeNodeType } from '../../../../typings/treeNode';
import { paths } from '../../../../constants/paths';
import { EPageQueryParams } from '../../../../typings/searchParams';
import { ESystemTabsIds } from '../../../../pages/systems/item/types';
import { findTreeNodeByKey, resolveTreeKey } from '../../../../utils/tree';
import { editSystem } from '../../../../api/systems';

const SectionServiceKeys: FC<ISectionServiceKeys> = (props) => {
  const { systemId = '', keyId = '', tabId = '', activeTabKey = '', permissions = {} } = props;

  const { data: deviceTree, sendRequest: getDeviceTree } = useApi<ITreeNode[]>(getRequest);
  const { sendRequest: updateDeviceTree } = useApi(editSystem);

  const navigate = useNavigate();

  useEffect(() => {
    if (keyId && tabId === activeTabKey) {
      getDeviceTree(serviceKeyUrl(systemId, keyId));
    }
  }, [keyId, tabId, activeTabKey]);

  const handleOnEditTree = useCallback(
    async (key: string, updatedNode: IAccessPoint) => {
      if (deviceTree) {
        const resolveKeys: ITreeEntityKey[] = resolveTreeKey(key);
        const updatedTree = [...deviceTree];
        const { parent, nodeIndex } = findTreeNodeByKey(resolveKeys, updatedTree);
        if (parent) {
          const lastKey = resolveKeys[resolveKeys.length - 1];
          if (lastKey.type === TreeNodeType.accessPoint && parent?.accessPoints) {
            const newNode = { ...updatedNode };
            delete newNode.dataNodeProps;
            parent.accessPoints[nodeIndex] = newNode;
          }
        }
        await updateDeviceTree(serviceKeyUrl(systemId, keyId), updatedTree);
        getDeviceTree(serviceKeyUrl(systemId, keyId));
      }
    },
    [deviceTree, getDeviceTree, keyId, systemId, updateDeviceTree]
  );

  const goToSystemTab = useCallback(() => {
    navigate({
      pathname: `${paths.systems}/${systemId}`,
      search: `?${createSearchParams({ [`${EPageQueryParams.tabId}`]: ESystemTabsIds.accessGroups })}`,
    });
  }, [navigate, systemId]);

  return (
    <EquipmentTree
      data={deviceTree || []}
      isEditable
      onEdit={handleOnEditTree}
      onClickErrorButton={goToSystemTab}
      permissionObject={permissions}
    />
  );
};

export default SectionServiceKeys;
