import React, { FC, useCallback, useState } from 'react';
import { flatsSkinIndexUrl } from '../../../../constants/api';
import { useApi } from '../../../../hooks/useApi';
import Button from '../../../ui/button';
import { ButtonSize, ButtonType } from '../../../ui/button/types';
import Modal from '../../../ui/modal';
import { ISkinIndexModal } from './types';
import SelectMultiple from '../../../ui/selectMultiple';
import { selectAllOptionKey } from '../../../../constants/select';
import { ISelectOption } from '../../../ui/select/types';
import { postRequest } from '../../../../api';

const SkinIndexModal: FC<ISkinIndexModal> = (props) => {
  const { isOpen = false, onCancel = () => {}, onOk = () => {}, sections = [], objectId = '' } = props;

  const [selectedSections, setSelectedSections] = useState<string[]>([selectAllOptionKey]);

  const { sendRequest } = useApi(postRequest);

  const handleOnSend = useCallback(async () => {
    const error = await sendRequest(
      flatsSkinIndexUrl(objectId),
      selectedSections[0] === selectAllOptionKey ? sections.map((item) => item.id) : selectedSections
    );
    if (!error) {
      onOk();
    }
  }, [objectId, onOk, sections, selectedSections, sendRequest]);

  const handleOnChangeSection = useCallback((data: string[]) => {
    setSelectedSections(data);
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onCancel={onCancel}
      title="Нумерация клемм"
      width={416}
      showCloseIcon
      wrapClassName="skin-index-modal__wrapper"
      centered
      footer={
        <div className="skin-index-modal__button-container">
          <Button type={ButtonType.secondary} size={ButtonSize.small} onClick={onCancel}>
            Отмена
          </Button>
          <Button size={ButtonSize.small} onClick={handleOnSend}>
            Назначить
          </Button>
        </div>
      }
    >
      <div className="skin-index-modal">
        <SelectMultiple
          title="Подъезд"
          onChange={handleOnChangeSection}
          values={selectedSections}
          options={[
            { value: selectAllOptionKey, data: 'Все', title: 'Все' },
            ...sections.map<ISelectOption>((section) => ({
              value: section.id,
              title: section.name,
            })),
          ]}
        />
      </div>
    </Modal>
  );
};

export default SkinIndexModal;
