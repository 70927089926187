import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useApi } from '../../../hooks/useApi';
import { companyUrl, getObjectListUrl, tariffSettingsUrl } from '../../../constants/api';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { checkIsAdmin, getProfilePermission } from '../../../store/selectors/profile';
import { ESidebarItemIds } from '../../../typings/sidebar';
import { setHeaderTitle } from '../../../store/slices/header';
import CommonHead from '../../../components/commonHead';
import { ISystem } from '../../../typings/systems/system';
import { getSystem } from '../../../api/systems';
import TabsCustom from '../../../components/tabsCustom';
import { ICustomTab } from '../../../components/tabsCustom/types';
import TariffItem from '../../../components/tariffItem';
import { ISelectOption } from '../../../components/ui/select/types';
import { ITariffCustomSettings } from './types';
import { ITariffSetting, ITariffSettingsDiscount } from '../../../typings/systems/tariff';
import Message from '../../../components/message';
import { IApiError } from '../../../typings/api';
import { setTariffs } from '../../../api/tariffs';
import { IConfirmData } from '../../../components/ui/universalModal/types';
import { defaultConfirm, saveChangesModal } from '../../../components/ui/universalModal/config';
import UniversalModal from '../../../components/ui/universalModal';
import { IOrganization } from '../../../typings/organization';
import { getClickedSidebarTab } from '../../../store/selectors/sidebar';
import { getWasChange } from '../../../store/selectors/changes';
import { setChange } from '../../../store/slices/changes';
import { setClickedSidebarTab } from '../../../store/slices/sidebar';
import FiltersTool from '../../../components/ui/filtersTool';
import { EFilterTool } from '../../../typings/filtersTool';

const TariffsCostPage: FC = () => {
  const isAdmin = useAppSelector(checkIsAdmin);

  const dispatch = useAppDispatch();

  const permissions = useAppSelector(getProfilePermission(ESidebarItemIds.tariffCost));

  const { data: companies, sendRequest: getCompanies, loading: companiesLoading } = useApi<IOrganization[]>(getSystem);
  const { data, sendRequest, loading } = useApi<ISystem[]>(getSystem);
  const { sendRequest: setTariffSettings, loading: setTariffSettingsLoading } = useApi(setTariffs);

  const [eventsApiParams, setEventsApiParams] = useState<{ search: string; companyId: string | null }>({
    search: '',
    companyId: '',
  });
  const [activeTab, setActiveTab] = useState('');
  const [isMaintenance, setMaintenance] = useState(false);
  const [maintenancePrice, setMaintenancePrice] = useState(0);
  const [confirmData, setConfirmData] = useState<IConfirmData>(defaultConfirm);

  const [customTariffSettings, setCustomTariffSettings] = useState<ITariffCustomSettings | null>(null);

  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();

  const clickedSidebarTab = useAppSelector(getClickedSidebarTab);

  const wasChange = useAppSelector(getWasChange);

  const setWasChange = useCallback(
    (value: boolean) => {
      dispatch(setChange(value));
    },
    [dispatch]
  );

  const getData = useCallback(
    (apiParams = eventsApiParams) => {
      sendRequest(getObjectListUrl(), {
        params: { ...apiParams, isOnlyIpSeries: true },
      });
      setEventsApiParams(apiParams);
    },
    [eventsApiParams, sendRequest]
  );

  const changeActiveTab = useCallback(
    (tabId: string) => {
      setActiveTab(tabId);
      searchParams.set('systemId', tabId);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  useEffect(() => {
    if (isAdmin) {
      getCompanies(companyUrl(), {
        params: {
          page: 0,
          count: 0,
          search: '',
        },
      });
    } else {
      getData();
    }
  }, [isAdmin]);

  useEffect(() => {
    const companyId = searchParams.get('companyId');
    if (isAdmin && companyId) {
      getData({ ...eventsApiParams, companyId });
    } else {
      getData();
    }
  }, [companies]);

  useEffect(() => {
    dispatch(setHeaderTitle('Стоимость тарифов'));
  }, []);

  useEffect(() => {
    const systemId = searchParams.get('systemId');
    if (systemId && !activeTab) {
      changeActiveTab(systemId);
    } else if (!activeTab && data && data[0]) {
      changeActiveTab(data[0].id || '');
    }
  }, [data]);

  const onChangeSettings = useCallback(
    (settings: ITariffCustomSettings, change = true) => {
      setCustomTariffSettings(settings);
      setWasChange(change);
    },
    [setWasChange]
  );

  const onSave = useCallback(async () => {
    if (customTariffSettings) {
      const saveData: ITariffSetting[] = [];
      const objectTariffDiscounts: ITariffSettingsDiscount[] = [];

      customTariffSettings.periods.forEach((item) =>
        objectTariffDiscounts.push({ monthCount: item.monthCount, discount: item.discount })
      );

      customTariffSettings.tariffs.forEach((item) =>
        saveData.push({
          version: item.version,
          additionalCompanyPrice: item.additionalCompanyPrice,
          objectTariffDiscounts: objectTariffDiscounts,
          isMaintenanceOn: isMaintenance,
          maintenancePrice: maintenancePrice,
        })
      );

      const item = data?.find((e) => e.id === activeTab);
      if (item) {
        const resError = await setTariffSettings(tariffSettingsUrl(item?.id || ''), saveData);

        const error = resError?.response?.data as IApiError;
        if (error) {
          Message.error({
            content: error?.message || 'Не удалось применить настройки',
          });
        } else {
          Message.success({
            content: 'Настройки стоимости тарифов применены',
          });
          setWasChange(false);
          return true;
        }
      }
    }
    return false;
  }, [activeTab, customTariffSettings, data, isMaintenance, maintenancePrice, setTariffSettings, setWasChange]);

  const handleOnSaveChanges = useCallback(
    (callback: () => void) => async () => {
      if (await onSave()) {
        callback();
      }
      setConfirmData(defaultConfirm);
    },
    [onSave]
  );

  const handleOnResetChanges = useCallback(
    (callback: () => void) => async () => {
      setWasChange(false);
      setConfirmData(defaultConfirm);
      callback();
    },
    []
  );

  const checkChanges = useCallback(
    async (callback: () => void) => {
      if (wasChange) {
        setConfirmData(saveChangesModal(handleOnSaveChanges(callback), handleOnResetChanges(callback)));
      } else {
        callback();
      }
    },
    [handleOnResetChanges, handleOnSaveChanges, wasChange]
  );

  useEffect(() => {
    if (clickedSidebarTab) {
      checkChanges(() => {
        setWasChange(false);
        navigate(clickedSidebarTab);
      });
      dispatch(setClickedSidebarTab(null));
    }
  }, [clickedSidebarTab]);

  const handleOnSearch = useCallback(
    async (value: string) =>
      checkChanges(() => {
        getData({ ...eventsApiParams, search: value });
        changeActiveTab('');
      }),
    [changeActiveTab, checkChanges, eventsApiParams, getData]
  );

  const handleOnChangeActiveFlatId = useCallback(
    (value: string) => checkChanges(() => changeActiveTab(value)),
    [changeActiveTab, checkChanges]
  );

  const handleOnChangeSelect = useCallback(
    (id: string | number) => checkChanges(() => getData({ ...eventsApiParams, companyId: id?.toString() || null })),
    [checkChanges, eventsApiParams, getData]
  );

  const closeConfirm = useCallback(() => {
    setConfirmData(defaultConfirm);
  }, []);

  const setValuesFromUrl = useCallback(
    (values: any) => {
      getData({ ...eventsApiParams, search: values.search || '', companyId: values.companyId || null });
    },
    [eventsApiParams, getData]
  );

  const organizationOptions = useMemo(
    () =>
      isAdmin && companies
        ? companies.map<ISelectOption>((object) => ({
            value: object.id || '',
            title: object.companyName || '',
          }))
        : [],
    [companies, isAdmin]
  );

  const organizationId = useMemo(
    () => eventsApiParams.companyId || data?.find((item) => item.id === activeTab)?.companyId || '',
    [activeTab, data, eventsApiParams.companyId]
  );

  return (
    <>
      <CommonHead seo={{ title: 'Стоимость тарифов' }} />
      <UniversalModal data={confirmData} onClose={closeConfirm} />
      <div className="tariffs-cost-page">
        <FiltersTool
          setValuesFromUrl={setValuesFromUrl}
          rows={[
            [
              {
                type: EFilterTool.select,
                id: 'companyId',
                hidden: !isAdmin,
                props: {
                  isAllOption: true,
                  showClear: true,
                  title: 'Организация',
                  value: eventsApiParams.companyId || '',
                  onChange: handleOnChangeSelect,
                  options: organizationOptions,
                },
              },
              {
                type: EFilterTool.search,
                id: 'search',
                props: {
                  showClear: true,
                  placeholder: 'Поиск по адресу и названию',
                  value: eventsApiParams.search,
                  onSearch: handleOnSearch,
                },
              },
            ],
          ]}
        />
        <TabsCustom
          activeTabKey={activeTab}
          onChangeActiveTab={handleOnChangeActiveFlatId}
          tabPaneContainerClassName="equipment-tab__tab-pane-container"
          tabPaneFieldTitleClassName="equipment-tab__tab-pane-text"
          loading={companiesLoading || setTariffSettingsLoading}
          emptyText="По вашему запросу ничего не найдено"
          tabs={(loading ? [] : data)?.map<ICustomTab>((item) => ({
            tabId: item.id || '',
            data: [
              ...(isAdmin ? [{ fieldTitle: 'Организация', fieldData: item.companyName }] : []),
              { fieldTitle: 'Объект', fieldData: item.objectName },
              { fieldTitle: 'Адрес', fieldData: item.address },
            ],
            children: null,
          }))}
        />
        {data?.length !== 0 && (
          <TariffItem
            isAdmin={isAdmin}
            isMaintenance={isMaintenance}
            maintenancePrice={maintenancePrice}
            customTariffSettings={customTariffSettings}
            systemId={activeTab}
            organizationId={organizationId}
            permissions={permissions}
            setMaintenancePrice={setMaintenancePrice}
            setMaintenance={setMaintenance}
            onChangeSettings={onChangeSettings}
            wasChange={wasChange}
            onSave={onSave}
            setWasChange={setWasChange}
          />
        )}
      </div>
    </>
  );
};

export default TariffsCostPage;
