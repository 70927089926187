import { Moment } from 'moment';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getDeviceEventEntity } from '../../api/deviceEvent';
import { deviceEventsDefaultApiParams } from '../../api/deviceEvent/config';
import { IDeviceEventApiParams } from '../../api/deviceEvent/types';
import CommonHead from '../../components/commonHead';
import DeviceEventTable from '../../components/deviceEventTable';
import { ISelectOption } from '../../components/ui/select/types';
import { getDeviceEventAccessPointsUrl, getDeviceEventEventUrl, getDeviceEventSystemUrl } from '../../constants/api';
import { selectAllOptionKey } from '../../constants/select';
import { useAppDispatch } from '../../hooks/hooks';
import { useApi } from '../../hooks/useApi';
import { setHeaderTitle } from '../../store/slices/header';
import { IApiResponse } from '../../typings/api';
import { IDeviceEventAccessPoint, IDeviceEvent, IDeviceEventSystem } from '../../typings/deviceEvent';
import { dateFormatNoTime } from '../../constants/date';
import FiltersTool from '../../components/ui/filtersTool';
import { EFilterTool } from '../../typings/filtersTool';
import { EDeviceEventsFilters } from './types';

const DeviceEvent: FC = () => {
  const dispatch = useAppDispatch();

  const { data: deviceSystems, sendRequest: sendRequestSystem } = useApi<IDeviceEventSystem[]>(getDeviceEventEntity);
  const { data: accessPoints, sendRequest: sendRequestAccessPoints } =
    useApi<IDeviceEventAccessPoint[]>(getDeviceEventEntity);
  const {
    data: events,
    sendRequest: sendRequestEvents,
    loading: eventsLoading,
  } = useApi<IApiResponse<IDeviceEvent>>(getDeviceEventEntity);
  const [eventsApiParams, setEventsApiParams] = useState<IDeviceEventApiParams>(deviceEventsDefaultApiParams);

  const [selectedDeviceSystemId, setSelectedDeviceSystemId] = useState<string>('');
  const [selectedAccessPointId, setSelectedAccessPointId] = useState<string>(selectAllOptionKey);

  const [searchParams, setSearchParams] = useSearchParams();

  const accessPointsData = useMemo(
    () => [
      { value: selectAllOptionKey, data: 'Все', title: 'Все' },
      ...(accessPoints?.map<ISelectOption>((accessPoint) => ({
        value: accessPoint.id || '',
        title: accessPoint.name || '',
      })) || []),
    ],
    [accessPoints]
  );

  const requestEvents = useCallback(
    async (newApiParams: IDeviceEventApiParams) => {
      setEventsApiParams(newApiParams);
      await sendRequestEvents(getDeviceEventEventUrl(), { params: newApiParams });
    },
    [sendRequestEvents]
  );

  useEffect(() => {
    dispatch(setHeaderTitle('Логирование событий оборудования'));
  }, [dispatch]);

  useEffect(() => {
    sendRequestSystem(getDeviceEventSystemUrl());
  }, []);

  useEffect(() => {
    if (deviceSystems && deviceSystems.length > 0) {
      const systemId = deviceSystems[0].id || '';
      setSelectedDeviceSystemId(systemId);
      sendRequestAccessPoints(getDeviceEventAccessPointsUrl(systemId));
      requestEvents({ ...eventsApiParams, intercomComplexId: systemId });
    }
  }, [deviceSystems]);

  const handleOnChangeDeviceSystem = useCallback(
    async (value: string | number) => {
      const newSystemId: string = value.toString();
      setSelectedDeviceSystemId(newSystemId);
      setSelectedAccessPointId(selectAllOptionKey);
      await sendRequestAccessPoints(getDeviceEventAccessPointsUrl(newSystemId));
      await requestEvents({ ...eventsApiParams, intercomComplexId: newSystemId, accessPointId: undefined });
      searchParams.delete(EDeviceEventsFilters.accessPointId);
      searchParams.set(EDeviceEventsFilters.systemId, newSystemId);
      setSearchParams(searchParams);
    },
    [eventsApiParams, requestEvents, searchParams, sendRequestAccessPoints, setSearchParams]
  );

  const handleOnChangeAccessPoint = useCallback(
    async (value: string | number) => {
      const newAccessPointId: string = value.toString();
      setSelectedAccessPointId(newAccessPointId);
      await requestEvents({
        ...eventsApiParams,
        accessPointId: newAccessPointId === selectAllOptionKey ? undefined : newAccessPointId,
      });
    },
    [eventsApiParams, requestEvents]
  );

  const handleOnChangeTablePage = useCallback(
    async (page: number) => {
      await requestEvents({ ...eventsApiParams, pagenumber: page - 1 });
    },
    [eventsApiParams, requestEvents]
  );

  const handleOnChangeDate = useCallback(
    async (firstDate?: Moment, secondDate?: Moment) => {
      await requestEvents({
        ...eventsApiParams,
        dateFrom: firstDate?.startOf('day').toISOString() || '',
        dateTo: secondDate?.endOf('day').toISOString() || '',
      });
    },
    [eventsApiParams, requestEvents]
  );

  const setValuesFromUrl = useCallback(
    async (values: any) => {
      if (values[EDeviceEventsFilters.systemId]) {
        setSelectedDeviceSystemId(values[EDeviceEventsFilters.systemId]);
        setSelectedAccessPointId(values[EDeviceEventsFilters.accessPointId]);
        await sendRequestAccessPoints(getDeviceEventAccessPointsUrl(values[EDeviceEventsFilters.systemId]) || '');
        await requestEvents({
          ...eventsApiParams,
          dateFrom: values.dateFrom || '',
          dateTo: values.dateTo || '',
          intercomComplexId: values[EDeviceEventsFilters.systemId],
          accessPointId: values[EDeviceEventsFilters.accessPointId],
        });
      }
    },
    [eventsApiParams, requestEvents, sendRequestAccessPoints]
  );

  return (
    <>
      <CommonHead seo={{ title: 'Логирование событий оборудования' }} />
      <div className="device-event">
        <FiltersTool
          setValuesFromUrl={setValuesFromUrl}
          rows={[
            [
              {
                type: EFilterTool.select,
                id: EDeviceEventsFilters.systemId,
                props: {
                  title: 'Краткое наименование объекта',
                  value: selectedDeviceSystemId,
                  onChange: handleOnChangeDeviceSystem,
                  options: deviceSystems?.map<ISelectOption>((system) => ({
                    value: system.id || '',
                    title: system.name || '',
                  })),
                },
              },
              {
                type: EFilterTool.select,
                id: EDeviceEventsFilters.accessPointId,
                props: {
                  title: 'Точка доступа',
                  value: selectedAccessPointId,
                  onChange: handleOnChangeAccessPoint,
                  options: accessPointsData,
                },
              },
              {
                type: EFilterTool.datePicker,
                id: EDeviceEventsFilters.date,
                props: {
                  title: 'Период',
                  placeholder: 'Выберите период',
                  firstDate: eventsApiParams.dateFrom || '',
                  secondDate: eventsApiParams.dateTo || '',
                  onChange: handleOnChangeDate,
                  showTime: false,
                  format: dateFormatNoTime,
                },
              },
            ],
          ]}
        />
        <DeviceEventTable
          events={events?.items || []}
          loading={eventsLoading}
          pagination={{
            pageSize: events?.pageSize || 0,
            currentPage: events?.page || 0,
            total: events?.totalCount || 0,
            onChangePage: handleOnChangeTablePage,
          }}
        />
      </div>
    </>
  );
};

export default DeviceEvent;
