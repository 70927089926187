import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { callCodeInitApiSettings } from '../../../../api/callCode/config';
import { getFlats } from '../../../../api/flats';
import { getCallCodesInBuildingUrl, getCallCodesInBuildingSectionUrl } from '../../../../constants/api';
import { selectAllOptionKey } from '../../../../constants/select';
import { useApi } from '../../../../hooks/useApi';
import { IApiResponse, IApiSettings, IApiSortField } from '../../../../typings/api';
import { ICallCode } from '../../../../typings/systems/callCode';
import { ButtonType } from '../../../ui/button/types';
import { ISelectOption } from '../../../ui/select/types';
import AddCallCodesModal from '../addCallCodesModal';
import CallCodesTable from '../callCodesTable';
import ClearCallCodesModal from '../clearCallCodesModal';
import { ECallCodesTabsFilters, ISectionCallCodes } from './types';
import FiltersTool from '../../../ui/filtersTool';
import { EFilterTool } from '../../../../typings/filtersTool';

const SectionCallCodes: FC<ISectionCallCodes> = (props) => {
  const {
    systemId = '',
    buildingId = '',
    sections = [],
    updateAvailableTabs = () => {},
    permissions = {},
    isFiveThousandth,
  } = props;

  const [selectedSectionId, setSelectedSectionId] = useState<string | number>(selectAllOptionKey);
  const [addCallCodesModalIsOpen, setAddCallCodesModalIsOpen] = useState<boolean>(false);
  const [clearCallCodesModalIsOpen, setClearCallCodesModalIsOpen] = useState<boolean>(false);
  const [apiSettings, setApiSettings] = useState<IApiSettings<ICallCode>>(callCodeInitApiSettings);
  const {
    data: flatsWithCallCode,
    sendRequest,
    loading: flatsWithCallCodeLoading,
  } = useApi<IApiResponse<ICallCode>>(getFlats);

  useEffect(() => {
    if (buildingId) {
      sendRequest(getCallCodesInBuildingUrl(buildingId), apiSettings);
    }
  }, [buildingId]);

  const requestFlatsWithCallCode = useCallback(
    async (reqSettings: IApiSettings<ICallCode>, buildId: string, sectionId = selectAllOptionKey) => {
      if (buildId) {
        if (sectionId === selectAllOptionKey) {
          await sendRequest(getCallCodesInBuildingUrl(buildId), reqSettings);
        } else {
          await sendRequest(getCallCodesInBuildingSectionUrl(buildId, sectionId), reqSettings);
        }
      }
      updateAvailableTabs();
    },
    [sendRequest, updateAvailableTabs]
  );

  const reloadFlatsWithCallCode = useCallback(async () => {
    await requestFlatsWithCallCode(apiSettings, buildingId, selectedSectionId.toString());
  }, [apiSettings, buildingId, requestFlatsWithCallCode, selectedSectionId]);

  const handleOnChangeSection = useCallback(
    async (sectionId: string | number) => {
      setSelectedSectionId(sectionId);
      const newApiSettings: IApiSettings<ICallCode> = {
        ...callCodeInitApiSettings,
        sortFields: apiSettings.sortFields,
      };
      await requestFlatsWithCallCode(newApiSettings, buildingId, sectionId.toString());
      setApiSettings(newApiSettings);
    },
    [apiSettings, buildingId, requestFlatsWithCallCode]
  );

  const handleOnSearch = useCallback(
    async (value: string) => {
      const newApiSettings: IApiSettings<ICallCode> = { ...apiSettings, page: 0, search: value };
      await requestFlatsWithCallCode(newApiSettings, buildingId, selectedSectionId?.toString());
      setApiSettings(newApiSettings);
    },
    [apiSettings, buildingId, requestFlatsWithCallCode, selectedSectionId]
  );

  const handleOnChangeTablePage = useCallback(
    async (page: number) => {
      const newApiSettings: IApiSettings<ICallCode> = { ...apiSettings, page: page - 1 };
      await requestFlatsWithCallCode(newApiSettings, buildingId, selectedSectionId?.toString());
      setApiSettings(newApiSettings);
    },
    [apiSettings, buildingId, requestFlatsWithCallCode, selectedSectionId]
  );

  const handleOnSort = useCallback(
    async (sortResults: IApiSortField<ICallCode>[]) => {
      const newApiSettings: IApiSettings<ICallCode> = { ...apiSettings, sortFields: sortResults };
      await requestFlatsWithCallCode(newApiSettings, buildingId, selectedSectionId?.toString());
      setApiSettings(newApiSettings);
    },
    [apiSettings, buildingId, requestFlatsWithCallCode, selectedSectionId]
  );

  const handleOnOkModal = useCallback(
    async (setter: React.Dispatch<React.SetStateAction<boolean>>) => {
      setter(false);
      await requestFlatsWithCallCode(apiSettings, buildingId, selectedSectionId.toString());
    },
    [apiSettings, buildingId, requestFlatsWithCallCode, selectedSectionId]
  );

  const setValuesFromUrl = useCallback(
    async (values: any) => {
      setSelectedSectionId(values[ECallCodesTabsFilters.section] || selectAllOptionKey);
      const newApiSettings: IApiSettings<ICallCode> = {
        ...callCodeInitApiSettings,
        sortFields: apiSettings.sortFields,
        search: values[ECallCodesTabsFilters.search] || '',
      };
      await requestFlatsWithCallCode(
        newApiSettings,
        buildingId,
        values[ECallCodesTabsFilters.section] || selectAllOptionKey
      );
      setApiSettings(newApiSettings);
    },
    [apiSettings.sortFields, buildingId, requestFlatsWithCallCode]
  );

  return (
    <div className="section-call-codes">
      <AddCallCodesModal
        isFiveThousandth={isFiveThousandth}
        isOpen={addCallCodesModalIsOpen}
        onOk={() => handleOnOkModal(setAddCallCodesModalIsOpen)}
        onCancel={() => setAddCallCodesModalIsOpen(false)}
        sections={sections}
        systemId={systemId}
        buildingId={buildingId}
      />
      <ClearCallCodesModal
        isFiveThousandth={isFiveThousandth}
        isOpen={clearCallCodesModalIsOpen}
        onOk={() => handleOnOkModal(setClearCallCodesModalIsOpen)}
        onCancel={() => setClearCallCodesModalIsOpen(false)}
        sections={sections}
        systemId={systemId}
        buildingId={buildingId}
      />
      <FiltersTool
        setValuesFromUrl={setValuesFromUrl}
        rows={[
          [
            {
              type: EFilterTool.select,
              id: ECallCodesTabsFilters.section,
              props: {
                title: 'Подъезд',
                onChange: handleOnChangeSection,
                value: selectedSectionId,
                options: [
                  { value: selectAllOptionKey, data: 'Все', title: 'Все' },
                  ...sections.map<ISelectOption>((section) => ({
                    value: section.id,
                    title: section.name,
                  })),
                ],
              },
            },
            {
              type: EFilterTool.search,
              id: ECallCodesTabsFilters.search,
              props: {
                placeholder: 'Поиск по квартире, кодам вызова',
                value: apiSettings.search,
                onSearch: handleOnSearch,
              },
            },
            {
              type: EFilterTool.button,
              hidden: !permissions.edit,
              props: {
                type: ButtonType.tertiary,
                onClick: () => setClearCallCodesModalIsOpen(true),
                children: 'Очистить',
                className: 'section-call-codes__button',
              },
            },
            {
              type: EFilterTool.button,
              hidden: !permissions.edit,
              props: {
                onClick: () => setAddCallCodesModalIsOpen(true),
                children: 'Заполнить',
                className: 'section-call-codes__button',
              },
            },
          ],
        ]}
      />
      <div className="section-call-codes__content-wrapper">
        <CallCodesTable
          isFiveThousandth={isFiveThousandth}
          callCodes={flatsWithCallCode?.items || []}
          loading={flatsWithCallCodeLoading}
          pageSize={flatsWithCallCode?.pageSize}
          total={flatsWithCallCode?.totalCount}
          currentPage={flatsWithCallCode?.page}
          onChangePage={handleOnChangeTablePage}
          systemId={systemId}
          onEditRow={reloadFlatsWithCallCode}
          onSort={handleOnSort}
          sortOrders={apiSettings.sortFields}
          isSearch={!!apiSettings.search}
          permissions={permissions}
        />
      </div>
    </div>
  );
};

export default SectionCallCodes;
