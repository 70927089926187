import classNames from 'classnames';
import React, { FC, useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Moment } from 'moment';
import Button from '../button';
import InputSearch from '../inputSearch';
import Select from '../select';
import { IFilterTool, IFilterToolRow } from './types';
import { EFilterTool } from '../../../typings/filtersTool';
import SelectMultiple from '../selectMultiple';
import PeriodDatePicker from '../periodDatePicker';
import { dateISOFormatNoTimeZone } from '../../../constants/date';

const FiltersTool: FC<IFilterTool> = (props) => {
  const { rows = [], wrapperClassName = '', setValuesFromUrl = () => {}, setValuesWithDelay = true } = props;

  const [searchParams, setSearchParams] = useSearchParams();

  const setValues = useCallback(() => {
    const values: any = {};

    rows.forEach((row) =>
      row.forEach((item) => {
        if (item.id) {
          const value = searchParams.get(item.id);
          if (value) values[item.id] = value;
          else if (item.type === EFilterTool.datePicker) {
            const dateFrom = searchParams.get('dateFrom');
            if (dateFrom) values.dateFrom = dateFrom;
            const dateTo = searchParams.get('dateTo');
            if (dateTo) values.dateTo = dateTo;
          }
        }
      })
    );

    setValuesFromUrl(values);
  }, [rows, searchParams, setValuesFromUrl]);

  useEffect(() => {
    if (setValuesWithDelay) setTimeout(() => setValues(), 500);
    else setValues();
  }, []);

  const onChange = useCallback(
    (item: IFilterToolRow) => (value: any) => {
      if (item.id && item.props.onChange) {
        item.props.onChange(value);
        searchParams.set(item.id, value);
        setSearchParams(searchParams);
      }
    },
    [searchParams, setSearchParams]
  );

  const onSearch = useCallback(
    (item: IFilterToolRow) => (value: string) => {
      if (item.id && item.props.onSearch) {
        item.props.onSearch(value);
        searchParams.set(item.id, value);
        setSearchParams(searchParams);
      }
    },
    [searchParams, setSearchParams]
  );

  const onChangeDate = useCallback(
    (item: IFilterToolRow) => (firstDate?: Moment, secondDate?: Moment) => {
      if (item.id && item.props.onChange) {
        item.props.onChange(firstDate, secondDate);
        searchParams.set('dateFrom', firstDate?.startOf('day').format(dateISOFormatNoTimeZone) || '');
        searchParams.set('dateTo', secondDate?.endOf('day').format(dateISOFormatNoTimeZone) || '');
        setSearchParams(searchParams);
      }
    },
    [searchParams, setSearchParams]
  );

  const renderItem = useCallback(
    (item: IFilterToolRow) => {
      let res;

      if (!item?.hidden)
        switch (item.type) {
          case EFilterTool.select: {
            res = (
              <Select
                {...item.props}
                containerClassName={classNames(
                  'filters-tool__item filters-tool__select',
                  item.props.containerClassName || ''
                )}
                onChange={onChange(item)}
              />
            );
            break;
          }
          case EFilterTool.multiSelect: {
            res = (
              <SelectMultiple
                {...item.props}
                containerClassName={classNames(
                  'filters-tool__item filters-tool__multi-select',
                  item.props.containerClassName || ''
                )}
                onChange={onChange(item)}
              />
            );
            break;
          }
          case EFilterTool.button: {
            res = (
              <Button
                {...item.props}
                className={classNames('filters-tool__item filters-tool__button', item.props.containerClassName || '')}
              >
                {item.props.children}
              </Button>
            );
            break;
          }
          case EFilterTool.search: {
            res = (
              <InputSearch
                {...item.props}
                onSearch={onSearch(item)}
                containerClassName={classNames(
                  'filters-tool__item filters-tool__search',
                  item.props.containerClassName
                )}
              />
            );
            break;
          }
          case EFilterTool.datePicker: {
            res = (
              <PeriodDatePicker
                {...item.props}
                onChange={onChangeDate(item)}
                containerClassName={classNames('filters-tool__item filters-tool__date', item.props.containerClassName)}
              />
            );
            break;
          }
          case EFilterTool.extraContent: {
            res = item.props.children;
            break;
          }
          default: {
            res = null;
          }
        }

      return res;
    },
    [onChange, onChangeDate, onSearch]
  );

  return (
    <div className={classNames('filters-tool', wrapperClassName || '')}>
      {rows.map((row, index) => (
        <div
          key={`row-${index}`}
          className={classNames('filters-tool__row', {
            'filters-tool__row_hide': row.filter((item) => !item.hidden).length === 0,
          })}
        >
          {row.map((item) => renderItem(item))}
        </div>
      ))}
    </div>
  );
};

export default FiltersTool;
