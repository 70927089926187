import { Moment } from 'moment';
import React, { FC, useCallback, useEffect, useState } from 'react';
import CommonHead from '../../../components/commonHead';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { setHeaderTitle } from '../../../store/slices/header';
import { useApi } from '../../../hooks/useApi';
import {
  companiesObjectIdUrl,
  companiesObjectsUrl,
  companiesPaymentUrl,
  paymentsListUrl,
} from '../../../constants/api';
import { checkIsAdmin } from '../../../store/selectors/profile';
import PaymentsTable from '../../../components/paymentsTable';
import { getPaymentsData, setPayments } from '../../../api/payments';
import PaymentsFilters from '../../../components/paymentsFilters';
import { ICompany, IObject, IPayment, paymentStatusNamesMap } from '../../../typings/payments';
import { IApiResponse, IApiSortField } from '../../../typings/api';
import { getPaymentApiSettings } from '../../../api/payments/config';
import { IPaymentApiParams, IPaymentsApiSettings } from '../../../api/payments/types';
import { dateISOFormatNoTimeZone } from '../../../constants/date';
import { EPaymentsFilters } from '../../../components/paymentsFilters/types';

const PaymentsPage: FC = () => {
  const dispatch = useAppDispatch();
  const isAdmin = useAppSelector(checkIsAdmin);

  const { data: companies, sendRequest: getCompanies, loading: companiesLoading } = useApi<ICompany[]>(getPaymentsData);
  const { data: objects, sendRequest: getObjects, loading: objectsLoading } = useApi<IObject[]>(getPaymentsData);
  const {
    data: payments,
    sendRequest: getPayments,
    loading: paymentsLoading,
  } = useApi<IApiResponse<IPayment>>(setPayments);

  const [apiSettings, setApiSettings] = useState<IPaymentsApiSettings<IPayment>>(
    getPaymentApiSettings('paymentCreateDate')
  );
  const [eventsApiParams, setEventsApiParams] = useState<IPaymentApiParams>({});

  const [searchValue, setSearchValue] = useState<string>('');

  useEffect(() => {
    dispatch(setHeaderTitle('Платежи абонентов'));
  }, []);

  useEffect(() => {
    if (isAdmin) {
      getCompanies(companiesPaymentUrl());
    }
  }, [isAdmin]);

  useEffect(() => {
    getPayments(paymentsListUrl(), apiSettings);
    getObjects(companiesObjectsUrl(), { params: { isOnlyIpSeries: true } });
  }, []);

  const requestData = useCallback(
    async (
      reqSettings: IPaymentsApiSettings<IPayment> = apiSettings,
      newApiParams: IPaymentApiParams = eventsApiParams
    ) => {
      setApiSettings(reqSettings);
      setEventsApiParams(newApiParams);
      await getPayments(paymentsListUrl(), reqSettings, { params: newApiParams });
    },
    [apiSettings, eventsApiParams, getPayments]
  );

  const handleOnSearch = useCallback(
    async (value: string) => {
      setSearchValue(value);
      const newApiSettings: IPaymentsApiSettings<IPayment> = { ...apiSettings, page: 0, search: value };
      await requestData(newApiSettings);
    },
    [apiSettings, requestData]
  );

  const handleOnChangeDate = useCallback(
    async (firstDate?: Moment, secondDate?: Moment) => {
      const newApiSettings: IPaymentsApiSettings<IPayment> = { ...apiSettings, page: 0 };
      const newEventsParams = {
        ...eventsApiParams,
        startDate: firstDate?.startOf('day').format(dateISOFormatNoTimeZone) || '',
        endDate: secondDate?.endOf('day').format(dateISOFormatNoTimeZone) || '',
      };
      await requestData(newApiSettings, newEventsParams);
    },
    [apiSettings, eventsApiParams, requestData]
  );

  const handleOnChangeTablePage = useCallback(
    async (page: number) => {
      const newApiSettings: IPaymentsApiSettings<IPayment> = { ...apiSettings, page: page - 1 };
      await requestData(newApiSettings);
    },
    [apiSettings, requestData]
  );

  const handleOnSort = useCallback(
    async (sortResults: IApiSortField<IPayment>[]) => {
      const newApiSettings: IPaymentsApiSettings<IPayment> = { ...apiSettings, sortFields: sortResults };
      await requestData(newApiSettings);
    },
    [apiSettings, requestData]
  );

  const handleOnChangeSelect = useCallback(
    (key: string) => (val: string | number) => {
      const newApiSettings: IPaymentsApiSettings<IPayment> = { ...apiSettings, page: 0 };
      const newEventsParams = {
        ...eventsApiParams,
        [key]: val,
      };
      requestData(newApiSettings, newEventsParams);
      if (key === 'companyId') {
        getObjects(val ? companiesObjectIdUrl(val.toString()) : companiesObjectsUrl(), {
          params: { isOnlyIpSeries: true },
        });
      }
    },
    [apiSettings, eventsApiParams, getObjects, requestData]
  );

  const setValuesFromUrl = useCallback(
    (values: any) => {
      const newApiSettings: IPaymentsApiSettings<IPayment> = {
        ...apiSettings,
        page: 0,
        search: values[EPaymentsFilters.search] || '',
      };
      const newEventsParams = {
        ...eventsApiParams,
        startDate: values.dateFrom || '',
        endDate: values.dateTo || '',
        companyId: values[EPaymentsFilters.companyId],
        objectId: values[EPaymentsFilters.objectId],
        status: values[EPaymentsFilters.status],
      };
      setSearchValue(values[EPaymentsFilters.search] || '');
      if (values[EPaymentsFilters.companyId]) {
        getObjects(companiesObjectIdUrl(values[EPaymentsFilters.companyId]), { params: { isOnlyIpSeries: true } });
      }
      requestData(newApiSettings, newEventsParams);
    },
    [apiSettings, eventsApiParams, getObjects, requestData]
  );

  return (
    <>
      <CommonHead seo={{ title: 'Платежи абонентов' }} />
      <div className="payments">
        <PaymentsFilters
          statusMap={paymentStatusNamesMap}
          objects={objects || []}
          apiSettings={apiSettings}
          eventsApiParams={eventsApiParams}
          companies={companies || []}
          isAdmin={isAdmin}
          handleOnChangeDate={handleOnChangeDate}
          handleOnSearch={handleOnSearch}
          handleOnChangeSelect={handleOnChangeSelect}
          setValuesFromUrl={setValuesFromUrl}
        />
        <PaymentsTable
          payments={payments?.items || []}
          isAdmin={isAdmin}
          loading={companiesLoading || paymentsLoading || objectsLoading}
          pageSize={payments?.pageSize}
          total={payments?.totalCount}
          currentPage={payments?.page}
          onChangePage={handleOnChangeTablePage}
          onSort={handleOnSort}
          sortOrders={apiSettings.sortFields}
          isSearch={!!searchValue}
        />
      </div>
    </>
  );
};

export default PaymentsPage;
