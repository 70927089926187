import { IFlatEventActionType } from '../../../../typings/subscribers/deviceLog';
import { IAccessPointDevice } from '../../../../typings/treeNode';

export interface IDeviceLogData {
  flatId?: string;
  devices?: IAccessPointDevice[];
  actionTypeList?: IFlatEventActionType[];
  actionTypeListLoading?: boolean;
}

export enum EDeviceLogsFilters {
  deviceId = 'deviceId',
  type = 'type',
  date = 'date',
}
