import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import moment, { Moment } from 'moment';
import { EFlatsFilters, IFlatTabComponent } from '../types';
import { useApi } from '../../../hooks/useApi';
import { getRequest, getRequestWithArrayInParams } from '../../../api';
import {
  getFlatDevicesUrl,
  getFlatEventsUrl,
  getFlatKeysUrl,
  getFlatsSubscribersUrl,
  getSubscriberDeviceLogActionTypeListUrl,
} from '../../../constants/api';
import { dateFormatNoTime, dateISOFormatNoTimeZone } from '../../../constants/date';
import { IFlatEvent, IFlatKey, IFlatSubscriber, IFlatsPageDevice } from '../../../typings/flat';
import { IFlatDeviceEventsApiParams } from '../../../api/deviceEvent/types';
import FlatsJournalTable from './journalTable';
import { IApiResponse } from '../../../typings/api';
import { IFlatEventActionType } from '../../../typings/subscribers/deviceLog';
import { getEntitiesForSubscriber } from '../../../api/subscribers';
import { ISelectMultipleOption } from '../../ui/selectMultiple/types';
import FiltersTool from '../../ui/filtersTool';
import { EFilterTool } from '../../../typings/filtersTool';

const FlatJournalTab: FC<IFlatTabComponent> = (props) => {
  const { flatId = '', tabId, activeTabKey } = props;

  const {
    data: subscribers,
    sendRequest: getSubscribers,
    loading: subscribersLoading,
  } = useApi<IFlatSubscriber[]>(getRequest);

  const { data: devices, sendRequest: getDevices, loading: devicesLoading } = useApi<IFlatsPageDevice[]>(getRequest);

  const { data: keys, sendRequest: sendRequestKeys, loading: keysLoading } = useApi<IFlatKey[]>(getRequest);

  const {
    data: events,
    sendRequest: getEvents,
    loading: eventsLoading,
  } = useApi<IApiResponse<IFlatEvent>>(getRequestWithArrayInParams);

  const {
    data: actionTypeList,
    sendRequest: sendRequestActionTypeList,
    loading: actionTypeListLoading,
  } = useApi<IFlatEventActionType[]>(getEntitiesForSubscriber);

  const defaultApiParams = {
    page: 0,
    count: 10,
    dateTo: moment()?.toISOString() || '',
    dateFrom: moment()?.subtract(1, 'month')?.startOf('day').toISOString() || '',
  };

  const [eventsApiParams, setEventsApiParams] = useState<IFlatDeviceEventsApiParams>({ ...defaultApiParams });

  const requestData = useCallback(
    async (newApiParams = eventsApiParams) => {
      setEventsApiParams(newApiParams);
      await getEvents(getFlatEventsUrl(flatId), { params: newApiParams });
    },
    [eventsApiParams, flatId, getEvents]
  );

  useEffect(() => {
    if (tabId === activeTabKey) {
      requestData({ ...defaultApiParams });
      sendRequestKeys(getFlatKeysUrl(flatId));
      getSubscribers(getFlatsSubscribersUrl(flatId));
      getDevices(getFlatDevicesUrl(flatId));
      sendRequestActionTypeList(getSubscriberDeviceLogActionTypeListUrl());
    }
  }, [tabId, activeTabKey]);

  const handleOnChangeDate = useCallback(
    async (firstDate?: Moment, secondDate?: Moment) => {
      const newEventsParams = {
        ...eventsApiParams,
        dateFrom: firstDate?.startOf('day').format(dateISOFormatNoTimeZone) || '',
        dateTo: secondDate?.endOf('day').format(dateISOFormatNoTimeZone) || '',
        page: 0,
      };
      await requestData(newEventsParams);
    },
    [eventsApiParams, requestData]
  );

  const handleOnChangeTablePage = useCallback(
    async (page: number) => {
      const newEventsParams = {
        ...eventsApiParams,
        page: page - 1,
      };
      await requestData(newEventsParams);
    },
    [eventsApiParams, requestData]
  );

  const handleOnChangeSelect = useCallback(
    (key: string) => (val: any) => {
      const newEventsParams = {
        ...eventsApiParams,
        [key]: val,
        page: 0,
      };
      requestData(newEventsParams);
    },
    [eventsApiParams, requestData]
  );

  const setValuesFromUrl = useCallback(
    (values: any) => {
      const keysValues = (values[EFlatsFilters.keys] || '').split(',').filter((item: string) => item !== ',');
      const subscriberIds = (values[EFlatsFilters.subscriberIds] || '')
        .split(',')
        .filter((item: string) => item !== ',');

      const newEventsParams: IFlatDeviceEventsApiParams = {
        ...eventsApiParams,
        dateFrom: values.dateFrom || '',
        dateTo: values.dateTo || '',
        deviceId: values[EFlatsFilters.deviceId],
        keys: keysValues[0] === '' ? [] : keysValues,
        subscriberIds: subscriberIds[0] === '' ? [] : subscriberIds,
        type: values[EFlatsFilters.type],
        page: 0,
      };
      requestData(newEventsParams);
    },
    [eventsApiParams, requestData]
  );

  const subscribersOptions = useMemo(
    () =>
      subscribers?.map((object) => ({
        value: object.id || '',
        title: `${object.lastName} ${object.firstName} ${object.middleName}`,
      })) || [],
    [subscribers]
  );

  const keysOptions = useMemo(
    () =>
      keys?.map((object) => ({
        value: object.id || '',
        title: object.keyAlias || '',
      })) || [],
    [keys]
  );

  const devicesOptions = useMemo(
    () =>
      devices?.map((object) => ({
        value: object.id || '',
        title: object.name || '',
      })) || [],
    [devices]
  );

  const selectActionTypeListOptions = useMemo(
    () =>
      actionTypeList?.map<ISelectMultipleOption>((action) => ({
        value: action.type || '',
        title: action.displayName || '',
      })) || [],
    [actionTypeList]
  );

  return (
    <div className="flat-journal-tab subscriptions">
      <FiltersTool
        setValuesWithDelay
        setValuesFromUrl={setValuesFromUrl}
        rows={[
          [
            {
              type: EFilterTool.multiSelect,
              id: EFlatsFilters.subscriberIds,
              props: {
                isSelectedAllDefault: true,
                title: 'Абонент',
                values: eventsApiParams?.subscriberIds,
                options: subscribersOptions,
                onChange: handleOnChangeSelect('subscriberIds'),
                notAllowAllChoice: true,
                showClear: true,
              },
            },
            {
              type: EFilterTool.select,
              id: EFlatsFilters.deviceId,
              props: {
                isAllOption: true,
                title: 'Обрудование',
                value: eventsApiParams?.deviceId || '',
                onChange: handleOnChangeSelect('deviceId'),
                options: devicesOptions,
                showClear: true,
              },
            },
            {
              type: EFilterTool.multiSelect,
              id: EFlatsFilters.keys,
              props: {
                showClear: true,
                isSelectedAllDefault: true,
                title: 'Уникальный номер / псевдоним',
                values: eventsApiParams?.keys,
                options: keysOptions,
                onChange: handleOnChangeSelect('keys'),
                notAllowAllChoice: true,
              },
            },
          ],
          [
            {
              type: EFilterTool.datePicker,
              id: EFlatsFilters.date,
              props: {
                title: 'Период',
                placeholder: 'Выберите период',
                firstDate: eventsApiParams?.dateFrom,
                secondDate: eventsApiParams?.dateTo,
                onChange: handleOnChangeDate,
                showTime: false,
                format: dateFormatNoTime,
              },
            },
            {
              type: EFilterTool.select,
              id: EFlatsFilters.type,
              props: {
                showClear: true,
                isAllOption: true,
                title: 'Тип события',
                value: eventsApiParams.type || '',
                onChange: handleOnChangeSelect('type'),
                options: selectActionTypeListOptions,
              },
            },
          ],
        ]}
      />
      <FlatsJournalTable
        loading={subscribersLoading || keysLoading || eventsLoading || devicesLoading || actionTypeListLoading}
        pageSize={events?.pageSize}
        total={events?.totalCount}
        currentPage={events?.page}
        onChangePage={handleOnChangeTablePage}
        journal={events?.items || []}
      />
    </div>
  );
};

export default FlatJournalTab;
