import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';
import classNames from 'classnames';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { checkIsAdmin, getProfilePermission } from '../../../store/selectors/profile';
import { ESidebarItemIds } from '../../../typings/sidebar';
import { useApi } from '../../../hooks/useApi';
import { getRequest } from '../../../api';
import { setHeaderTitle } from '../../../store/slices/header';
import CommonHead from '../../../components/commonHead';
import { paths } from '../../../constants/paths';
import { dispatcherPanelBaseInfoUrl } from '../../../constants/api';
import ButtonLink from '../../../components/ui/buttonLink';
import ArrowBackIcon from '../../../assets/svg/icons/arrowBack';
import Tabs from '../../../components/tabs';
import { ITab } from '../../../components/tabs/types';
import { deviceTabs } from './config';
import { EDeviceTabsIds } from './types';
import Loader from '../../../components/ui/loader';
import { ELoaderColor } from '../../../components/ui/loader/types';
import { IProfilePermission } from '../../../typings/profile';
import {
  EDispatcherDeviceStatus,
  IDispatcherPanelDeviceInfo,
  fromStatusToName,
} from '../../../typings/dispatcherPanel';
import InformationBlock from '../../../components/ui/informationBlock';
import { dateFormatNoTime } from '../../../constants/date';
import EditIcon from '../../../assets/svg/icons/edit';
import ContactsModal from '../../../components/deviceTabs/baseTab/contactsModal';
import Button from '../../../components/ui/button';
import { ButtonSize, ButtonType } from '../../../components/ui/button/types';
import ChevronDown from '../../../assets/svg/icons/chevronDown';
import { EPageQueryParams, IQueryParamsObject } from '../../../typings/searchParams';
import DisablingPush from '../../../components/dispatcherPanel/disablingPush';
import { getFullPath } from '../../../functions';

const DevicePage: FC = () => {
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useAppDispatch();

  const isAdmin = useAppSelector(checkIsAdmin);

  const [activeTab, setActiveTab] = useState<ITab>(
    deviceTabs.find((tab) => tab.id === searchParams.get(EPageQueryParams.tabId)) || deviceTabs[0]
  );

  const [showInfo, setShowInfo] = useState(true);

  const [isWasChange, setChange] = useState<boolean>(false);
  const [clickedTab, setClickerTab] = useState<ITab | null>(null);

  const [contactsModalIsOpen, setContactsModalOpen] = useState(false);

  const permissions = useAppSelector(getProfilePermission(ESidebarItemIds.dispatcherPanel)) as IProfilePermission;

  const {
    data: deviceData,
    sendRequest: getDeviceData,
    loading: deviceDataLoading,
  } = useApi<IDispatcherPanelDeviceInfo | null>(getRequest);

  const [queryParams, setQueryParams] = useState<IQueryParamsObject>({ prevPage: paths.dispatcherPanel });

  const getData = useCallback(async () => {
    await getDeviceData(dispatcherPanelBaseInfoUrl(params.deviceId || ''));
  }, [getDeviceData, params.deviceId]);

  useEffect(() => {
    const prev = searchParams.get(EPageQueryParams.prevPage);
    if (prev) {
      setQueryParams({
        prevPage: prev || '',
        prevPageId: searchParams.get(EPageQueryParams.prevPageId) || '',
      });
    }
  }, []);

  useEffect(() => {
    getData();
    if (!searchParams.get(EPageQueryParams.tabId)) {
      searchParams.set(EPageQueryParams.tabId, EDeviceTabsIds.basic);
      setSearchParams(searchParams);
    }
  }, [params.deviceId]);

  useEffect(() => {
    if (activeTab.id !== searchParams.get(EPageQueryParams.tabId)) {
      setActiveTab(deviceTabs.find((tab) => tab.id === searchParams.get(EPageQueryParams.tabId)) || deviceTabs[0]);
    }
  }, [searchParams.get(EPageQueryParams.tabId)]);

  useEffect(() => {
    if (deviceData) {
      dispatch(setHeaderTitle(deviceData?.deviceName));
    } else {
      dispatch(setHeaderTitle('Неизвестное оборудование'));
    }
  }, [deviceData]);

  const chancelChangeTab = useCallback(() => {
    setClickerTab(null);
  }, []);

  const setTabAndSearch = useCallback(
    (newTab: ITab, searchArr?: { name: string; val: any }[]) => {
      setActiveTab(newTab);
      [...searchParams].forEach((item) => {
        searchParams.set(item[0], '');
        searchParams.delete(item[0]);
      });
      searchParams.set(EPageQueryParams.tabId, newTab.id);
      if (searchArr) {
        searchArr.forEach((item) => searchParams.set(item.name, item.val));
      }
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const repeatChangeTab = useCallback(() => {
    if (clickedTab) {
      setTabAndSearch(clickedTab);
      setChange(false);
      setClickerTab(null);
    }
  }, [setTabAndSearch, clickedTab]);

  const onChangeTab = useCallback(
    (id: string) => {
      const newActiveTab = deviceTabs.find((tab) => tab.id === id) || deviceTabs[0];
      if (isWasChange) {
        setClickerTab(newActiveTab);
      } else {
        setTabAndSearch(newActiveTab);
      }
    },
    [setTabAndSearch, isWasChange]
  );

  const handleOnChangeNextTab = useCallback(() => {
    const newActiveTab = deviceTabs.find((tab) => tab.position === activeTab.position + 1) || deviceTabs[0];
    if (isWasChange) {
      setClickerTab(newActiveTab);
    } else {
      setTabAndSearch(newActiveTab);
    }
  }, [activeTab.position, setTabAndSearch, isWasChange]);

  const onUpdatedContacts = useCallback(() => {
    getData();
    setContactsModalOpen(false);
  }, [getData]);

  const mainInfo = useMemo(
    () => (
      <InformationBlock
        wrapperClassName="flat-base-tab__info"
        data={[
          [
            {
              items: [
                {
                  name: 'Объект (краткое наименование)',
                  value: deviceData?.objectName || '-',
                },
                {
                  name: 'Тип оборудования',
                  value: deviceData?.deviceType || '-',
                },
                {
                  name: 'Серийный номер',
                  value: deviceData?.serialNumber || '-',
                },
                {
                  name: 'Расположение',
                  value: deviceData?.address || '-',
                },
              ],
            },
          ],
          [
            {
              items: [
                {
                  name: 'Последняя версия конфигурации',
                  value: deviceData?.isLastVersion ? 'Да' : 'Нет',
                },
                {
                  name: 'Статус',
                  value: fromStatusToName.get(deviceData?.status as EDispatcherDeviceStatus) || '-',
                },
                {
                  name: 'Дата последнего состояния',
                  value: deviceData?.lastStatusTime ? moment(deviceData?.lastStatusTime).format(dateFormatNoTime) : '-',
                },
                {
                  name: 'Способ связи',
                  value: (
                    <div className="flat-base-tab__contact">
                      <div className="flat-base-tab__contact-label">{deviceData?.communicationMethod || '-'}</div>
                      {permissions?.edit && (
                        <div
                          role="presentation"
                          onClick={() => setContactsModalOpen(true)}
                          className="flat-base-tab__contact-icon"
                        >
                          <EditIcon />
                        </div>
                      )}
                    </div>
                  ),
                },
              ],
            },
          ],
        ]}
      />
    ),
    [deviceData, permissions]
  );

  if (deviceDataLoading) {
    return (
      <div className="device-page__loader-wrapper">
        <Loader color={ELoaderColor.blue} />
      </div>
    );
  }

  return (
    <>
      <CommonHead seo={{ title: deviceData?.deviceName }} />
      <ContactsModal
        isOpen={contactsModalIsOpen}
        onCancel={() => setContactsModalOpen(false)}
        deviceId={params.deviceId}
        onOk={onUpdatedContacts}
      />
      <div className="device-page">
        {isAdmin ? (
          <div className="device-page__buttons">
            <ButtonLink
              url={getFullPath(queryParams)}
              leftIcon={<ArrowBackIcon />}
              content={queryParams.prevPage?.includes('plan') ? 'План объекта' : 'Панель диспетчера'}
            />
          </div>
        ) : (
          <DisablingPush
            permissions={permissions}
            className="device-page__buttons"
            deviceId={params.deviceId}
            objectId={deviceData?.objectId}
          >
            <ButtonLink
              url={getFullPath(queryParams)}
              leftIcon={<ArrowBackIcon />}
              content={queryParams.prevPage?.includes('plan') ? 'План объекта' : 'Панель диспетчера'}
            />
          </DisablingPush>
        )}

        <div className="device-page__content">
          {deviceDataLoading && (
            <div className="device-page__loader-wrapper">
              <Loader color={ELoaderColor.blue} />
            </div>
          )}
          {deviceData && (
            <Tabs
              activeTabKey={activeTab.id}
              onChangeActiveTab={onChangeTab}
              tabBarExtraContent={
                <Button
                  className={classNames('device-page__info-button', { 'device-page__info-button_close': !showInfo })}
                  size={ButtonSize.small}
                  type={ButtonType.secondary}
                  onClick={() => setShowInfo(!showInfo)}
                  rightIcon={<ChevronDown />}
                >
                  Инфо
                </Button>
              }
              tabs={deviceTabs.map((tab) => ({
                ...tab,
                children: (
                  <tab.component
                    showInfo={showInfo}
                    mainInfo={mainInfo}
                    key={tab.id}
                    isWasChange={isWasChange}
                    setChange={setChange}
                    deviceId={params.deviceId}
                    device={deviceData}
                    onNextTab={handleOnChangeNextTab}
                    onChangeTab={onChangeTab}
                    tabId={tab.id}
                    activeTabKey={activeTab.id}
                    repeatChangeTab={repeatChangeTab}
                    chancelChangeTab={chancelChangeTab}
                    clickedTab={clickedTab?.id}
                    permissions={permissions}
                    isAdmin={isAdmin}
                  />
                ),
              }))}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default DevicePage;
