import React, { FC, useCallback, useEffect, useState } from 'react';
import { getFlats } from '../../../../api/flats';
import { checkSecurityKeysInObject, generateSecurityKeysInObject } from '../../../../api/objectKeys';
import { objectKeysInitApiSettings } from '../../../../api/objectKeys/config';
import {
  checkObjectSecurityKeysUrl,
  generateObjectSecurityKeysUrl,
  getObjectKeysInBuildingSectionUrl,
  getObjectKeysInBuildingUrl,
} from '../../../../constants/api';
import { selectAllOptionKey } from '../../../../constants/select';
import { useApi } from '../../../../hooks/useApi';
import { IApiResponse, IApiSettings, IApiSortField } from '../../../../typings/api';
import { IObjectKeysFlat } from '../../../../typings/systems/objectKeys';
import SectionFlatsTools from '../../../ui/sectionFlatsTools';
import { ISelectOption } from '../../../ui/select/types';
import ObjectKeysTable from '../objectKeysTable';
import { ISectionObjectKeys } from './types';
import FiltersTool from '../../../ui/filtersTool';
import { EFilterTool } from '../../../../typings/filtersTool';

const SectionObjectKeys: FC<ISectionObjectKeys> = (props) => {
  const {
    systemId = '',
    system,
    buildingId = '',
    tabId = '',
    activeTabKey = '',
    sections = [],
    updateAvailableTabs = () => {},
    permissions = {},
  } = props;

  const [selectedSectionId, setSelectedSectionId] = useState<string | number>(selectAllOptionKey);
  const [searchValue, setSearchValue] = useState<string>('');
  const [apiSettings, setApiSettings] = useState<IApiSettings<IObjectKeysFlat>>(objectKeysInitApiSettings);
  const {
    data: flatsWithObjectKeys,
    sendRequest,
    loading: flatsWithObjectKeysLoading,
  } = useApi<IApiResponse<IObjectKeysFlat>>(getFlats);
  const { data: isValidSecurityKey, sendRequest: sendRequestSecurityKey } = useApi<boolean>(checkSecurityKeysInObject);
  const { sendRequest: sendRequestGenerateSecurityKeys } = useApi(generateSecurityKeysInObject);

  useEffect(() => {
    if (systemId) {
      sendRequestSecurityKey(checkObjectSecurityKeysUrl(systemId));
    }
  }, [systemId]);

  useEffect(() => {
    if (buildingId && tabId === activeTabKey) {
      sendRequest(getObjectKeysInBuildingUrl(buildingId), apiSettings);
      setSelectedSectionId(selectAllOptionKey);
    }
  }, [buildingId, tabId, activeTabKey, sendRequest]);

  const requestFlatsWithObjectKeys = useCallback(
    async (reqSettings: IApiSettings<IObjectKeysFlat>, buildId: string, sectionId = selectAllOptionKey) => {
      if (buildId) {
        if (sectionId === selectAllOptionKey) {
          await sendRequest(getObjectKeysInBuildingUrl(buildId), reqSettings);
        } else {
          await sendRequest(getObjectKeysInBuildingSectionUrl(buildId, sectionId), reqSettings);
        }
      }

      updateAvailableTabs();
    },
    [sendRequest, updateAvailableTabs]
  );

  const reloadFlatsWithObjectKeys = useCallback(async () => {
    await requestFlatsWithObjectKeys(apiSettings, buildingId, selectedSectionId.toString());
  }, [apiSettings, buildingId, requestFlatsWithObjectKeys, selectedSectionId]);

  const reloadSecurityKeyValid = useCallback(async () => {
    if (systemId) {
      await sendRequestSecurityKey(checkObjectSecurityKeysUrl(systemId));
    }
  }, [sendRequestSecurityKey, systemId]);

  const handleOnClickReloadButton = useCallback(async () => {
    await reloadSecurityKeyValid();
    await reloadFlatsWithObjectKeys();
  }, [reloadFlatsWithObjectKeys, reloadSecurityKeyValid]);

  const handleOnGenerateSecurityKeys = useCallback(async () => {
    if (systemId) {
      await sendRequestGenerateSecurityKeys(generateObjectSecurityKeysUrl(systemId));
    }
  }, [sendRequestGenerateSecurityKeys, systemId]);

  const handleOnChangeSection = useCallback(
    async (sectionId: string | number) => {
      setSelectedSectionId(sectionId);
      setSearchValue('');
      const newApiSettings: IApiSettings<IObjectKeysFlat> = {
        ...objectKeysInitApiSettings,
        sortFields: apiSettings.sortFields,
      };
      await requestFlatsWithObjectKeys(newApiSettings, buildingId, sectionId.toString());
      setApiSettings(newApiSettings);
    },
    [apiSettings, buildingId, requestFlatsWithObjectKeys]
  );

  const handleOnSearch = useCallback(
    async (value: string) => {
      setSearchValue(value);
      const newApiSettings: IApiSettings<IObjectKeysFlat> = { ...apiSettings, page: 0, search: value };
      await requestFlatsWithObjectKeys(newApiSettings, buildingId, selectedSectionId?.toString());
      setApiSettings(newApiSettings);
    },
    [apiSettings, buildingId, requestFlatsWithObjectKeys, selectedSectionId]
  );

  const handleOnChangeTablePage = useCallback(
    async (page: number) => {
      const newApiSettings: IApiSettings<IObjectKeysFlat> = { ...apiSettings, page: page - 1 };
      await requestFlatsWithObjectKeys(newApiSettings, buildingId, selectedSectionId?.toString());
      setApiSettings(newApiSettings);
    },
    [apiSettings, buildingId, requestFlatsWithObjectKeys, selectedSectionId]
  );

  const handleOnSort = useCallback(
    async (sortResults: IApiSortField<IObjectKeysFlat>[]) => {
      const newApiSettings: IApiSettings<IObjectKeysFlat> = { ...apiSettings, sortFields: sortResults };
      await requestFlatsWithObjectKeys(newApiSettings, buildingId, selectedSectionId?.toString());
      setApiSettings(newApiSettings);
    },
    [apiSettings, buildingId, requestFlatsWithObjectKeys, selectedSectionId]
  );

  const setValuesFromUrl = useCallback(
    (values: any) => {
      setSelectedSectionId(values.sectionId || selectAllOptionKey);
      setSearchValue(values.search || '');
      const newApiSettings: IApiSettings<IObjectKeysFlat> = {
        ...apiSettings,
        page: 0,
        search: values.search || '',
      };
      requestFlatsWithObjectKeys(newApiSettings, buildingId, values.sectionId || '');
      setApiSettings(newApiSettings);
    },
    [apiSettings, buildingId, requestFlatsWithObjectKeys]
  );

  return (
    <div className="section-call-codes">
      <FiltersTool
        setValuesFromUrl={setValuesFromUrl}
        setValuesWithDelay
        rows={[
          [
            {
              type: EFilterTool.select,
              id: 'sectionId',
              props: {
                title: 'Подъезд',
                onChange: handleOnChangeSection,
                value: selectedSectionId,
                options: [
                  { value: selectAllOptionKey, data: 'Все', title: 'Все' },
                  ...sections.map<ISelectOption>((section) => ({
                    value: section.id,
                    title: section.name,
                  })),
                ],
              },
            },
            {
              type: EFilterTool.search,
              id: 'search',
              props: {
                placeholder: 'Поиск по псведониму, номеру квартиры и ключа',
                value: apiSettings.search,
                onSearch: handleOnSearch,
              },
            },
            {
              type: EFilterTool.button,
              props: {
                onClick: handleOnClickReloadButton,
                children: 'Обновить',
              },
            },
          ],
        ]}
      />
      <ObjectKeysTable
        system={system}
        objectKeys={flatsWithObjectKeys?.items || []}
        loading={flatsWithObjectKeysLoading}
        pageSize={flatsWithObjectKeys?.pageSize}
        total={flatsWithObjectKeys?.totalCount}
        currentPage={flatsWithObjectKeys?.page}
        onChangePage={handleOnChangeTablePage}
        systemId={systemId}
        onUpdateData={handleOnClickReloadButton}
        onSort={handleOnSort}
        sortOrders={apiSettings.sortFields}
        isSearch={!!searchValue}
        permissions={permissions}
        isValidSecurityKey={Boolean(isValidSecurityKey)}
        onRequestGenSecurityKey={handleOnGenerateSecurityKeys}
      />
    </div>
  );
};

export default SectionObjectKeys;
