import React, { FC, useMemo } from 'react';
import moment from 'moment';
import { IFlatDisablingPaymentItem } from './types';
import PeriodDatePicker from '../../../../ui/periodDatePicker';
import { dateFormatNoTime, dateISOFormatNoTimeZone } from '../../../../../constants/date';
import Input from '../../../../ui/input';

const FLatDisablingPaymentItem: FC<IFlatDisablingPaymentItem> = (props) => {
  const { data, index, activePeriodId } = props;

  const isActive = useMemo(() => activePeriodId === data?.id, [activePeriodId, data?.id]);

  return (
    <>
      {!!activePeriodId && index === 1 && (
        <div className="disabling-payment__sub-title">История предыдущих отключений</div>
      )}

      <div className="disabling-payment-item">
        <div className="disabling-payment-item__date-picker">
          <PeriodDatePicker
            showCalendarIcon={false}
            isCanUnlimited
            title="Период действия"
            firstDate={data?.startDate || ''}
            secondDate={moment.utc(data?.endDate).format(dateISOFormatNoTimeZone) || ''}
            format={dateFormatNoTime}
            placeholder="Не ограничен"
            isDisabledStyle
            disabled
            textInfo="Время завершения периода 23:59 UTC"
          />
          <div className="disabling-payment-item__date-picker-status">
            {isActive ? (
              moment().diff(moment(data?.startDate), 'minutes') < 0 ? (
                <div className="disabling-payment-item__date-picker-status_not-active-yet">
                  Дата начала: {moment(data?.startDate || '').format(dateFormatNoTime)}
                </div>
              ) : (
                <div className="disabling-payment-item__date-picker-status_active">Действует</div>
              )
            ) : (
              <div className="disabling-payment-item__date-picker-status_inactive">
                Дата завершения: {moment.utc(data?.endDate).format(dateFormatNoTime) || ''}
              </div>
            )}
          </div>
        </div>
        <div className="disabling-payment-item__tariff">
          <Input title="Тариф" value={data?.tariff} isDisabledStyle disabled />
        </div>
        <div className="disabling-payment-item__comment">
          <Input title="Комментарий" value={data?.description} isDisabledStyle disabled />
          <div
            className={`disabling-payment-item__comment-status disabling-payment-item__comment-status_${
              isActive ? 'active' : 'inactive'
            }`}
          >
            Автор отключения: {data?.creatorEmail}
          </div>
        </div>
      </div>
    </>
  );
};

export default FLatDisablingPaymentItem;
