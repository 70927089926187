export enum ERolesTabsIds {
  basic = 'basic',
  users = 'users',
}

export enum ERolePageQueryParams {
  tabId = 'tabId',
}

export enum EFeedbackTablePageFilters {
  date = 'date',
  categoryId = 'categoryId',
  priority = 'priority',
  status = 'status',
  search = 'search',
  assignedUserId = 'assignedUserId',
}
