import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { EFlatFilters, IFlatPageFilters } from './types';
import { useApi } from '../../../hooks/useApi';
import { getRequest } from '../../../api';
import { IApiResponse } from '../../../typings/api';
import { ISystem } from '../../../typings/systems/system';
import { IFlatsPageBuilding, IFlatsPageFlat, IFlatsPageSection } from '../../../typings/flat';
import {
  companyUrl,
  getBuildingsUrl,
  getObjectSystemIdUrl,
  getObjectSystemsListUrl,
  getSectionsUrl,
} from '../../../constants/api';
import { IApartmentApiParams, IApartmentApiSettings } from '../../../api/apartments/types';
import { ISelectOption } from '../../ui/select/types';
import { EFlatPaymentStatus } from '../../../pages/flats/types';
import { IOrganization } from '../../../typings/organization';
import FiltersTool from '../../ui/filtersTool';
import { EFilterTool } from '../../../typings/filtersTool';

const FlatsPageFilters: FC<IFlatPageFilters> = ({
  setFlatsArr = () => {},
  isAdmin = false,
  requestData = () => {},
  eventsApiParams,
  apiSettings,
  setEventsApiParams = () => {},
}) => {
  const { data: companies, sendRequest: getCompanies, loading: companiesLoading } = useApi<IOrganization[]>(getRequest);
  const { data: objects, sendRequest: getObjects, loading: objectsLoading } = useApi<IApiResponse<ISystem>>(getRequest);
  const {
    data: buildings,
    sendRequest: getBuildings,
    loading: buildingsLoading,
  } = useApi<IFlatsPageBuilding[]>(getRequest);
  const {
    data: sections,
    sendRequest: getSections,
    loading: sectionsLoading,
  } = useApi<IFlatsPageSection[]>(getRequest);

  const [searchParams, setSearchParams] = useSearchParams();

  const [objectsList, setObjectsList] = useState<ISystem[]>([]);
  const [buildingsList, setBuildingsList] = useState<IFlatsPageBuilding[]>([]);
  const [sectionsList, setSectionsList] = useState<IFlatsPageSection[]>([]);

  useEffect(() => {
    setBuildingsList(buildings || []);
  }, [buildings]);

  useEffect(() => {
    setSectionsList(sections || []);
  }, [sections]);

  useEffect(() => {
    if (objects?.items?.length && eventsApiParams && apiSettings) {
      const id = eventsApiParams.objectId || objects.items[0].id || '';
      requestData(apiSettings, { ...eventsApiParams, objectId: id });
      getBuildings(getBuildingsUrl(id));
    } else if (objects?.items?.length === 0) {
      setFlatsArr([]);
      setBuildingsList([]);
      setSectionsList([]);
    }
    setObjectsList(objects?.items || []);
  }, [objects]);

  useEffect(() => {
    if (eventsApiParams?.companyId) {
      getObjects(getObjectSystemIdUrl(eventsApiParams.companyId), { params: { count: 0, isOnlyIpSeries: true } });
    } else if (!isAdmin) {
      getObjects(getObjectSystemsListUrl(), { params: { count: 0, isOnlyIpSeries: true } });
    }
  }, [eventsApiParams?.companyId]);

  useEffect(() => {
    if (isAdmin && companies?.length) {
      setEventsApiParams({
        ...eventsApiParams,
        companyId: companies[0].id,
      });
    }
  }, [companies]);

  useEffect(() => {
    if (isAdmin) {
      getCompanies(companyUrl());
    }
  }, [isAdmin]);

  const handleOnSearch = useCallback(
    async (value: string) => {
      if (apiSettings && eventsApiParams) {
        const newApiSettings: IApartmentApiSettings<IFlatsPageFlat> = { ...apiSettings, search: value, page: 0 };
        requestData(newApiSettings);
      }
    },
    [apiSettings, eventsApiParams, requestData]
  );

  const handleOnChangeSelect = useCallback(
    (key: string) => (val: string | number) => {
      if (apiSettings && eventsApiParams) {
        const newApiSettings: IApartmentApiSettings<IFlatsPageFlat> = { ...apiSettings, page: 0 };
        const newEventsApiParams: IApartmentApiParams = { ...eventsApiParams, [key]: val };
        requestData(newApiSettings, newEventsApiParams);
      }
    },
    [apiSettings, eventsApiParams, requestData]
  );

  const handleOnChangeSelects = useCallback(
    (newEventsApiParams: IApartmentApiParams) => {
      if (apiSettings) {
        const newApiSettings: IApartmentApiSettings<IFlatsPageFlat> = { ...apiSettings, page: 0 };
        requestData(newApiSettings, newEventsApiParams);
      }
    },
    [apiSettings, requestData]
  );

  const handleOnChangeOrganization = useCallback(
    (val: string | number) => {
      if (eventsApiParams) {
        handleOnChangeSelects({
          ...eventsApiParams,
          companyId: val?.toString(),
          objectId: '',
          buildingId: '',
          sectionId: '',
        });
      }
      searchParams.delete(EFlatFilters.objectId);
      searchParams.delete(EFlatFilters.buildingId);
      searchParams.delete(EFlatFilters.sectionId);
      searchParams.set(EFlatFilters.companyId, val?.toString());
      setSearchParams(searchParams);
      setFlatsArr([]);
      setBuildingsList([]);
      setSectionsList([]);
    },
    [eventsApiParams, handleOnChangeSelects, searchParams, setFlatsArr, setSearchParams]
  );

  const handleOnChangeObject = useCallback(
    async (val: string | number) => {
      if (eventsApiParams) {
        handleOnChangeSelects({
          ...eventsApiParams,
          objectId: val?.toString(),
          buildingId: '',
          sectionId: '',
        });
      }
      setSectionsList([]);
      await getBuildings(getBuildingsUrl(val?.toString()));
      searchParams.set(EFlatFilters.objectId, val?.toString());
      searchParams.delete(EFlatFilters.buildingId);
      searchParams.delete(EFlatFilters.sectionId);
      setSearchParams(searchParams);
    },
    [eventsApiParams, getBuildings, handleOnChangeSelects, searchParams, setSearchParams]
  );

  const handleOnChangeBuilding = useCallback(
    (val: string | number) => {
      if (eventsApiParams) {
        handleOnChangeSelects({
          ...eventsApiParams,
          buildingId: val?.toString(),
          sectionId: '',
        });
      }
      searchParams.delete(EFlatFilters.sectionId);
      setSearchParams(searchParams);
      if (eventsApiParams?.objectId && val) {
        getSections(getSectionsUrl(eventsApiParams.objectId, val?.toString()));
      } else {
        setSectionsList([]);
      }
    },
    [eventsApiParams, getSections, handleOnChangeSelects, searchParams, setSearchParams]
  );

  const setValuesFromUrl = useCallback(
    async (values: any) => {
      if (apiSettings && eventsApiParams) {
        const newApiSettings: IApartmentApiSettings<IFlatsPageFlat> = {
          ...apiSettings,
          page: 0,
          search: values[EFlatFilters.search],
        };
        const newEventsApiParams: IApartmentApiParams = {
          ...eventsApiParams,
          objectId: values[EFlatFilters.objectId] || '',
          buildingId: values[EFlatFilters.buildingId] || '',
          companyId: values[EFlatFilters.companyId] || '',
          payStatus: values[EFlatFilters.payStatus] || EFlatPaymentStatus.all,
          sectionId: values[EFlatFilters.sectionId] || '',
        };
        await requestData(newApiSettings, newEventsApiParams);
        getSections(getSectionsUrl(values[EFlatFilters.objectId], values[EFlatFilters.buildingId]));
      }
    },
    [apiSettings, eventsApiParams, requestData]
  );

  const organizationOptions = useMemo(
    () =>
      isAdmin
        ? companies?.map<ISelectOption>((object) => ({
            value: object.id || '',
            title: object.companyName || '',
          }))
        : [],
    [companies, isAdmin]
  );

  const objectOptions = useMemo(
    () =>
      objectsList.map<ISelectOption>((object) => ({
        value: object.id || '',
        title: object.objectName || '',
      })) || [],
    [objectsList]
  );

  const buildingOptions = useMemo(
    () =>
      buildingsList?.map<ISelectOption>((object) => ({
        value: object.id || '',
        title: object.name || '',
      })) || [],
    [buildingsList]
  );

  const sectionOptions = useMemo(
    () =>
      sectionsList?.map<ISelectOption>((object) => ({
        value: object.id || '',
        title: object.name || '',
      })) || [],
    [sectionsList]
  );

  const payStatusOptions: ISelectOption[] = useMemo(
    () => [
      {
        value: EFlatPaymentStatus.all,
        title: 'Все',
      },
      {
        value: EFlatPaymentStatus.enabled,
        title: 'Включена',
      },
      {
        value: EFlatPaymentStatus.disabled,
        title: 'Отключена',
      },
    ],
    []
  );

  const getSection = useCallback(
    (hidden: boolean) => ({
      type: EFilterTool.select,
      id: EFlatFilters.sectionId,
      hidden,
      props: {
        isAllOption: true,
        title: 'Подъезд',
        value: eventsApiParams?.sectionId || '',
        onChange: handleOnChangeSelect('sectionId'),
        options: sectionOptions,
        loading: sectionsLoading,
      },
    }),
    [eventsApiParams?.sectionId, handleOnChangeSelect, sectionOptions, sectionsLoading]
  );

  return (
    <FiltersTool
      setValuesFromUrl={setValuesFromUrl}
      wrapperClassName="flats-filters"
      rows={[
        [
          {
            hidden: !isAdmin,
            type: EFilterTool.select,
            id: EFlatFilters.companyId,
            props: {
              title: 'Организация',
              value: eventsApiParams?.companyId || '',
              onChange: handleOnChangeOrganization,
              options: organizationOptions,
              loading: companiesLoading,
            },
          },
          {
            type: EFilterTool.select,
            id: EFlatFilters.objectId,
            props: {
              title: 'Объект (краткое наименование)',
              value: eventsApiParams?.objectId || '',
              onChange: handleOnChangeObject,
              options: objectOptions,
              loading: objectsLoading,
            },
          },
          {
            type: EFilterTool.select,
            id: EFlatFilters.buildingId,
            props: {
              isAllOption: true,
              title: 'Адрес строения',
              value: eventsApiParams?.buildingId || '',
              onChange: handleOnChangeBuilding,
              options: buildingOptions,
              loading: buildingsLoading,
            },
          },
          getSection(isAdmin),
        ],
        [
          getSection(!isAdmin),
          {
            type: EFilterTool.select,
            id: EFlatFilters.payStatus,
            props: {
              title: 'Оплата МП',
              value: eventsApiParams?.payStatus || '',
              onChange: handleOnChangeSelect('payStatus'),
              options: payStatusOptions,
            },
          },
          {
            type: EFilterTool.search,
            id: EFlatFilters.search,
            props: {
              placeholder: 'Поиск по номеру квартиры',
              value: apiSettings?.search,
              onSearch: handleOnSearch,
            },
          },
        ],
      ]}
    />
  );
};

export default FlatsPageFilters;
