export enum EDispatcherPanelTabs {
  table = 'table',
  map = 'map',
}

export enum EDispatcherPanelFilters {
  objectId = 'objectId',
  type = 'type',
  statuses = 'statuses',
  search = 'search',
  companyId = 'companyId',
}
