import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { createSearchParams } from 'react-router-dom';
import { IFlatTabComponent } from '../types';
import InformationBlock from '../../ui/informationBlock';
import { IFlatPayment, IFlatsPageFlat } from '../../../typings/flat';
import { useApi } from '../../../hooks/useApi';
import { getRequest } from '../../../api';
import { subscriptionFlatUrl } from '../../../constants/api';
import { dateFormatNoTime } from '../../../constants/date';
import { paths } from '../../../constants/paths';
import Checkbox from '../../ui/checkbox';
import { EPageQueryParams } from '../../../typings/searchParams';
import FLatDisablingPayment from './disablingPayment';
import { useAppSelector } from '../../../hooks/hooks';
import { getProfilePermission } from '../../../store/selectors/profile';
import { ESidebarItemIds } from '../../../typings/sidebar';

const FlatBaseTab: FC<IFlatTabComponent> = (props) => {
  const { flat, flatId = '', tabId, activeTabKey, permissions, isFiveThousandth } = props;

  const { data: subscriptionData, sendRequest: getSubscriptionData } = useApi<IFlatPayment | null>(getRequest);

  const navigate = useNavigate();

  useEffect(() => {
    if (tabId === activeTabKey) {
      getSubscriptionData(subscriptionFlatUrl(flatId));
    }
  }, [tabId, activeTabKey]);

  const paymentsPermissions = useAppSelector(getProfilePermission(ESidebarItemIds.payments));

  const goToSubscription = useCallback(() => {
    navigate({
      pathname: `${paths.mpManagementSubscriptions}/${subscriptionData?.subscriptionId}`,
      search: `?${createSearchParams({
        [EPageQueryParams.prevPage]: paths.flats || '',
        [EPageQueryParams.prevPageId]: flatId || '',
        [EPageQueryParams.prevTabId]: tabId || '',
      })}`,
    });
  }, [flatId, navigate, subscriptionData?.subscriptionId, tabId]);

  const columns = useMemo<ColumnsType<IFlatsPageFlat>>(
    () =>
      [
        {
          title: '№ кв',
          dataIndex: 'number',
          key: 'number',
          render: (_: any, record: IFlatsPageFlat) => (
            <div className="editable-table__data-container">{record?.number}</div>
          ),
        },
        {
          title: 'Отзвонка',
          dataIndex: 'IsNotificationCallBack',
          key: 'IsNotificationCallBack',
          render: (_: any, record: IFlatsPageFlat) => (
            <div className="editable-table__data-container">
              <Checkbox checked={record?.isNotificationCallBack} disabled />
            </div>
          ),
        },
        {
          hidden: isFiveThousandth,
          title: 'Прямой вызов',
          dataIndex: 'IsDirectCall',
          key: 'IsDirectCall',
          render: (_: any, record: IFlatsPageFlat) => (
            <div className="editable-table__data-container">
              <Checkbox checked={record?.isDirectCall} disabled />
            </div>
          ),
        },
        {
          hidden: !isFiveThousandth,
          title: 'Видео',
          dataIndex: 'IsVideo',
          key: 'IsVideo',
          render: (_: any, record: IFlatsPageFlat) => (
            <div className="editable-table__data-container">
              <Checkbox checked={record?.isVideo} disabled />
            </div>
          ),
        },
        {
          hidden: !isFiveThousandth,
          title: 'Блок',
          dataIndex: 'isBlocked',
          key: 'isBlocked',
          render: (_: any, record: IFlatsPageFlat) => (
            <div className="editable-table__data-container">
              <Checkbox checked={record?.isBlocked} disabled />
            </div>
          ),
        },
        {
          hidden: !isFiveThousandth,
          title: 'Обратный вызов',
          dataIndex: 'isCallback',
          key: 'isPhysicalCallsAvailable',
          render: (_: any, record: IFlatsPageFlat) => (
            <div className="editable-table__data-container">
              <Checkbox checked={record?.isCallback} disabled />
            </div>
          ),
        },
      ].filter((item) => !item.hidden),
    [isFiveThousandth]
  );

  const subscriberInfo = useMemo(
    () =>
      `${subscriptionData?.tariff?.version} ${
        subscriptionData?.monthInSubscription ? `(${subscriptionData?.monthInSubscription} мес.)` : ''
      }`,
    [subscriptionData?.monthInSubscription, subscriptionData?.tariff?.version]
  );

  return (
    <div className="flat-base-tab">
      <InformationBlock
        wrapperClassName="flat-base-tab__info"
        data={[
          [
            {
              items: [
                {
                  name: 'Объект (краткое наименование)',
                  value: flat?.objectName || '-',
                },
                {
                  name: 'Адрес строения',
                  value: flat?.buildingAddress || '-',
                },
                {
                  name: 'Строение (краткое наименование)',
                  value: flat?.buildingName || '-',
                },
                {
                  name: 'Подъезд',
                  value: flat?.sectionName || '-',
                },
                {
                  name: 'Этаж',
                  value: flat?.floor || '-',
                },
                {
                  name: 'Номер квартиры',
                  value: flat?.number || '-',
                },
                {
                  name: 'КВ подъезда',
                  value: flat?.inBuildingCallCode || '-',
                },
              ],
            },
          ],
          [
            {
              items: [
                {
                  name: 'КВ калитки',
                  value: flat?.outOfBuildingCallCode || '-',
                },
                {
                  name: 'Количество абонентов',
                  value: flat?.subscriberCount || '-',
                },
                {
                  name: 'Главный абонент',
                  value: flat?.mainSubscriber || '-',
                },
                {
                  name: 'Количество ключей',
                  value: flat?.keyCount || '-',
                },
                {
                  name: 'Подписка',
                  value: subscriptionData?.tariff?.version ? (
                    paymentsPermissions?.view ? (
                      <div role="presentation" onClick={goToSubscription} className="flat-base-tab__tariff">
                        {subscriberInfo}
                      </div>
                    ) : (
                      subscriberInfo
                    )
                  ) : (
                    '-'
                  ),
                },
                {
                  name: 'Действует до',
                  value: subscriptionData?.nextPayment
                    ? moment(subscriptionData.nextPayment).format(dateFormatNoTime)
                    : '-',
                },
                {
                  name: 'Осталось дней',
                  value: subscriptionData?.activeDays || '-',
                },
              ],
            },
          ],
        ]}
      />

      {flat && (
        <div className="editable-table">
          <Table
            scroll={{ x: '100vh' }}
            columns={columns}
            dataSource={[flat]}
            showSorterTooltip={false}
            pagination={false}
          />
        </div>
      )}

      {permissions?.view && (
        <FLatDisablingPayment
          subscriptionData={subscriptionData}
          tabId={tabId}
          activeTabKey={activeTabKey}
          flatId={flatId}
          permissions={permissions}
        />
      )}
    </div>
  );
};

export default FlatBaseTab;
