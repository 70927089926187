export enum ESystemTabErrorCodes {
  ObjectDuplicateShortName = 'ObjectDuplicateShortName',
  ObjectDuplicateName = 'ObjectDuplicateName',
  Validation = 'Validation',
  SerialNumberValidation = 'SerialNumberValidation',
  SerialNumberFormatValidation = 'SerialNumberFormatValidation',
  SerialNumber = 'SerialNumber',
  CameraMacDuplicate = 'CameraMacDuplicate',
  CameraAlreadyConnected = 'CameraAlreadyConnected',
  CameraIsConnected = 'CameraIsConnected',
  InvalidSecurityDeskTime = 'InvalidSecurityDeskTime',
  NightAndDayTimeCannotBeEqual = 'NightAndDayTimeCannotBeEqual',
  NetAddressError = 'NetAddressError',
  SyncValidationError = 'SyncValidationError',
  ScCallCode = 'ScCallCode',
}
