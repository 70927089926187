import React, { FC, useCallback, useState } from 'react';
import { editCallCode } from '../../../../api/callCode';
import { ICallCodeEditFlatsData, ICallCodeErrorType } from '../../../../api/callCode/types';
import { editCallCodeFlatsUrl } from '../../../../constants/api';
import { selectAllOptionKey } from '../../../../constants/select';
import { useApi } from '../../../../hooks/useApi';
import Button from '../../../ui/button';
import { ButtonSize, ButtonType } from '../../../ui/button/types';
import Checkbox from '../../../ui/checkbox';
import AlertBlock from '../../../ui/alertBlock';
import Input from '../../../ui/input';
import { IInputValue, InputStatus, InputType } from '../../../ui/input/types';
import Modal from '../../../ui/modal';
import RadioGroup from '../../../ui/radioGroup';
import Select from '../../../ui/select';
import { ISelectOption } from '../../../ui/select/types';
import { CallCodeFillingType, CallCodesAction, CallCodeType, IAddCallCodesModal } from './types';
import Message from '../../../message';

const AddCallCodesModal: FC<IAddCallCodesModal> = (props) => {
  const {
    systemId = '',
    buildingId = '',
    sections = [],
    isOpen = false,
    onCancel = () => {},
    onOk = () => {},
    isFiveThousandth,
  } = props;

  const { sendRequest } = useApi(editCallCode);
  const [selectedSectionId, setSelectedSectionId] = useState<string | number>(selectAllOptionKey);
  const [callCodeType, setCallCodeType] = useState<CallCodeType>(CallCodeType.inBuilding);
  const [callCodeFillingType, setCallCodeFillingType] = useState<CallCodeFillingType>(
    CallCodeFillingType.flatNumberInCallCode
  );
  const [callCodeAction, setCallCodeAction] = useState<CallCodesAction>(CallCodesAction.rewrite);
  const [isPrefix, setIsPrefix] = useState<boolean>(false);
  const [startFlatNumber, setStartFlatNumber] = useState<IInputValue>({
    value: '',
    status: InputStatus.normal,
    errorText: '',
  });
  const [startCallCode, setStartCallCode] = useState<IInputValue>({
    value: '',
    status: InputStatus.normal,
    errorText: '',
  });
  const [endCallCode, setEndCallCode] = useState<IInputValue>({
    value: '',
    status: InputStatus.normal,
    errorText: '',
  });
  const [errorText, setErrorText] = useState<string>('');

  const handleOnChangeInput = useCallback(
    (setter: React.Dispatch<React.SetStateAction<IInputValue>>, value: string) => {
      setter({
        value,
        status: InputStatus.normal,
        errorText: '',
      });
    },
    []
  );

  const resetAll = useCallback(() => {
    setCallCodeType(CallCodeType.inBuilding);
    setCallCodeFillingType(CallCodeFillingType.flatNumberInCallCode);
    setCallCodeAction(CallCodesAction.rewrite);
    setIsPrefix(false);
    handleOnChangeInput(setStartFlatNumber, '');
    handleOnChangeInput(setStartCallCode, '');
    handleOnChangeInput(setEndCallCode, '');
    setErrorText('');
  }, [handleOnChangeInput]);

  const handleOnClose = useCallback(() => {
    onCancel();
    setSelectedSectionId(selectAllOptionKey);
    resetAll();
  }, [onCancel, resetAll]);

  const validateForm = useCallback(() => {
    if (!startFlatNumber.value) {
      setStartFlatNumber({ ...startFlatNumber, status: InputStatus.error, errorText: 'Поле не может быть пустым' });
      return false;
    }

    if (callCodeFillingType === CallCodeFillingType.callCodeRage) {
      if (!startCallCode.value) {
        setStartCallCode({ ...startCallCode, status: InputStatus.error, errorText: 'Поле не может быть пустым' });
        return false;
      }
      if (!endCallCode.value) {
        setEndCallCode({ ...endCallCode, status: InputStatus.error, errorText: 'Поле не может быть пустым' });
        return false;
      }

      if (isFiveThousandth && callCodeType === CallCodeType.outOfBuilding && isPrefix) {
        if (startCallCode.value.length > 4) {
          setStartCallCode({ ...startCallCode, status: InputStatus.error, errorText: 'Введено больше 4 символов' });
          return false;
        }
        if (endCallCode.value.length > 4) {
          setEndCallCode({ ...endCallCode, status: InputStatus.error, errorText: 'Введено больше 4 символов' });
          return false;
        }
      }
    }

    return true;
  }, [callCodeFillingType, callCodeType, endCallCode, isFiveThousandth, isPrefix, startCallCode, startFlatNumber]);

  const handleOnClickAddButton = useCallback(async () => {
    if (startFlatNumber.value.length > 5) {
      setErrorText('Не удалось сгенерировать коды вызова. Укажите код вызова вручную.');
    } else if (selectedSectionId && validateForm()) {
      const apiConfig: ICallCodeEditFlatsData = {
        ...(selectedSectionId === selectAllOptionKey ? {} : { sectionId: selectedSectionId.toString() }),
        updateInBuildingCode: callCodeType === CallCodeType.inBuilding,
        updateOutOfBuildingCode: callCodeType === CallCodeType.outOfBuilding,
        updateWithPrefix: isFiveThousandth && callCodeType === CallCodeType.outOfBuilding ? isPrefix : false,
        rewriteFlatCallCode: callCodeAction === CallCodesAction.rewrite,
        startFlatNumber: Number(startFlatNumber.value),
        useFlatNumber: callCodeFillingType === CallCodeFillingType.flatNumberInCallCode,
        useCallCodesRange: callCodeFillingType === CallCodeFillingType.callCodeRage,
        startCallCode: startCallCode.value || null,
        endCallCode: endCallCode.value || null,
      };
      const resError = await sendRequest(editCallCodeFlatsUrl(systemId, buildingId), apiConfig);
      if (resError) {
        const errorData = resError.response.data;
        if (errorData.errorCodes === ICallCodeErrorType.flatNotFound) {
          setStartFlatNumber({ ...startFlatNumber, status: InputStatus.error, errorText: errorData.message });
        } else {
          setErrorText(resError.response.data.message);
        }
        return;
      }
      onOk();
      resetAll();
    }
  }, [
    buildingId,
    callCodeAction,
    callCodeFillingType,
    callCodeType,
    endCallCode.value,
    isFiveThousandth,
    isPrefix,
    onOk,
    resetAll,
    selectedSectionId,
    sendRequest,
    startCallCode.value,
    startFlatNumber,
    systemId,
    validateForm,
  ]);

  const handleOnChangeSection = useCallback((sectionId: string | number) => {
    setSelectedSectionId(sectionId);
  }, []);

  const handleOnChangeCallCodeType = useCallback((type: string | number) => {
    setCallCodeType(type.toString() as CallCodeType);
  }, []);

  const handleOnChangeCallCodeFillingType = useCallback((value: string | number) => {
    setCallCodeFillingType(value.toString() as CallCodeFillingType);
  }, []);

  const handleOnChangeCheckboxPrefix = useCallback((value: boolean) => {
    setIsPrefix(value);
  }, []);

  const handleOnChangeCallCodeAction = useCallback((value: string) => {
    setCallCodeAction(value as CallCodesAction);
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      title="Добавить коды вызова"
      width={416}
      onCancel={handleOnClose}
      showCloseIcon
      wrapClassName="add-call-codes-modal__wrapper"
      footer={
        <div className="add-call-codes-modal__button-container">
          <Button type={ButtonType.secondary} size={ButtonSize.small} onClick={handleOnClose}>
            Отмена
          </Button>
          <Button size={ButtonSize.small} onClick={handleOnClickAddButton}>
            Создать
          </Button>
        </div>
      }
    >
      <div className="add-call-codes-modal">
        <Select
          title="Подъезд"
          containerClassName="add-call-codes-modal__field-wrapper"
          onChange={handleOnChangeSection}
          value={selectedSectionId}
          options={[
            { value: selectAllOptionKey, data: 'Все', title: 'Все' },
            ...sections.map<ISelectOption>((section) => ({ value: section.id, title: section.name })),
          ]}
        />
        {isFiveThousandth && (
          <Select
            title="Тип кодов вызова"
            containerClassName="add-call-codes-modal__field-wrapper"
            onChange={handleOnChangeCallCodeType}
            value={callCodeType}
            options={[
              { value: CallCodeType.inBuilding, title: 'Подъезд' },
              { value: CallCodeType.outOfBuilding, title: 'Калитка' },
            ]}
          />
        )}

        {isFiveThousandth && callCodeType === CallCodeType.outOfBuilding && (
          <div className="add-call-codes-modal__field-wrapper">
            <Checkbox
              checked={isPrefix}
              defaultChecked={false}
              label="Учитывать код строения"
              onChange={handleOnChangeCheckboxPrefix}
            />
          </div>
        )}
        <div className="add-call-codes-modal__field-wrapper">
          <Input
            title="Начиная с номера квартиры"
            placeholder="Укажите номер"
            value={startFlatNumber.value}
            onChange={(value) => handleOnChangeInput(setStartFlatNumber, value)}
            status={startFlatNumber.status}
            errorText={startFlatNumber.errorText}
            inputType={InputType.numbers}
          />
        </div>
        <Select
          title="Тип заполнения"
          containerClassName="add-call-codes-modal__field-wrapper"
          onChange={handleOnChangeCallCodeFillingType}
          value={callCodeFillingType}
          options={[
            { value: CallCodeFillingType.flatNumberInCallCode, title: 'Номер квартиры в коде вызова' },
            { value: CallCodeFillingType.callCodeRage, title: 'Указать диапазон кодов вызова' },
          ]}
        />
        {callCodeFillingType === CallCodeFillingType.callCodeRage && (
          <div className="add-call-codes-modal__field-wrapper">
            <span className="add-call-codes-modal__input-title">Диапазон кодов вызова</span>
            <div className="add-call-codes-modal__input-container">
              <Input
                placeholder="начинается с"
                value={startCallCode.value}
                onChange={(value) => handleOnChangeInput(setStartCallCode, value)}
                status={startCallCode.status}
                errorText={startCallCode.errorText}
                inputType={InputType.numbers}
                containerClassName="add-call-codes-modal__input-item"
                inputClassName="add-call-codes-modal__input"
                maxLength={isPrefix ? 4 : 5}
              />
              <Input
                placeholder="по"
                value={endCallCode.value}
                onChange={(value) => handleOnChangeInput(setEndCallCode, value)}
                status={endCallCode.status}
                errorText={endCallCode.errorText}
                inputType={InputType.numbers}
                containerClassName="add-call-codes-modal__input-item"
                inputClassName="add-call-codes-modal__input"
                maxLength={isPrefix ? 4 : 5}
              />
            </div>
          </div>
        )}
        <div className="add-call-codes-modal__field-wrapper">
          <span className="add-call-codes-modal__title">Для заполненных КВ</span>
          <RadioGroup
            value={callCodeAction}
            onChange={handleOnChangeCallCodeAction}
            options={[
              {
                title: 'Перезаписывать',
                value: CallCodesAction.rewrite,
              },
              {
                title: 'Пропускать',
                value: CallCodesAction.skip,
              },
            ]}
            containerClassName="add-call-codes-modal__radio-group-container"
          />
        </div>
        <div className="add-call-codes-modal__field-wrapper add-call-codes-modal__error-wrapper">
          {errorText && <AlertBlock content={errorText} />}
        </div>
      </div>
    </Modal>
  );
};

export default AddCallCodesModal;
