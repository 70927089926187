import { Moment } from 'moment';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import classNames from 'classnames';
import { useSearchParams } from 'react-router-dom';
import CommonHead from '../../components/commonHead';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { setHeaderTitle } from '../../store/slices/header';
import { useApi } from '../../hooks/useApi';
import { companiesObjectsUrl, mailingListUrl, mailingSmsInfoUrl, mailingStatusListUrl } from '../../constants/api';
import { IObject } from '../../typings/payments';
import { IApiResponse, IApiSortField } from '../../typings/api';
import { dateFormatNoTime, dateISOFormatNoTimeZone } from '../../constants/date';
import Button from '../../components/ui/button';
import { getRequest, postRequest } from '../../api';
import { getMailingApiSettings } from '../../api/mailing/config';
import { IMailingApiParams, IMailingApiSettings } from '../../api/mailing/types';
import { EMailingTypes, mapPathByMailingType, IMailingInfo, IMailingStatus, ISmsInfo } from '../../typings/mailing';
import MailingTable from '../../components/mailing/mailingTable';
import { getProfilePermission } from '../../store/selectors/profile';
import { ESidebarItemIds } from '../../typings/sidebar';
import Tabs from '../../components/tabs';
import WarningBlock from '../../components/ui/warningBlock';
import FiltersTool from '../../components/ui/filtersTool';
import { EFilterTool } from '../../typings/filtersTool';
import { EMailingFilters } from './types';

const MailingPage: FC = () => {
  const dispatch = useAppDispatch();

  const { data: smsInfo, sendRequest: getSmsInfo } = useApi<ISmsInfo>(getRequest);
  const { data: objects, sendRequest: getObjects, loading: objectsLoading } = useApi<IObject[]>(getRequest);
  const {
    data: mailingList,
    sendRequest: getMailingList,
    loading: mailingListLoading,
  } = useApi<IApiResponse<IMailingInfo>>(postRequest);
  const {
    data: statusList,
    sendRequest: getStatusList,
    loading: getStatusListLoading,
  } = useApi<IMailingStatus[]>(getRequest);

  const [apiSettings, setApiSettings] = useState<IMailingApiSettings<IMailingInfo>>(
    getMailingApiSettings('createDate')
  );
  const [eventsApiParams, setEventsApiParams] = useState<IMailingApiParams>({ type: EMailingTypes.email });

  const navigate = useNavigate();

  const permissions = useAppSelector(getProfilePermission(ESidebarItemIds.mailing));

  const [searchParams, setSearchParams] = useSearchParams();

  const requestData = useCallback(
    async (
      reqSettings: IMailingApiSettings<IMailingInfo> = apiSettings,
      newApiParams: IMailingApiParams = eventsApiParams
    ) => {
      setApiSettings(reqSettings);
      setEventsApiParams(newApiParams);
      await getMailingList(mailingListUrl(), reqSettings, { params: newApiParams });
      if (newApiParams.type === EMailingTypes.sms) getSmsInfo(mailingSmsInfoUrl());
    },
    [apiSettings, eventsApiParams, getMailingList, getSmsInfo]
  );

  useEffect(() => {
    dispatch(setHeaderTitle('Рассылка'));
    requestData();
    getObjects(companiesObjectsUrl());
    getStatusList(mailingStatusListUrl());
  }, []);

  const handleOnSearch = useCallback(
    async (value: string) => {
      const newApiSettings: IMailingApiSettings<IMailingInfo> = { ...apiSettings, page: 0, search: value };
      await requestData(newApiSettings);
    },
    [apiSettings, requestData]
  );

  const handleOnChangeDate = useCallback(
    async (firstDate?: Moment, secondDate?: Moment) => {
      const newApiSettings: IMailingApiSettings<IMailingInfo> = { ...apiSettings, page: 0 };
      const newEventsParams = {
        ...eventsApiParams,
        startDate: firstDate?.startOf('day').format(dateISOFormatNoTimeZone) || '',
        endDate: secondDate?.endOf('day').format(dateISOFormatNoTimeZone) || '',
      };
      await requestData(newApiSettings, newEventsParams);
    },
    [apiSettings, eventsApiParams, requestData]
  );

  const handleOnChangeTablePage = useCallback(
    async (page: number) => {
      const newApiSettings: IMailingApiSettings<IMailingInfo> = { ...apiSettings, page: page - 1 };
      await requestData(newApiSettings);
    },
    [apiSettings, requestData]
  );

  const handleOnSort = useCallback(
    async (sortResults: IApiSortField<any>[]) => {
      const newApiSettings: IMailingApiSettings<IMailingInfo> = { ...apiSettings, sortFields: sortResults };
      await requestData(newApiSettings);
    },
    [apiSettings, requestData]
  );

  const handleOnChangeSelect = useCallback(
    (key: string) => (val: string | number) => {
      const newApiSettings: IMailingApiSettings<IMailingInfo> = { ...apiSettings, page: 0 };
      const newEventsParams = {
        ...eventsApiParams,
        [key]: val,
      };
      requestData(newApiSettings, newEventsParams);
    },
    [apiSettings, eventsApiParams, requestData]
  );

  const setActiveTab = useCallback(
    (tab: EMailingTypes) => {
      const newEventsParams = {
        ...eventsApiParams,
        type: tab,
      };
      searchParams.set(EFilterTool.tabId, tab);
      setSearchParams(searchParams);
      requestData(apiSettings, newEventsParams);
    },
    [apiSettings, eventsApiParams, requestData, searchParams, setSearchParams]
  );

  const onCreateClick = useCallback(() => {
    navigate(`${mapPathByMailingType.get(eventsApiParams.type as EMailingTypes) || ''}/new`);
  }, [eventsApiParams.type, navigate]);

  const setValuesFromUrl = useCallback(
    (values: any) => {
      const newApiSettings: IMailingApiSettings<IMailingInfo> = {
        ...apiSettings,
        page: 0,
        search: values[EMailingFilters.search],
      };
      const newEventsParams = {
        ...eventsApiParams,
        type: (searchParams.get(EFilterTool.tabId) || EMailingTypes.email) as EMailingTypes,
        startDate: values.dateFrom || '',
        endDate: values.dateTo || '',
        objectId: values[EMailingFilters.objectId] || '',
        status: values[EMailingFilters.status] || '',
      };
      requestData(newApiSettings, newEventsParams);
    },
    [apiSettings, eventsApiParams, requestData, searchParams]
  );

  const objectOptions = useMemo(
    () =>
      objects?.map((item) => ({
        value: item.objectId || '',
        title: item.objectName || '',
      })),
    [objects]
  );

  const statusOptions = useMemo(
    () =>
      statusList?.map((item) => ({
        value: item.type || '',
        title: item.displayName || '',
      })),
    [statusList]
  );

  return (
    <>
      <CommonHead seo={{ title: 'Рассылка' }} />
      <div className="mailing">
        <Tabs
          activeTabKey={eventsApiParams.type}
          onChangeActiveTab={setActiveTab}
          tabBarExtraContent={
            permissions?.create ? (
              <Button disabled={!permissions.create} onClick={onCreateClick}>
                {eventsApiParams.type === EMailingTypes.email
                  ? 'Создать E-mail рассылку'
                  : eventsApiParams.type === EMailingTypes.sms
                  ? 'Создать SMS-рассылку'
                  : 'Создать push-рассылку'}
              </Button>
            ) : null
          }
          tabsClassName="recipients-modal__tabs"
          tabs={[
            {
              id: EMailingTypes.email,
              title: 'E-mail',
              position: 0,
            },
            {
              id: EMailingTypes.sms,
              title: 'SMS',
              position: 1,
            },
            {
              id: EMailingTypes.push,
              title: 'Push-уведомления',
              position: 2,
            },
          ]}
        />
        {eventsApiParams.type === EMailingTypes.sms && smsInfo ? (
          smsInfo?.isSmsMailingEnabled ? (
            <div className="mailing__info">
              {(smsInfo?.remainingSmsCount || 0) <= 0 && (
                <WarningBlock
                  messages={[
                    'Нет доступных для отправки SMS в этом месяце.',
                    'Для возобновления SMS-рассылок обратитесь к администратору Eltis.',
                  ]}
                />
              )}
              <div className="mailing__info-count">
                Доступно{' '}
                <span
                  className={classNames('mailing__info-count-number', {
                    'mailing__info-count-number_error': !smsInfo?.remainingSmsCount,
                  })}
                >
                  {(smsInfo?.remainingSmsCount || 0) <= 0 ? 0 : smsInfo?.remainingSmsCount}
                </span>{' '}
                SMS
              </div>
            </div>
          ) : (
            <div className="mailing__info">
              <WarningBlock
                messages={[
                  'Услуга SMS-рассылок отключена. Для подключения рассылок обратитесь к администратору Eltis.',
                ]}
              />
            </div>
          )
        ) : null}
        <FiltersTool
          setValuesWithDelay
          setValuesFromUrl={setValuesFromUrl}
          rows={[
            [
              {
                type: EFilterTool.select,
                id: EMailingFilters.objectId,
                props: {
                  isAllOption: true,
                  showClear: true,
                  title: 'Объект (краткое наименование)',
                  value: eventsApiParams.objectId || '',
                  onChange: handleOnChangeSelect('objectId'),
                  options: objectOptions,
                  loading: objectsLoading,
                },
              },
              {
                type: EFilterTool.select,
                id: EMailingFilters.status,
                props: {
                  isAllOption: true,
                  showClear: true,
                  title: 'Статус',
                  value: eventsApiParams.status || '',
                  onChange: handleOnChangeSelect('status'),
                  options: statusOptions,
                  loading: getStatusListLoading,
                },
              },
              {
                type: EFilterTool.datePicker,
                id: EMailingFilters.date,
                props: {
                  title: 'Период',
                  placeholder: 'Выберите период',
                  firstDate: eventsApiParams.startDate,
                  secondDate: eventsApiParams.endDate,
                  onChange: handleOnChangeDate,
                  showTime: false,
                  format: dateFormatNoTime,
                },
              },
            ],
            [
              {
                type: EFilterTool.search,
                id: EMailingFilters.search,
                props: {
                  placeholder:
                    eventsApiParams.type === EMailingTypes.email
                      ? 'Поиск по теме'
                      : eventsApiParams.type === EMailingTypes.push
                      ? 'Поиск по заголовку'
                      : 'Поиск по тексту',
                  value: apiSettings?.search || '',
                  onSearch: handleOnSearch,
                },
              },
            ],
          ]}
        />
        <MailingTable
          mailingList={mailingList?.items}
          loading={mailingListLoading}
          pageSize={mailingList?.pageSize}
          total={mailingList?.totalCount}
          currentPage={mailingList?.page}
          onChangePage={handleOnChangeTablePage}
          onSort={handleOnSort}
          sortOrders={apiSettings.sortFields}
          isSearch={!!apiSettings.search}
          activeTab={eventsApiParams.type}
        />
      </div>
    </>
  );
};

export default MailingPage;
