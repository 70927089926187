import { Table, TableProps } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import React, { FC, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { FilterValue, SortOrder, SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface';
import { IFlatsPageTable } from './types';
import Scrollbar from '../../ui/scrollbar';
import Loader from '../../ui/loader';
import { ELoaderColor } from '../../ui/loader/types';
import ErrorPlaceholder from '../../ui/errorPlaceholder';
import SearchFailIcon from '../../../assets/svg/icons/searchFail';
import ChevronLeft from '../../../assets/svg/icons/chevronLeft';
import ChevronRight from '../../../assets/svg/icons/chevronRight';
import { paths } from '../../../constants/paths';
import { EFlatPaymentStatus } from '../../../pages/flats/types';
import { IApiSortField } from '../../../typings/api';
import { IFlatsPageFlat } from '../../../typings/flat';

const FlatsPageTable: FC<IFlatsPageTable> = (props) => {
  const {
    onSort = () => {},
    flats = [],
    loading = false,
    pageSize = 10,
    total = 0,
    onChangePage = () => {},
    sortOrders = [],
    currentPage = 0,
    isSearch = false,
    isAdmin = false,
    eventsApiParams,
  } = props;

  const navigate = useNavigate();

  const onRowClick = useCallback(
    (id: string) => {
      navigate(`${paths.flats}/${id}`);
    },
    [navigate]
  );

  const handleOnChangeTable: TableProps<IFlatsPageFlat>['onChange'] = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<IFlatsPageFlat> | SorterResult<IFlatsPageFlat>[],
      extra: TableCurrentDataSource<IFlatsPageFlat>
    ) => {
      if (extra.action === 'sort') {
        const sortResults: IApiSortField<IFlatsPageFlat>[] = [];

        if (Array.isArray(sorter)) {
          sorter.forEach((item) => {
            const prevIsDescend = sortOrders.find((elem) => elem.field === item.field)?.descending;
            const isDescend = item.order === 'descend';
            if (isDescend !== prevIsDescend) {
              sortResults.push({
                field: item.field as keyof IFlatsPageFlat,
                descending: isDescend,
              });
            }
          });
        } else if (sorter.order) {
          sortResults.push({
            field: sorter.field as keyof IFlatsPageFlat,
            descending: sorter.order === 'descend',
          });
        }
        onSort(sortResults);
      }
    },
    [onSort, sortOrders]
  );

  const getColumnSortOrder = useCallback(
    (flatSort: IApiSortField<IFlatsPageFlat> | null) =>
      flatSort ? (flatSort.descending ? 'descend' : 'ascend') : null,
    []
  );

  const columns = useMemo<ColumnsType<IFlatsPageFlat>>(
    () =>
      [
        {
          hidden: !isAdmin,
          dataIndex: 'organization',
          key: 'organization',
          title: 'Организация',
          render: (_: any, record: IFlatsPageFlat) => (
            <div className="editable-table__data-container">{record?.objectName}</div>
          ),
        },
        {
          title: 'Объект',
          dataIndex: 'object',
          key: 'object',
          render: (_: any, record: IFlatsPageFlat) => (
            <div className="editable-table__data-container">{record?.objectName}</div>
          ),
        },
        {
          title: 'Адрес строения',
          dataIndex: 'buildingAddress',
          key: 'buildingAddress',
          render: (_: any, record: IFlatsPageFlat) => (
            <div className="editable-table__data-container">{record?.buildingAddress}</div>
          ),
        },
        {
          title: 'Подъезд',
          dataIndex: 'sectionName',
          key: 'sectionName',
          render: (_: any, record: IFlatsPageFlat) => (
            <div className="editable-table__data-container">{record?.sectionName}</div>
          ),
        },
        {
          title: 'Номер квартиры',
          dataIndex: 'number',
          key: 'number',
          sorter: {
            multiple: 0,
          },
          sortOrder: getColumnSortOrder(sortOrders.find((item) => item.field === 'number') || null) as SortOrder,
          render: (_: any, record: IFlatsPageFlat) => (
            <div className="editable-table__data-container">{record?.number}</div>
          ),
        },
        {
          title: 'Кол-во абонентов',
          dataIndex: 'subscriberCount',
          key: 'subscriberCount',
          render: (_: any, record: IFlatsPageFlat) => (
            <div className="editable-table__data-container">{record?.subscriberCount}</div>
          ),
        },
        {
          title: 'Кол-во ключей',
          dataIndex: 'keyCount',
          key: 'keyCount',
          render: (_: any, record: IFlatsPageFlat) => (
            <div className="editable-table__data-container">{record?.keyCount || 0}</div>
          ),
        },
        {
          title: 'Оплата МП',
          dataIndex: 'isBlocking',
          key: 'isBlocking',
          render: (_: any, record: IFlatsPageFlat) => (
            <div className="editable-table__data-container">
              {record?.payStatus === EFlatPaymentStatus.enabled ? (
                <div className="flats-page-table__status flats-page-table__status_active">Включена</div>
              ) : (
                <div className="flats-page-table__status flats-page-table__status_inactive">Отключена</div>
              )}
            </div>
          ),
        },
      ].filter((item) => !item.hidden),
    [getColumnSortOrder, isAdmin, sortOrders]
  );

  const data = useMemo(
    () =>
      flats.map((item, index) => ({
        ...item,
        key: index,
      })),
    [flats]
  );

  return (
    <div className="flats-page-table editable-table">
      <Scrollbar>
        <Table
          onChange={handleOnChangeTable}
          scroll={{ x: '100vh' }}
          onRow={(record) => ({
            onClick: () => onRowClick(record.id || ''),
          })}
          columns={columns}
          dataSource={data}
          loading={{
            spinning: loading,
            indicator: <Loader color={ELoaderColor.blue} />,
          }}
          showSorterTooltip={false}
          locale={{
            emptyText: isSearch ? (
              <ErrorPlaceholder text="По вашему запросу ничего не найдено" icon={<SearchFailIcon />} />
            ) : eventsApiParams?.sectionId ? (
              <ErrorPlaceholder
                text='Для выбранного подъезда не созданы квартиры, добавьте их в настройках объекта на вкладке "Распределение квартир"'
                icon={<SearchFailIcon />}
              />
            ) : eventsApiParams?.buildingId ? (
              <ErrorPlaceholder
                text='Для выбранного строения не созданы подъезды, добавьте их в настройках объекта на вкладке "План"'
                icon={<SearchFailIcon />}
              />
            ) : eventsApiParams?.objectId ? (
              <ErrorPlaceholder
                text='Для выбранного объекта не созданы строения, добавьте их в настройках объекта на вкладке "План"'
                icon={<SearchFailIcon />}
              />
            ) : (
              <ErrorPlaceholder text={<span>Ничего не найдено</span>} icon={<SearchFailIcon />} />
            ),
          }}
          pagination={{
            current: currentPage + 1,
            pageSize,
            hideOnSinglePage: true,
            total,
            onChange: onChangePage,
            showSizeChanger: false,
            showQuickJumper: false,
            prevIcon: <ChevronLeft />,
            nextIcon: <ChevronRight />,
            className: 'editable-table__pagination flats-page-table_absolute',
          }}
        />
      </Scrollbar>
    </div>
  );
};

export default FlatsPageTable;
