import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { IDeviceTabComponent } from '../types';
import { useApi } from '../../../hooks/useApi';
import { getRequest } from '../../../api';
import { dispatcherPanelNotificationsListUrl } from '../../../constants/api';
import { IDispatcherPanelNotification } from '../../../typings/dispatcherPanel';
import DeviceNotificationsList from './notificationsList';
import DeviceNotificationsForm from './notificationForm';
import { ISelectOption } from '../../ui/select/types';
import FiltersTool from '../../ui/filtersTool';
import { EFilterTool } from '../../../typings/filtersTool';
import { ENotificationsFilters } from './types';

const DeviceNotificationsTab: FC<IDeviceTabComponent> = (props) => {
  const { deviceId = '', tabId, activeTabKey, mainInfo, permissions, showInfo, isAdmin = false } = props;

  const { data: notifications, sendRequest: getNotifications } = useApi<IDispatcherPanelNotification[]>(getRequest);

  const [eventsApiParams, setEventsApiParams] = useState({
    withNotification: null,
    resolved: null,
    search: '',
  });

  const requestData = useCallback(
    async (newApiParams = eventsApiParams) => {
      setEventsApiParams(newApiParams);
      await getNotifications(dispatcherPanelNotificationsListUrl(deviceId), { params: newApiParams });
    },
    [eventsApiParams, getNotifications, deviceId]
  );

  useEffect(() => {
    if (tabId === activeTabKey) {
      requestData();
    }
  }, [tabId, activeTabKey]);

  const handleOnChangeSelect = useCallback(
    (key: string) => (val: any) => {
      requestData({
        ...eventsApiParams,
        [key]: val,
      });
    },
    [eventsApiParams, requestData]
  );

  const handleOnChangeType = useCallback(
    (val: any) => {
      requestData({
        ...eventsApiParams,
        withNotification: val,
        resolved: null,
      });
    },
    [eventsApiParams, requestData]
  );

  const onSearch = useCallback(
    (search: string) => {
      requestData({
        ...eventsApiParams,
        search,
      });
    },
    [eventsApiParams, requestData]
  );

  const setValuesFromUrl = useCallback(
    (values: any) => {
      requestData({
        ...eventsApiParams,
        search: values[ENotificationsFilters.search],
        resolved: values[ENotificationsFilters.resolved],
        withNotification: values[ENotificationsFilters.withNotification],
      });
    },
    [eventsApiParams, requestData]
  );

  const typeOptions = useMemo(
    (): ISelectOption[] => [
      {
        value: 'true',
        title: 'Напоминание',
      },
      {
        value: 'false',
        title: 'Комментарий',
      },
    ],
    []
  );

  const resolveOptions = useMemo(
    (): ISelectOption[] => [
      {
        value: 'true',
        title: 'Решено',
      },
      {
        value: 'false',
        title: 'Не решено',
      },
    ],
    []
  );

  return (
    <div className="device-notifications-tab">
      {showInfo && mainInfo}
      <FiltersTool
        setValuesWithDelay
        setValuesFromUrl={setValuesFromUrl}
        rows={[
          [
            {
              type: EFilterTool.select,
              id: ENotificationsFilters.withNotification,
              props: {
                isAllOption: true,
                title: 'Тип сообщения',
                value: eventsApiParams?.withNotification || '',
                onChange: handleOnChangeType,
                options: typeOptions,
              },
            },
            {
              type: EFilterTool.select,
              id: ENotificationsFilters.resolved,
              props: {
                isDisabledStyle: true,
                disabled: !(eventsApiParams?.withNotification === 'true'),
                isAllOption: true,
                title: 'Статус напоминания',
                value: eventsApiParams?.resolved || '',
                onChange: handleOnChangeSelect('resolved'),
                options: resolveOptions,
              },
            },
            {
              type: EFilterTool.search,
              id: ENotificationsFilters.search,
              props: {
                containerClassName: 'device-notifications-tab__search',
                placeholder: 'Поиск по тексту сообщения',
                value: eventsApiParams.search,
                onSearch: onSearch,
              },
            },
          ],
        ]}
      />
      <div className="device-notifications-tab__content">
        <DeviceNotificationsList
          isAdmin={isAdmin}
          permissions={permissions}
          notifications={notifications || []}
          deviceId={deviceId}
          updateList={requestData}
        />
        <DeviceNotificationsForm
          isAdmin={isAdmin}
          permissions={permissions}
          deviceId={deviceId}
          onSuccess={requestData}
        />
      </div>
    </div>
  );
};

export default DeviceNotificationsTab;
