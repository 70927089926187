import { Select as SelectComponent } from 'antd';
import classNames from 'classnames';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import ChevronDown from '../../../assets/svg/icons/chevronDown';
import SearchFailIcon from '../../../assets/svg/icons/searchFail';
import { selectAllOptionKey } from '../../../constants/select';
import Checkbox from '../checkbox';
import ErrorPlaceholder from '../errorPlaceholder';
import InputSearch from '../inputSearch';
import Loader from '../loader';
import { ELoaderColor } from '../loader/types';
import Scrollbar from '../scrollbar';
import { ISelectMultiple, ISelectMultipleOption } from './types';
import { InputStatus } from '../input/types';
import CloseIcon from '../../../assets/svg/icons/close';

const { Option } = SelectComponent;

const SelectMultiple: FC<ISelectMultiple> = (props) => {
  const {
    notAllowAllChoice = false,
    isDisabledCheckboxes = false,
    isSelectedAllDefault = true,
    title = '',
    containerClassName = '',
    titleClassName = '',
    selectClassName = '',
    isRequired = false,
    onChange = () => {},
    placeholder = 'Выберите',
    showArrow = true,
    disabled = false,
    status = InputStatus.normal,
    errorText = '',
    errorTextClassName = '',
    loading = false,
    options = [],
    values = [],
    showSearch = true,
    notFoundContent = <ErrorPlaceholder text="Ничего не найдено" icon={<SearchFailIcon />} />,
    allSelectOptionValue = selectAllOptionKey,
    showClear,
  } = props;

  const [searchValue, setSearchValue] = useState<string>('');
  const [isSelectedAll, setIsSelectedAll] = useState<boolean>(
    isSelectedAllDefault && values.includes(allSelectOptionValue)
  );

  const handleOnSearch = useCallback((value: string) => {
    setSearchValue(value);
    setIsSelectedAll(false);
  }, []);

  const handleOnClear = useCallback(() => {
    onChange([]);
  }, [onChange]);

  useEffect(() => {
    setIsSelectedAll(isSelectedAllDefault && values.includes(allSelectOptionValue));
  }, [values]);

  const handleOnClickItemInDropdown = useCallback(
    (option: ISelectMultipleOption) => {
      if (!isDisabledCheckboxes) {
        if (option.value === allSelectOptionValue) {
          if (isSelectedAll) {
            onChange([]);
          } else {
            const allSelectedOptions = [allSelectOptionValue];
            onChange(allSelectedOptions);
          }
          setIsSelectedAll(!isSelectedAll);
        } else {
          let updatedValues: string[] = [];
          if (values.includes(option.value)) {
            updatedValues = values.filter((item) => item !== option.value && item !== allSelectOptionValue);
          } else {
            updatedValues = [...values.filter((item) => item !== allSelectOptionValue), option.value];
          }

          const isSelectedAllValues = options
            .filter((item) => item.value !== allSelectOptionValue)
            .reduce((result, item) => result && updatedValues.includes(item.value), true);
          setIsSelectedAll(isSelectedAllValues);
          onChange(isSelectedAllValues && !notAllowAllChoice ? [allSelectOptionValue] : updatedValues);
        }
      }
    },
    [allSelectOptionValue, isDisabledCheckboxes, isSelectedAll, notAllowAllChoice, onChange, options, values]
  );

  const filteredOptions = useMemo(
    () => options.filter((option) => option.title.toLowerCase()?.includes(searchValue.toLowerCase())),
    [options, searchValue]
  );

  const tagRender = useCallback(
    (tagProps: CustomTagProps) =>
      loading ? (
        <div className="">
          <Loader className="select-multiple__input-loader" color={ELoaderColor.blue} />
        </div>
      ) : (
        <span>{tagProps.label}</span>
      ),
    [loading]
  );

  const dropdownRender = useCallback(
    () => (
      <Scrollbar>
        <div className="select-multiple__dropdown-content">
          {loading ? (
            <div className="select-multiple__loader-container">
              <Loader color={ELoaderColor.blue} />
            </div>
          ) : (
            <>
              {showSearch && (
                <InputSearch
                  inputClassName="select-multiple__search-input"
                  value={searchValue}
                  onSearch={handleOnSearch}
                />
              )}
              {filteredOptions.length > 0
                ? filteredOptions.map((option) =>
                    option.value === allSelectOptionValue ? (
                      <div
                        role="presentation"
                        key="select-multiple-dropdown-key-all-option"
                        className={classNames('select-multiple__option-item-wrapper', {
                          'select-multiple__option-item-wrapper_selected': isSelectedAll,
                        })}
                        onClick={() => handleOnClickItemInDropdown(option)}
                      >
                        <span className="select-multiple__option-item-text">
                          {option.reactNodeTitle || option.title}
                        </span>
                        <Checkbox
                          isDisabledStyle
                          className="select-multiple__option-checkbox"
                          disabled={isDisabledCheckboxes || option.disabled}
                          checked={isSelectedAll}
                        />
                      </div>
                    ) : (
                      <div
                        role="presentation"
                        key={`select-multiple-dropdown-key-${option.value}`}
                        className={classNames('select-multiple__option-item-wrapper', {
                          'select-multiple__option-item-wrapper_selected':
                            values.includes(option.value) && !isSelectedAll,
                        })}
                        onClick={() => handleOnClickItemInDropdown(option)}
                      >
                        <span className="select-multiple__option-item-text">
                          {option.reactNodeTitle || option.title}
                        </span>
                        <Checkbox
                          isDisabledStyle
                          className="select-multiple__option-checkbox"
                          disabled={(!notAllowAllChoice && isSelectedAll) || isDisabledCheckboxes || option.disabled}
                          checked={isSelectedAll || values.includes(option.value)}
                        />
                      </div>
                    )
                  )
                : notFoundContent}
            </>
          )}
        </div>
      </Scrollbar>
    ),
    [
      allSelectOptionValue,
      filteredOptions,
      handleOnClickItemInDropdown,
      handleOnSearch,
      isDisabledCheckboxes,
      isSelectedAll,
      loading,
      notAllowAllChoice,
      notFoundContent,
      searchValue,
      showSearch,
      values,
    ]
  );

  return (
    <div
      className={classNames('select-multiple', containerClassName, {
        'select-multiple__clear-icon': showClear,
      })}
    >
      {title && (
        <span className={classNames('select-multiple__title', titleClassName)}>
          {title}
          {isRequired && <span className="select-multiple__title-required">*</span>}
        </span>
      )}

      {showClear && !!values.length && (
        <div
          className="select-multiple__icon-clear-wrapper close-icon select__icon-clear-wrapper"
          onClick={handleOnClear}
          role="presentation"
        >
          <CloseIcon />
        </div>
      )}
      <SelectComponent
        status={status}
        mode="multiple"
        className={classNames('select-multiple__select', selectClassName)}
        dropdownClassName="select-multiple__dropdown"
        getPopupContainer={(triggerNode: HTMLElement) => triggerNode.parentNode as HTMLElement}
        placeholder={placeholder}
        filterOption={false}
        suffixIcon={<ChevronDown />}
        showArrow={showArrow}
        disabled={disabled}
        dropdownAlign={{ offset: [0, 6] }}
        loading={loading}
        notFoundContent={notFoundContent}
        tagRender={tagRender}
        dropdownRender={dropdownRender}
        showSearch={false}
        menuItemSelectedIcon={null}
        value={values}
      >
        {filteredOptions.map((option, index) => (
          <Option
            key={`select-multiple-option-${index}-${option.value}`}
            value={option.value}
            disabled={option.disabled}
          >
            {option.title}
          </Option>
        ))}
      </SelectComponent>
      {errorText && <span className={classNames('input__error', errorTextClassName)}>{errorText}</span>}
    </div>
  );
};

export default SelectMultiple;
