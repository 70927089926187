export const BASE_URL = process.env.REACT_APP_BASE_URL
  ? `${process.env.REACT_APP_BASE_URL}api/`
  : 'http://localhost:15555/';
export const getProfileUrl = () => `${BASE_URL}me`;
export const getSettingsUrl = () => `${BASE_URL}Settings`;
export const userSettingsUrl = () => `${BASE_URL}userSetting`;

export const getAvailableTabsUrl = (systemId: string) => `${BASE_URL}object/${systemId}/availableTabs`;
export const getObjectBaseUrl = (systemId: string) => `${BASE_URL}object/${systemId}/base`;
export const getObjectPlanUrl = (systemId: string) => `${BASE_URL}object/${systemId}/plan`;
export const getObjectSystemUrl = () => `${BASE_URL}Object`;
export const getObjectSystemsListUrl = () => `${BASE_URL}Object/list`;
export const getObjectSystemIdUrl = (id: string) => `${BASE_URL}Object/list/${id}`;
export const getObjectListUrl = () => `${BASE_URL}object/all`;
export const getObjectTariffInfoUrl = (objectId: string) => `${BASE_URL}object/${objectId}/tariffinfo`;
export const getObjectSeriesUrl = () => `${BASE_URL}object/series`;
export const validateDeleteObjectUrl = (id: string) => `${BASE_URL}object/${id}/availabledelete`;
export const deleteObjectUrl = (id: string) => `${BASE_URL}object/${id}`;

export const getObjectBuildingsWithSectionsUrl = (systemId: string) => `${BASE_URL}object/${systemId}/buildings`;
export const getObjectOneBuildingWithSectionsUrl = (systemId: string, buildingId: string) =>
  `${BASE_URL}object/${systemId}/${buildingId}/buildings`;

export const getObjectBuildingAllFlatsUrl = (buildingId: string) => `${BASE_URL}flat/${buildingId}/list`;
export const getObjectBuildingSectionAllFlatsUrl = (buildingId: string, sectionId: string) =>
  `${BASE_URL}flat/${buildingId}/${sectionId}/list`;
export const objectBuildingSectionEditFlatsUrl = (buildingId: string, sectionId: string) =>
  `${BASE_URL}flat/${buildingId}/${sectionId}/bulk`;
export const addFlatsUrl = (systemId: string, buildingId: string, sectionId: string) =>
  `${BASE_URL}flat/${systemId}/${buildingId}/${sectionId}/bulk`;
export const editFlatUrl = (buildingId: string, sectionId: string) => `${BASE_URL}flat/${buildingId}/${sectionId}`;
export const deleteFlatUrl = (flatId: string) => `${BASE_URL}flat/${flatId}`;
export const deleteFlatsFewUrl = (buildingId: string, sectionId?: string) =>
  `${BASE_URL}Flat/bulk/${buildingId}${sectionId ? `/${sectionId}` : ''}`;
export const getObjectBuildingAllFloorsUrl = (buildingId: string) =>
  `${BASE_URL}flat/building/${buildingId}/floor/list`;
export const flatsCallBlocksUrl = (objectId: string, sectionId?: string) =>
  `${BASE_URL}flat/callblock/${objectId}/${sectionId}`;
export const flatsSkinIndexUrl = (objectId: string) => `${BASE_URL}flat/${objectId}/sinkIndex`;

export const getCallCodesInBuildingUrl = (buildingId: string) => `${BASE_URL}callCode/${buildingId}/list`;
export const getCallCodesInBuildingSectionUrl = (buildingId: string, sectionId: string) =>
  `${BASE_URL}callCode/${buildingId}/${sectionId}/list`;
export const editCallCodeOneFlatUrl = (systemId: string) => `${BASE_URL}callCode/${systemId}/update`;
export const editCallCodeFlatsUrl = (systemId: string, buildingId: string) =>
  `${BASE_URL}callCode/${systemId}/${buildingId}/bulk`;
export const clearCallCodesUrl = (systemId: string, buildingId: string) =>
  `${BASE_URL}callCode/${systemId}/${buildingId}/clear`;
export const gerCallCodesErrorsUrl = (systemId: string) => `${BASE_URL}callCode/${systemId}/validate`;

export const getAccessGroupsTreeUrl = (systemId: string) => `${BASE_URL}accesspointgroup/tree/${systemId}`;
export const getAccessGroupsTableUrl = (itemId: string) => `${BASE_URL}accesspointgroup/table/${itemId}`;
export const addAccessGroupUrl = (itemId: string) => `${BASE_URL}accesspointgroup/${itemId}`;

export const createObjectMockKeyUrl = (systemId: string, flatId: string) =>
  `${BASE_URL}objectKey/${systemId}/${flatId}/generate/mock`;
export const createObjectKeyUrl = (systemId: string, flatId: string) =>
  `${BASE_URL}objectKey/${systemId}/${flatId}/generate`;
export const getObjectKeysInBuildingSectionUrl = (buildingId: string, sectionId: string) =>
  `${BASE_URL}objectKey/${buildingId}/${sectionId}/list`;
export const getObjectKeysInBuildingUrl = (buildingId: string) => `${BASE_URL}objectKey/${buildingId}/list`;
export const getObjectFlatKeyUrl = (buildingId: string, flatId: string) =>
  `${BASE_URL}objectKey/${buildingId}/${flatId}`;
export const updateObjectKeyUrl = (objectKey: string) => `${BASE_URL}objectKey/${objectKey}`;

export const checkObjectSecurityKeysUrl = (objectKey: string) => `${BASE_URL}objectKey/${objectKey}/securityKeys`;
export const generateObjectSecurityKeysUrl = (objectId: string) => `${BASE_URL}objectKey/${objectId}/generateOwnKeys`;

export const getServiceKeysUrl = (objectId: string) => `${BASE_URL}ServiceKey/${objectId}`;
export const generateServiceKeysUrl = (objectId: string) => `${BASE_URL}ServiceKey/${objectId}/generate`;
export const serviceKeyUrl = (objectId: string, keyId: string) => `${BASE_URL}ServiceKey/${objectId}/${keyId}`;
export const serviceKeyAliasUrl = (objectId: string, keyId: string) =>
  `${BASE_URL}ServiceKey/${objectId}/${keyId}/alias`;

export const getSubscribersAllUrl = () => `${BASE_URL}subscriber/all`;
export const getSubscribersInObjectUrl = (systemId: string) => `${BASE_URL}subscriber/all/${systemId}`;
export const getSubscriberByIdUrl = (subscriberId: string) => `${BASE_URL}subscriber/${subscriberId}`;
export const addSubscriberUrl = () => `${BASE_URL}subscriber`;
export const editSubscriberUrl = () => `${BASE_URL}subscriber`;
export const deleteSubscriberUrl = (subscriberId: string) => `${BASE_URL}subscriber/${subscriberId}`;
export const getSubscriberObjectsUrl = () => `${BASE_URL}subscriber/edit/organizationObjects`;
export const getSubscriberBuildingsUrl = () => `${BASE_URL}subscriber/edit/buildings`;
export const getSubscriberFlatsUrl = () => `${BASE_URL}subscriber/edit/flats`;
export const getSubscriberSubscriptionsUrl = (subscriberId: string) =>
  `${BASE_URL}subscriber/${subscriberId}/flatSubscription/list`;
export const subscriberValidateMainFlatDeleteUrl = (flatId: string) =>
  `${BASE_URL}subscriber/validateMainFlatDelete/${flatId}`;
export const subscriberExcelExportUrl = () => `${BASE_URL}subscriber/excel/export`;
export const subscriberExcelImportUrl = () => `${BASE_URL}subscriber/excel/import`;

export const getHardwareSettingsTreeUrl = (systemId: string) => `${BASE_URL}device/settings/${systemId}/tree`;
export const getHardwareTypesUrl = () => `${BASE_URL}device/settings/types`;
export const getHardwareSettingsUrl = (accessPoint: string) => `${BASE_URL}device/settings/${accessPoint}`;
export const getDeviceCategoriesUrl = () => `${BASE_URL}device/settings/categories`;
export const getDeviceRtspServers = () => `${BASE_URL}device/settings/rtspServers`;
export const getDeviceVideoServers = () => `${BASE_URL}VideoServer/all`;
export const getHardwareValidationUrl = (systemId: string) => `${BASE_URL}device/settings/${systemId}/validate`;
export const hardwareCameraUrl = (cameraId: string) => `${BASE_URL}camera/${cameraId}`;
export const hardwareCameraConnectUrl = (cameraId: string) => `${BASE_URL}camera/${cameraId}/connect`;
export const hardwareCameraDisconnectUrl = (cameraId: string) => `${BASE_URL}camera/${cameraId}/disconnect`;
export const autoConfigureDeviceUrl = (systemId: string) => `${BASE_URL}device/settings/${systemId}/auto`;
export const connectDeviceUrl = (objectId: string, deviceId: string) =>
  `${BASE_URL}device/settings/${objectId}/${deviceId}/connect`;
export const disconnectDeviceUrl = (objectId: string, deviceId: string) =>
  `${BASE_URL}device/settings/${objectId}/${deviceId}/disconnect`;
export const sendDeviceSettingsUrl = (objectId: string, deviceId: string) =>
  `${BASE_URL}device/settings/${objectId}/${deviceId}/sync`;
export const devicesListUrl = (systemId: string) => `${BASE_URL}device/settings/${systemId}/devices`;

export const getDeviceFileUrl = (versionRevisionId: string) => `${BASE_URL}device/settings/${versionRevisionId}/file`;
export const getRevisionsListUrl = (typeId: string) => `${BASE_URL}device/settings/${typeId}/revision`;
export const getRevisionVersionsUrl = (typeId: string, revisionId: string) =>
  `${BASE_URL}device/settings/${typeId}/${revisionId}/softwareVersion`;
export const allDeviceUpdateAvailableUrl = (id: string) => `${BASE_URL}device/settings/${id}/allDeviceUpdateAvailable`;
export const deviceUpdateTimeUrl = (id: string) => `${BASE_URL}object/${id}/deviceUpdateTime`;
export const createCertificateUrl = (id: string) => `${BASE_URL}Certificate/${id}/issued `;

export const getSubscriberFlatsWithKeysUrl = (subscriberId: string) =>
  `${BASE_URL}subscriber/accesspoint/flatsWithKeys/${subscriberId}`;
export const getSubscriberFlatEquipmentUrl = (flatId: string, keyId: string) =>
  `${BASE_URL}subscriber/accesspoint/accessPointTree/${flatId}/${keyId}`;
export const editSubscriberFlatEquipmentUrl = (subscriberId: string, flatId: string, keyId: string) =>
  `${BASE_URL}subscriber/accesspoint/accessPointTree/${subscriberId}/${flatId}/${keyId}`;
export const getCheckHiddenAccessPointsUrl = (flatId: string) =>
  `${BASE_URL}subscriber/accesspoint/accessPointTree/${flatId}/hidden`;
export const validateSubscriberFlatEquipmentUrl = (flatId: string, subscriberId: string, keyId: string) =>
  `${BASE_URL}subscriber/accesspoint/validateObjectKey/${flatId}/${subscriberId}/${keyId}`;

export const getSubscriberFlatsWithDeviceUrl = (subscriberId: string) =>
  `${BASE_URL}subscriber/accesspoint/flatswithdevices/${subscriberId}`;
export const getSubscriberDeviceLogActionTypeListUrl = () => `${BASE_URL}subscriber/flatevent/actionTypeList`;
export const getSubscriberDeviceLogUrl = (subscriberFlatId: string) =>
  `${BASE_URL}subscriber/flatevent/${subscriberFlatId}`;

export const startSynchronizationUrl = (systemId: string) => `${BASE_URL}Object/${systemId}/sync`;
export const validateSynchronizationUrl = (systemId: string) => `${BASE_URL}Object/${systemId}/sync/validate`;

export const getDeviceEventSystemUrl = () => `${BASE_URL}deviceevent/objects/list`;
export const getDeviceEventDeviceUrl = (systemId: string) => `${BASE_URL}deviceevent/devices/${systemId}/list`;
export const getDeviceEventAccessPointsUrl = (systemId: string) =>
  `${BASE_URL}deviceevent/accessPoint/${systemId}/list`;
export const getDeviceEventEventUrl = () => `${BASE_URL}deviceevent/events`;

export const getHelpFaqChapters = () => `${BASE_URL}faq`;
export const getHelpFaqChaptersList = () => `${BASE_URL}faq/sections`;
export const getHelpFaqQuestion = (questionId: string) => `${BASE_URL}faq/${questionId}`;
export const createHelpFaqUserQuestion = () => `${BASE_URL}faq/userQuestion`;

export const getHelpInstructionsChapters = () => `${BASE_URL}instruction`;
export const getHelpInstructionsChaptersList = () => `${BASE_URL}instruction/sections`;
export const getHelpInstructionsQuestion = (questionId: string) => `${BASE_URL}instruction/${questionId}`;
export const createHelpInstructionUserQuestion = () => `${BASE_URL}instruction/userQuestion`;
export const getHelpInstructionFile = (questionId: string) => `${BASE_URL}instruction/${questionId}/file`;

export const currentTariffUrl = () => `${BASE_URL}tariff/current`;
export const deferredTariffUrl = () => `${BASE_URL}tariff/deferred`;
export const dependenciesTariffUrl = () => `${BASE_URL}tariff/dependencies`;
export const resetTariffUrl = () => `${BASE_URL}tariff/reset`;
export const tariffCommissionUrl = () => `${BASE_URL}tariff/commission`;
export const tariffSettingsUrl = (systemId: string) => `${BASE_URL}tariff/${systemId}/tariff`;

export const companiesPaymentUrl = () => `${BASE_URL}Payment/companies`;
export const companiesObjectsUrl = () => `${BASE_URL}Payment/objects`;
export const companiesObjectIdUrl = (id: string) => `${BASE_URL}Payment/objects/${id}`;
export const paymentsListUrl = () => `${BASE_URL}payment/payments/list`;
export const paymentUrl = (paymentId: string) => `${BASE_URL}payment/${paymentId}`;
export const subscriptionListPaymentUrl = () => `${BASE_URL}payment/subscription/list`;
export const subscriptionPaymentUrl = (subscriptionId: string) => `${BASE_URL}payment/subscription/${subscriptionId}`;
export const subscriptionFlatUrl = (id: string) => `${BASE_URL}payment/subscription/${id}/active`;

export const companyUrl = () => `${BASE_URL}company/list`;
export const companyContactUpdateUrl = () => `${BASE_URL}company/contact/update`;
export const organizationUrl = () => `${BASE_URL}company/current`;
export const organizationUpdateInfoUrl = () => `${BASE_URL}company/update/request`;
export const organizationUpdateLogoUrl = () => `${BASE_URL}company/logo`;
export const organizationUpdateContactsUrl = (objectId: string) =>
  `${BASE_URL}object/${objectId}/buildingContacts/save`;

export const getKeyWriterInfoUrl = () => `${BASE_URL}KeyWriter/last`;
export const getKeyWriterUrl = () => `${BASE_URL}KeyWriter/last/url`;

export const getCertificateExportUrl = (deviceId: string) => `${BASE_URL}certificate/${deviceId}/export`;
export const getCertificateStatusUrl = (deviceId: string) => `${BASE_URL}certificate/${deviceId}/status`;

export const getSectionsFAQUrl = () => `${BASE_URL}subscriberfaq/sections`;
export const getLevelsFAQUrl = () => `${BASE_URL}subscriberfaq/level`;
export const getQuestionFAQUrl = (id: string) => `${BASE_URL}subscriberfaq/${id}`;
export const getFAQQuestionsListUrl = (id: string) => `${BASE_URL}subscriberfaq/${id}/questions`;
export const modifyFAQSectionUrl = () => `${BASE_URL}subscriberfaq/section`;
export const createFAQQuestionUrl = (id: string) => `${BASE_URL}subscriberfaq/${id}/question`;
export const sortFAQSectionsUrl = () => `${BASE_URL}subscriberfaq/section/order`;
export const sortFAQQuestionsUrl = () => `${BASE_URL}subscriberfaq/question/order`;
export const editFAQQuestionUrl = () => `${BASE_URL}subscriberfaq/question`;
export const deleteFAQQuestionUrl = (id: string) => `${BASE_URL}subscriberfaq/question/${id}`;
export const deleteFAQSectionUrl = (id: string) => `${BASE_URL}subscriberfaq/section/${id}`;

export const getRolesListUrl = () => `${BASE_URL}role/list`;
export const getEmptyRoleUrl = () => `${BASE_URL}role/empty`;
export const defaultRolesUrl = () => `${BASE_URL}role/defaultRoles`;
export const createRoleUrl = () => `${BASE_URL}role/create`;
export const getRoleUrl = (id: string) => `${BASE_URL}role/${id}/full`;
export const deleteRoleUrl = (id: string) => `${BASE_URL}role/${id}`;
export const updateRoleUrl = () => `${BASE_URL}role/update`;
export const roleUsersListUrl = (id: string) => `${BASE_URL}role/${id}/users`;
export const roleUsersAvailableListUrl = (id: string) => `${BASE_URL}role/${id}/users/available`;
export const roleUserUrl = (roleId: string, userId: string) => `${BASE_URL}role/${roleId}/users/${userId}`;
export const roleExportUrl = () => `${BASE_URL}role/export`;

export const getUsersListUrl = () => `${BASE_URL}user/list`;
export const getUsersObjects = () => `${BASE_URL}user/objects`;
export const getUserUrl = (id: string) => `${BASE_URL}user/${id}`;
export const updateUserUrl = () => `${BASE_URL}user`;
export const exportUserUrl = () => `${BASE_URL}user/export`;
export const resetPasswordUrl = () => `${BASE_URL}user/resetPassword`;

export const getFlatsUrl = (objectId: string) => `${BASE_URL}flatInfo/${objectId}/list`;
export const getBuildingsUrl = (objectId: string) => `${BASE_URL}flatInfo/${objectId}/buildings`;
export const getSectionsUrl = (objectId: string, buildingId: string) =>
  `${BASE_URL}flatInfo/${objectId}/${buildingId}/sections`;
export const flatUrl = (flatId: string) => `${BASE_URL}flatInfo/${flatId}`;
export const getFlatsSubscribersUrl = (flatId: string) => `${BASE_URL}flatInfo/${flatId}/subscribers/list`;
export const apartmentSubscriberUrl = (flatId: string, subscriberId: string) =>
  `${BASE_URL}flatInfo/${flatId}/${subscriberId}`;
export const getFlatKeysUrl = (flatId: string) => `${BASE_URL}flatInfo/${flatId}/keys`;
export const getFlatEventsUrl = (flatId: string) => `${BASE_URL}flatInfo/${flatId}/events`;
export const getFlatDevicesUrl = (flatId: string) => `${BASE_URL}flatinfo/devices/${flatId}`;
export const getFlatPaymentDisableListUrl = (flatId: string) => `${BASE_URL}PaymentDisable/${flatId}/list`;
export const setFlatPaymentDisableUrl = (flatId: string) => `${BASE_URL}PaymentDisable/${flatId}`;
export const endFlatDisableUrl = (flatId: string, id: string) => `${BASE_URL}PaymentDisable/${flatId}/${id}`;
export const editFlatKeyUrl = (flatId: string, keyId: string, subscriberId?: string) =>
  `${BASE_URL}flatInfo/updateKey/${flatId}/${keyId}${subscriberId ? `/${subscriberId}` : ''}`;
export const validateFlatKeyUrl = (flatId: string, keyId: string, subscriberId?: string) =>
  `${BASE_URL}flatInfo/subscriber/validateObjectKey/${flatId}/${keyId}${subscriberId ? `/${subscriberId}` : ''}`;

export const dispatcherPanelObjectTreeUrl = () => `${BASE_URL}DispatcherPanel/tree`;
export const dispatcherPanelDevicesListUrl = () => `${BASE_URL}DispatcherPanel/list`;
export const dispatcherPanelBaseInfoUrl = (deviceId: string) => `${BASE_URL}DispatcherPanel/${deviceId}/base`;
export const dispatcherPanelEventsUrl = (deviceId: string) => `${BASE_URL}DispatcherPanel/${deviceId}/events`;
export const dispatcherPanelEventUrl = (deviceId: string, eventId: string) =>
  `${BASE_URL}DispatcherPanel/${deviceId}/events/${eventId}`;
export const dispatcherPanelNotificationsListUrl = (deviceId: string) =>
  `${BASE_URL}DispatcherPanel/${deviceId}/notification/list`;
export const dispatcherPanelNotificationUrl = (deviceId: string) =>
  `${BASE_URL}DispatcherPanel/${deviceId}/notification`;
export const delDispatcherPanelNotificationUrl = (deviceId: string, notifyId: string) =>
  `${BASE_URL}DispatcherPanel/${deviceId}/notification/${notifyId}`;
export const dispatcherPanelCommunicationMethodUrl = (deviceId: string) =>
  `${BASE_URL}DispatcherPanel/${deviceId}/communicationMethod`;
export const dispatcherPanelErrorsCountUrl = () => `${BASE_URL}DispatcherPanel/count`;
export const dispatcherPanelDisableNotificationsUrl = () => `${BASE_URL}DispatcherPanel/notification/disable/status`;
export const dispatcherPanelDevicesUrl = () => `${BASE_URL}DispatcherPanel/devices`;

export const notifyConnectionIdUrl = () => `${BASE_URL}hub/notification/negotiate?negotiateVersion=1`;
export const notifyWebSocketUrl = (url: string) => `wss://${url}api/hub/notification`;
export const notifyDisabledUrl = () => `${BASE_URL}DispatcherPanel/notification/disable`;
export const notifyDisabledDeleteUrl = (id: string) => `${BASE_URL}DispatcherPanel/notification/disable/${id}`;

export const getFeedbacksListUrl = () => `${BASE_URL}feedback/list`;
export const getFeedbacksCategoryListUrl = () => `${BASE_URL}feedbackcategory/category/list`;
export const feedbackUrl = () => `${BASE_URL}feedback`;
export const feedbackDataUrl = (id: string) => `${BASE_URL}feedback/${id}/full`;
export const feedbackMediaUrl = (id: string) => `${BASE_URL}feedback/${id}/media`;
export const feedbackPriorityUrl = (id: string) => `${BASE_URL}feedback/${id}/priority`;
export const feedbackCategoryUrl = (id: string) => `${BASE_URL}feedback/${id}/category`;
export const feedbackStatusUrl = (id: string) => `${BASE_URL}feedback/${id}/status`;
export const feedbackAssignToMeUrl = (id: string) => `${BASE_URL}feedback/${id}/assignToMe`;
export const downloadFeedbackFileUrl = (id: string, partId: string) => `${BASE_URL}feedback/${id}/${partId}/file`;
export const feedbackHistoryUrl = (id: string) => `${BASE_URL}feedback/${id}/history`;
export const feedbackHistoryCommentUrl = (id: string) => `${BASE_URL}feedback/${id}/comment`;
export const feedbackHistoryMediaUrl = (id: string) => `${BASE_URL}feedback/${id}/history/media`;

export const feedbackSubjectsListUrl = (categoryId: string) => `${BASE_URL}feedbackcategory/${categoryId}/subject/list`;
export const feedbackCategoryBaseUrl = () => `${BASE_URL}feedbackcategory`;
export const feedbackCategorySubjectBaseUrl = () => `${BASE_URL}feedbackcategory/subject`;
export const feedbackCategoryBaseIdUrl = (id: string) => `${BASE_URL}feedbackcategory/${id}`;
export const feedbackCategorySubjectBaseIdUrl = (id: string) => `${BASE_URL}feedbackcategory/subject/${id}`;
export const feedbackCategorySortUrl = () => `${BASE_URL}feedbackcategory/category/order`;
export const feedbackCategorySubjectSortUrl = () => `${BASE_URL}feedbackcategory/subject/order`;

export const daDataAddressUrl = () => `${BASE_URL}dadata/address`;

export const mailingObjectsUrl = () => `${BASE_URL}mailing/objects`;
export const mailingRecipientsUrl = () => `${BASE_URL}mailing/recipients`;
export const mailingStatusListUrl = () => `${BASE_URL}mailing/status/list`;
export const mailingRecipientsFillUrl = (id: string) => `${BASE_URL}mailing/${id}/recipients/fill`;
export const mailingListUrl = () => `${BASE_URL}mailing/list`;
export const mailingItemUrl = (id: string) => `${BASE_URL}mailing/${id}`;
export const mailingItemRecipientsUrl = (id: string) => `${BASE_URL}mailing/${id}/recipients/list`;
export const mailingUrl = () => `${BASE_URL}mailing`;
export const mailingTestUrl = () => `${BASE_URL}mailing/test`;
export const mailingDeleteRecipientUrl = (id: string) => `${BASE_URL}mailing/recipients/${id}`;
export const mailingDeleteRecipientsUrl = (id: string, objectId?: string) =>
  `${BASE_URL}mailing/recipients/bulk/${id}${objectId ? `/${objectId}` : ''}`;
export const mailingCancelUrl = (id: string) => `${BASE_URL}mailing/${id}/cancel`;
export const mailingSmsInfoUrl = () => `${BASE_URL}mailing/sms/info`;

export const companyTariffVersionUrl = () => `${BASE_URL}company/tariffVersion`;
export const objectCountRemainingUrl = () => `${BASE_URL}object/countRemaining`;
export const flatCountRemainingUrl = () => `${BASE_URL}flat/countRemaining`;
export const userCountRemainingUrl = () => `${BASE_URL}user/countRemaining`;

export const securityDeckFlatUrl = (buildingId: string, sectionId: string) =>
  `${BASE_URL}SecurityDeckFlat/${buildingId}/${sectionId}`;
export const securityDeckFlatListUrl = (buildingId: string, sectionId: string) =>
  `${BASE_URL}SecurityDeckFlat/${buildingId}/${sectionId}/list`;
export const securityDeckFlatListAllUrl = (buildingId: string) => `${BASE_URL}SecurityDeckFlat/${buildingId}/list`;
export const securityDeckFlatUpdateUrl = (buildingId: string, sectionId: string) =>
  `${BASE_URL}SecurityDeckFlat/${buildingId}/${sectionId}/bulk`;
