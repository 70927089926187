import React, { FC, useCallback, useEffect, useState } from 'react';
import { IDeviceSyncModal } from './types';
import UniversalModal from '../../ui/universalModal';
import { IConfirmData } from '../../ui/universalModal/types';
import { defaultConfirm } from '../../ui/universalModal/config';
import Loader from '../../ui/loader';
import { ButtonType } from '../../ui/button/types';
import { ELoaderColor } from '../../ui/loader/types';
import CheckedIcon from '../../../assets/svg/icons/checkedIcon';
import CloseCircleIcon from '../../../assets/svg/icons/closeCIrcle';

const DeviceSyncModal: FC<IDeviceSyncModal> = (props) => {
  const { data = null } = props;

  const [confirmData, setConfirmData] = useState<IConfirmData>(defaultConfirm);

  const [wasClose, setWasClose] = useState(false);

  const closeConfirm = useCallback(() => {
    setConfirmData(defaultConfirm);
    setWasClose(true);
  }, []);

  const showConnectModal = useCallback(() => {
    if (data && (wasClose ? data.currentPart === 1 || data.isCompleted || data.isError : true)) {
      setWasClose(false);
      setConfirmData({
        isOpen: true,
        description: 'Взаимодействие с оборудованием',
        text: data.isCompleted ? (
          <div className="device-sync-modal__final">
            <CheckedIcon /> {data.message || 'Успешно'}
          </div>
        ) : data.isError ? (
          <div className="device-sync-modal__final">
            <CloseCircleIcon />
            {data.message || (
              <span>
                Соединение с оборудованием прервано. <br /> Проверьте подключение и попробуйте еще раз
              </span>
            )}
          </div>
        ) : (
          <div className="device-sync-modal__wait">
            <Loader color={ELoaderColor.blue} /> {data.message || 'Пожалуйста, подождите...'}
          </div>
        ),
        buttons: [
          {
            label: 'Понятно',
            type: ButtonType.primary,
            onClick: () => {
              closeConfirm();
            },
          },
        ],
      });
    }
  }, [closeConfirm, data, wasClose]);

  useEffect(() => {
    showConnectModal();
  }, [data]);

  return <UniversalModal wrapClassName="device-sync-modal" data={confirmData} onClose={closeConfirm} />;
};

export default DeviceSyncModal;
