import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { checkIsAdmin, getProfilePermission } from '../../../store/selectors/profile';
import { ESidebarItemIds } from '../../../typings/sidebar';
import { useApi } from '../../../hooks/useApi';
import { getRequest } from '../../../api';
import { setHeaderTitle } from '../../../store/slices/header';
import CommonHead from '../../../components/commonHead';
import { paths } from '../../../constants/paths';
import { flatUrl } from '../../../constants/api';
import ButtonLink from '../../../components/ui/buttonLink';
import ArrowBackIcon from '../../../assets/svg/icons/arrowBack';
import Tabs from '../../../components/tabs';
import { ITab } from '../../../components/tabs/types';
import { flatsTabs } from './config';
import { EFlatTabsIds } from './types';
import Loader from '../../../components/ui/loader';
import { IFlatsPageFlat } from '../../../typings/flat';
import { ELoaderColor } from '../../../components/ui/loader/types';
import { IProfilePermission } from '../../../typings/profile';
import { EPageQueryParams } from '../../../typings/searchParams';
import { getWasChange } from '../../../store/selectors/changes';
import { setChange } from '../../../store/slices/changes';
import { ESystemBaseDeviceSeries } from '../../../typings/systems/base';

const FlatPage: FC = () => {
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useAppDispatch();

  const [activeTab, setActiveTab] = useState<ITab>(
    flatsTabs.find((tab) => tab.id === searchParams.get(EPageQueryParams.tabId)) || flatsTabs[0]
  );

  const [clickedTab, setClickerTab] = useState<ITab | null>(null);

  const isAdmin = useAppSelector(checkIsAdmin);

  const wasChange = useAppSelector(getWasChange);

  const setWasChange = useCallback(
    (value: boolean) => {
      dispatch(setChange(value));
    },
    [dispatch]
  );

  const flatPermissions = useAppSelector(getProfilePermission(ESidebarItemIds.flatInfo)) as IProfilePermission;

  const {
    data: flatData,
    sendRequest: getFlatData,
    loading: flatDataLoading,
  } = useApi<IFlatsPageFlat | null>(getRequest);

  const isFiveThousandth = useMemo(
    () => flatData?.deviceSeriesId === ESystemBaseDeviceSeries.D5000,
    [flatData?.deviceSeriesId]
  );

  const getData = useCallback(() => {
    getFlatData(flatUrl(params.flatId || ''));
  }, [getFlatData, params.flatId]);

  useEffect(() => {
    getData();
    if (!searchParams.get(EPageQueryParams.tabId)) {
      searchParams.set(EPageQueryParams.tabId, EFlatTabsIds.basic);
      setSearchParams(searchParams);
    }
  }, [params.systemId]);

  useEffect(() => {
    if (flatData) {
      dispatch(setHeaderTitle(`Квартира ${flatData.number}` || ''));
    }
  }, [flatData]);

  const chancelChangeTab = useCallback(() => {
    setClickerTab(null);
  }, []);

  const setTabAndSearch = useCallback(
    (newTab: ITab, searchArr?: { name: string; val: any }[]) => {
      setActiveTab(newTab);
      [...searchParams].forEach((item) => {
        searchParams.set(item[0], '');
        searchParams.delete(item[0]);
      });
      searchParams.set(EPageQueryParams.tabId, newTab.id);
      if (searchArr) {
        searchArr.forEach((item) => searchParams.set(item.name, item.val));
      }
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const repeatChangeTab = useCallback(() => {
    if (clickedTab) {
      setTabAndSearch(clickedTab);
      setChange(false);
      setClickerTab(null);
    }
  }, [clickedTab]);

  const onChangeTab = useCallback(
    (id: string) => {
      const newActiveTab = flatsTabs.find((tab) => tab.id === id) || flatsTabs[0];
      if (wasChange) {
        setClickerTab(newActiveTab);
      } else {
        setTabAndSearch(newActiveTab);
      }
    },
    [wasChange, setTabAndSearch]
  );

  const handleOnChangeNextTab = useCallback(() => {
    const newActiveTab = flatsTabs.find((tab) => tab.position === activeTab.position + 1) || flatsTabs[0];
    if (wasChange) {
      setClickerTab(newActiveTab);
    } else {
      setTabAndSearch(newActiveTab);
    }
  }, [activeTab.position, setTabAndSearch, wasChange]);

  if (!flatData) {
    return (
      <div className="flat-item__loader-wrapper">
        <Loader color={ELoaderColor.blue} />
      </div>
    );
  }

  return (
    <>
      <CommonHead seo={{ title: `Квартира ${flatData.number}` }} />
      <div className="flat-item">
        <div className="flat-item__button-wrapper">
          <ButtonLink url={paths.flats} leftIcon={<ArrowBackIcon />} content="Все квартиры" />
        </div>
        <div className="flat-item__content">
          {flatDataLoading && (
            <div className="flat-item__loader-wrapper">
              <Loader color={ELoaderColor.blue} />
            </div>
          )}
          {flatData && (
            <Tabs
              activeTabKey={activeTab.id}
              onChangeActiveTab={onChangeTab}
              tabs={flatsTabs.map((tab) => ({
                ...tab,
                children: (
                  <tab.component
                    isFiveThousandth={isFiveThousandth}
                    updateFlats={getData}
                    wasChange={wasChange}
                    setChange={setWasChange}
                    flatId={params.flatId}
                    flat={flatData}
                    onNextTab={handleOnChangeNextTab}
                    onChangeTab={onChangeTab}
                    tabId={tab.id}
                    activeTabKey={activeTab.id}
                    repeatChangeTab={repeatChangeTab}
                    chancelChangeTab={chancelChangeTab}
                    clickedTab={clickedTab?.id}
                    isAdmin={isAdmin}
                    permissions={flatPermissions}
                  />
                ),
              }))}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default FlatPage;
