import { IMailingRecipientApiSettings, IMailingRecipientsApiParams } from '../../../api/mailingRecipients/types';
import { IApiResponse } from '../../../typings/api';
import { EMailingTypes, IMailingItem, IMailingRecipient } from '../../../typings/mailing';
import { IProfilePermission } from '../../../typings/profile';

export interface IMailingEditorTabComponent {
  mailingType?: EMailingTypes;
  onlyView?: boolean;
  onDeleteRecipients?: (list?: string[]) => void;
  removeRecipients?: string[];
  setRemoveRecipients?: (val: string[]) => void;
  recipientsList?: IApiResponse<IMailingRecipient> | null;
  requestRecipients?: (
    reqSettings?: IMailingRecipientApiSettings<IMailingRecipient>,
    newApiParams?: IMailingRecipientsApiParams
  ) => Promise<void>;
  apiSettings?: IMailingRecipientApiSettings<IMailingRecipient>;
  eventsApiParams?: IMailingRecipientsApiParams;
  haveEmptyFields?: boolean;
  mailingData?: IMailingItem;
  onChangeMailingData?: (key: string, value: any) => void;
  wasChange?: boolean;
  setWasChange?: (value: boolean) => void;
  isActiveTab?: boolean;
  permissions?: IProfilePermission;
  smsCount?: number;
}

export enum ERecipientsFilters {
  search = 'search',
  status = 'status',
  objectId = 'objectId',
}
