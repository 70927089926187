import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { getSystem } from '../../api/systems';
import CommonHead from '../../components/commonHead';
import SystemsTable from '../../components/systemsTable';
import Button from '../../components/ui/button';
import { paths } from '../../constants/paths';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { useApi } from '../../hooks/useApi';
import { checkIsAdmin, getProfilePermission } from '../../store/selectors/profile';
import { setHeaderTitle } from '../../store/slices/header';
import {
  companyTariffVersionUrl,
  companyUrl,
  getObjectSystemIdUrl,
  getObjectSystemsListUrl,
  objectCountRemainingUrl,
} from '../../constants/api';
import { IApiQueryParams, IApiResponse } from '../../typings/api';
import { ESidebarItemIds } from '../../typings/sidebar';
import { ISystem } from '../../typings/systems/system';
import { ESystemTabsIds } from './item/types';
import { getRequest } from '../../api';
import { ISelectOption } from '../../components/ui/select/types';
import { EPageQueryParams } from '../../typings/searchParams';
import WarningBlock from '../../components/ui/warningBlock';
import { ICompanyTariff } from '../../typings/tariffs';
import FiltersTool from '../../components/ui/filtersTool';
import { EFilterTool } from '../../typings/filtersTool';

const Systems: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { data, sendRequest, loading } = useApi<IApiResponse<ISystem>>(getSystem);
  const [apiSettings, setApiSettings] = useState<IApiQueryParams<ISystem>>({
    page: 0,
    count: 10,
    search: '',
  });
  const permissionObject = useAppSelector(getProfilePermission(ESidebarItemIds.objects));

  const { data: companies, sendRequest: getCompanies } = useApi<ISystem[]>(getRequest);

  const isAdmin = useAppSelector(checkIsAdmin);

  const [companyId, setCompanyId] = useState<string | number>('');

  const { data: tariffInfo, sendRequest: getTariffInfo } = useApi<ICompanyTariff>(getRequest);
  const { data: objectCounter, sendRequest: getObjectCounter } = useApi<any>(getRequest);

  useEffect(() => {
    getObjectCounter(objectCountRemainingUrl());
  }, []);

  useEffect(() => {
    if ((objectCounter || objectCounter === 0) && objectCounter <= 0) getTariffInfo(companyTariffVersionUrl());
  }, [objectCounter]);

  useEffect(() => {
    dispatch(setHeaderTitle('Объекты'));
  }, [dispatch]);

  useEffect(() => {
    if (isAdmin) {
      getCompanies(companyUrl(), {
        params: {
          page: 0,
          count: 0,
          search: '',
        },
      });
    }
  }, [isAdmin]);

  const getObjects = useCallback(
    async (params = apiSettings, id = companyId) => {
      if (id) {
        await sendRequest(getObjectSystemIdUrl(id.toString()), { params });
      } else {
        await sendRequest(getObjectSystemsListUrl(), { params });
      }
    },
    [apiSettings, companyId, sendRequest]
  );

  useEffect(() => {
    getObjects();
  }, []);

  const handleOnClickButtonNew = useCallback(() => navigate(paths.systemsNew), [navigate]);

  const handleOnClickRow = useCallback(
    (systemId: string) =>
      navigate({
        pathname: `${paths.systems}/${systemId}`,
        search: `?${createSearchParams({ [`${EPageQueryParams.tabId}`]: ESystemTabsIds.basic })}`,
      }),
    [navigate]
  );

  const handleOnChangeTablePage = useCallback(
    async (page: number) => {
      const newApiSettings: IApiQueryParams<ISystem> = { ...apiSettings, page: page - 1 };
      await getObjects(newApiSettings);
      setApiSettings(newApiSettings);
    },
    [apiSettings, getObjects]
  );

  const handleOnSearch = useCallback(
    async (value: string) => {
      const newApiSettings: IApiQueryParams<ISystem> = { ...apiSettings, page: 0, search: value };
      await getObjects(newApiSettings);
      setApiSettings(newApiSettings);
    },
    [apiSettings, getObjects]
  );

  const setValuesFromUrl = useCallback(
    (values: any) => {
      const newApiSettings: IApiQueryParams<ISystem> = { ...apiSettings, page: 0, search: values.search };
      setCompanyId(values.companyId || '');
      getObjects(newApiSettings, values.companyId || '');
      setApiSettings(newApiSettings);
    },
    [apiSettings, getObjects]
  );

  const handleOnChangeCompanyId = useCallback(
    async (id: string | number) => {
      setCompanyId(id);
      const newApiSettings: IApiQueryParams<ISystem> = { ...apiSettings, page: 0 };
      getObjects(newApiSettings, id);
      setApiSettings(newApiSettings);
    },
    [apiSettings, getObjects]
  );

  const organizationOptions = useMemo(
    () =>
      isAdmin && companies
        ? companies.map<ISelectOption>((object) => ({
            value: object.id || '',
            title: object.companyName || '',
          }))
        : [],
    [companies, isAdmin]
  );

  return (
    <>
      <CommonHead seo={{ title: 'Объекты' }} />

      <div className="systems">
        {(objectCounter || objectCounter === 0) && tariffInfo && objectCounter <= 0 ? (
          <WarningBlock
            wrapperClassName="systems__warning"
            messages={[
              `Лимит на создание объектов по тарифу «${tariffInfo?.version}» исчерпан. Для изменения тарифа обратитесь к администратору Eltis.`,
            ]}
          />
        ) : null}

        <FiltersTool
          setValuesFromUrl={setValuesFromUrl}
          rows={[
            [
              {
                type: EFilterTool.search,
                id: 'search',
                props: {
                  containerClassName: isAdmin ? '' : 'filters-tool__item_mb',
                  placeholder: 'Название объекта, адрес',
                  value: apiSettings?.search,
                  onSearch: handleOnSearch,
                },
              },
              {
                hidden: !permissionObject?.create,
                type: EFilterTool.extraContent,
                props: {
                  children: (
                    <div className="systems__cell">
                      <Button
                        disabled={objectCounter === '' ? false : (objectCounter || 0) <= 0}
                        className="systems__button"
                        onClick={handleOnClickButtonNew}
                      >
                        Добавить объект
                      </Button>
                      {(objectCounter || objectCounter === 0) && (
                        <div className="systems__count">Доступно объектов {objectCounter || 0}</div>
                      )}
                    </div>
                  ),
                },
              },
              {
                type: EFilterTool.select,
                id: 'companyId',
                hidden: !isAdmin,
                props: {
                  showClear: true,
                  isAllOption: true,
                  title: 'Организация',
                  value: companyId,
                  onChange: handleOnChangeCompanyId,
                  options: organizationOptions,
                  containerClassName: 'systems__select',
                },
              },
            ],
          ]}
        />
        <SystemsTable
          systems={data?.items?.reverse() || []}
          onClickRow={handleOnClickRow}
          onEditRow={handleOnClickRow}
          permissions={permissionObject}
          loading={loading}
          pagination={{
            pageSize: data?.pageSize || 0,
            currentPage: data?.page || 0,
            total: data?.totalCount || 0,
            onChangePage: handleOnChangeTablePage,
          }}
        />
      </div>
    </>
  );
};

export default Systems;
