import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { EDeviceErrorsStatus, IDeviceErrorsTable, IJournalError } from './types';
import ErrorPlaceholder from '../ui/errorPlaceholder';
import FiltersTool from '../ui/filtersTool';
import { EFilterTool } from '../../typings/filtersTool';

const DeviceErrorsTable: FC<IDeviceErrorsTable> = (props) => {
  const { errors = [], warnings = [], emptyText = 'Оборудование работает исправно' } = props;

  const [list, setList] = useState<IJournalError[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');

  const getErrors = useCallback(() => {
    const newList: IJournalError[] = [];
    errors?.forEach((item) => newList.push({ text: item, type: EDeviceErrorsStatus.error }));
    warnings?.forEach((item) => newList.push({ text: item, type: EDeviceErrorsStatus.warning }));
    return newList;
  }, [errors, warnings]);

  useEffect(() => {
    setList(getErrors());
  }, [errors, warnings]);

  const onSearch = useCallback(
    (value: string) => {
      setSearchValue(value);
      if (value && value.trim()) {
        const newErrors: IJournalError[] = getErrors();
        const filterErrors = newErrors.filter((item) => item.text.toLowerCase().indexOf(value.toLowerCase()) >= 0);
        setList(filterErrors);
      } else {
        setList(getErrors());
      }
    },
    [getErrors]
  );

  const setValuesFromUrl = useCallback(
    (values: any) => {
      onSearch(values.search);
    },
    [onSearch]
  );

  const columns = useMemo<ColumnsType<IJournalError>>(
    () => [
      {
        title: 'Ошибка/предупреждение',
        dataIndex: 'error',
        key: 'error',
        render: (_, record: IJournalError) => (
          <div className="editable-table__data-container">
            {record.type === EDeviceErrorsStatus.error ? 'Ошибка' : 'Предупреждение'}
          </div>
        ),
        width: '30%',
      },
      {
        title: 'Описание',
        dataIndex: 'description',
        key: 'description',
        render: (_, record: IJournalError) => <div className="editable-table__data-container">{record.text}</div>,
        width: '70%',
      },
    ],
    []
  );

  return (
    <div className="device-errors-table">
      <FiltersTool
        setValuesFromUrl={setValuesFromUrl}
        rows={[
          [
            {
              type: EFilterTool.search,
              id: 'search',
              props: {
                showClear: true,
                placeholder: 'Поиск по тексту',
                value: searchValue,
                onSearch: onSearch,
              },
            },
          ],
        ]}
      />

      <div className="editable-table">
        <Table
          columns={columns}
          dataSource={list}
          showSorterTooltip={false}
          pagination={false}
          locale={{
            emptyText: (
              <ErrorPlaceholder
                containerClassName="device-event-table__error-wrapper"
                text={searchValue ? 'По вашему запросу ничего не найдено' : emptyText}
              />
            ),
          }}
        />
      </div>
    </div>
  );
};

export default DeviceErrorsTable;
