import React, { FC, useCallback, useEffect, useState } from 'react';
import Button from '../../../ui/button';
import Input from '../../../ui/input';
import {
  IInputTAdressValue,
  IInputValue,
  InputStatus,
  InputType,
  defaultAddressValue,
  defaultNotRequiredValue,
  defaultRequiredValue,
} from '../../../ui/input/types';
import Modal from '../../../ui/modal';
import { ITreeNode, TreeNodeType } from '../../../../typings/treeNode';
import { ITreeNodeModal } from '../types';
import { ButtonType } from '../../../ui/button/types';
import { ONLY_NUMBERS } from '../../../../constants/regex';
import { TreeNodeNamesMap } from '../constans';
import InputDaData from '../../../ui/inputDaData';

const BuildingsModal: FC<ITreeNodeModal> = (props) => {
  const {
    isOpen = false,
    onCreate = () => {},
    onCancel = () => {},
    editObject,
    onEdit = () => {},
    path,
    getLayout = () => '',
    parent = null,
    settings = {},
  } = props;

  const [addressData, setAddressData] = useState<IInputTAdressValue>({ ...defaultAddressValue });
  const [shortName, setShortName] = useState<IInputValue>({ ...defaultRequiredValue });
  const [prefix, setPrefix] = useState<IInputValue>({ ...defaultRequiredValue });

  const handleOnChangeInput = useCallback(
    (setter: React.Dispatch<React.SetStateAction<IInputValue>>, value: string) =>
      setter({ ...defaultRequiredValue, status: InputStatus.normal, errorText: '', value }),
    []
  );

  const validateInputs = useCallback(() => {
    let isValid = true;

    if (settings.daDataSearch) {
      if (editObject && !editObject.fiasId && !addressData.fiasId) {
        setAddressData({ ...addressData, status: InputStatus.error, errorText: 'Заполните адрес повторно' });
        isValid = false;
      } else if (addressData.status === InputStatus.error) {
        isValid = false;
      } else if (!addressData.address.trim()) {
        setAddressData({ ...addressData, status: InputStatus.error, errorText: 'Поле обязательно для заполнения' });
        isValid = false;
      }
    }

    if (!shortName.value || !shortName.value.trim()) {
      setShortName({ ...shortName, status: InputStatus.error });
      isValid = false;
    }

    if (!prefix.value || !ONLY_NUMBERS.test(prefix.value)) {
      setPrefix({ ...prefix, status: InputStatus.error });
      isValid = false;
    }

    return isValid;
  }, [addressData, editObject, prefix, settings.daDataSearch, shortName]);

  const handleOnCreate = useCallback(() => {
    if (validateInputs()) {
      const newBuilding: ITreeNode = {
        name: shortName.value,
        shortName: shortName.value,
        address: addressData.shortAddress || editObject?.address,
        prefix: Number(prefix.value),
        layout: path ? getLayout(path, shortName.value) : editObject ? editObject.layout : '',
        type: TreeNodeType.building,
        childItems: editObject ? editObject.childItems : [],
        accessPoints: editObject ? editObject.accessPoints : [],
        cameras: editObject ? editObject.cameras : [],
      };

      if (settings.daDataSearch) {
        newBuilding.fiasId = addressData.fiasId || editObject?.fiasId;
      }

      if (editObject) {
        newBuilding.id = editObject.id;
        onEdit(newBuilding);
      } else {
        onCreate(newBuilding);
      }

      onCancel();
    }
  }, [
    validateInputs,
    shortName.value,
    addressData,
    editObject,
    prefix.value,
    path,
    getLayout,
    settings.daDataSearch,
    onCancel,
    onEdit,
    onCreate,
  ]);

  useEffect(() => {
    if (!editObject) {
      setAddressData({ ...defaultAddressValue });
      setShortName({ ...defaultNotRequiredValue });
      setPrefix({ ...defaultNotRequiredValue });
    }
  }, [isOpen]);

  useEffect(() => {
    if (editObject) {
      setAddressData({
        ...defaultAddressValue,
        address: editObject.address || '',
        searchValue: editObject.address || '',
        fiasId: editObject.fiasId || '',
        isFinalAddress: true,
      });
      setShortName({ ...defaultNotRequiredValue, value: editObject.shortName || '' });
      setPrefix({ ...defaultNotRequiredValue, value: `${editObject.prefix || ''}` });
    }
  }, [editObject]);

  return (
    <Modal
      isOpen={isOpen}
      title={editObject ? 'Редактирование строения' : 'Создание строения'}
      onCancel={onCancel}
      width={400}
      wrapClassName="schema-modal__wrapper"
      footer={
        <div className="ant-modal-footer__buttons">
          <Button onClick={handleOnCreate}>{editObject ? 'Редактировать' : 'Создать'}</Button>
          <Button type={ButtonType.secondary} onClick={onCancel}>
            Отмена
          </Button>
        </div>
      }
    >
      <div className="schema-modal">
        {!editObject && parent?.type && TreeNodeNamesMap.get(parent.type) && (
          <div className="schema-modal__parent">
            {TreeNodeNamesMap.get(parent.type)}. {parent.object?.name}
          </div>
        )}
        <div className="schema-modal__input-wrapper">
          <InputDaData daDataSearch={settings.daDataSearch} onChange={setAddressData} value={addressData} />
        </div>
        <div className="schema-modal__input-wrapper">
          <Input
            title="Сокращенное название"
            value={shortName.value}
            status={shortName.status}
            onChange={(value) => handleOnChangeInput(setShortName, value)}
            placeholder="Сокращенное название строения"
            description={shortName.status === InputStatus.error ? '' : 'Будет отображаться на плане'}
            maxLength={10}
            isRequired
            errorText={shortName.status === InputStatus.error ? 'Поле обязательно для заполнения' : ''}
          />
        </div>
        <div className="schema-modal__input-wrapper">
          <Input
            title="Код строения"
            value={prefix.value}
            status={prefix.status}
            onChange={(value) => handleOnChangeInput(setPrefix, value)}
            placeholder="Код строения"
            inputType={InputType.numbers}
            maxLength={2}
            isRequired
            errorText={prefix.status === InputStatus.error ? 'Поле обязательно для заполнения' : ''}
          />
        </div>
      </div>
    </Modal>
  );
};

export default BuildingsModal;
