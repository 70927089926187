import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { checkIsAdmin, getProfilePermission } from '../../store/selectors/profile';
import { ESidebarItemIds } from '../../typings/sidebar';
import { useApi } from '../../hooks/useApi';
import { getRequest, postFileRequest, postRequest } from '../../api';
import { setHeaderTitle } from '../../store/slices/header';
import CommonHead from '../../components/commonHead';
import Button from '../../components/ui/button';
import { ButtonType } from '../../components/ui/button/types';
import DownloadIcon from '../../assets/svg/icons/download';
import { paths } from '../../constants/paths';
import {
  companyTariffVersionUrl,
  companyUrl,
  exportUserUrl,
  getUsersListUrl,
  userCountRemainingUrl,
} from '../../constants/api';
import { IApiResponse } from '../../typings/api';
import { IUser } from '../../typings/users';
import { IUsersApiSettings } from '../../api/users/types';
import { getUsersApiSettings } from '../../api/users/config';
import UsersTable from '../../components/usersTable';
import { ISystem } from '../../typings/systems/system';
import { ISelectOption } from '../../components/ui/select/types';
import WarningBlock from '../../components/ui/warningBlock';
import { ICompanyTariff } from '../../typings/tariffs';
import FiltersTool from '../../components/ui/filtersTool';
import { EFilterTool } from '../../typings/filtersTool';

const UsersPage: FC = () => {
  const dispatch = useAppDispatch();

  const permissions = useAppSelector(getProfilePermission(ESidebarItemIds.users));

  const isAdmin = useAppSelector(checkIsAdmin);

  const { data: companies, sendRequest: getCompanies } = useApi<ISystem[]>(getRequest);
  const [companyId, setCompanyId] = useState<string | number>('');

  const { data: users, sendRequest: getUsers, loading: usersLoading } = useApi<IApiResponse<IUser>>(postRequest);
  const { data: exportData, sendRequest: getExportData } = useApi<any>(postFileRequest);

  const navigate = useNavigate();

  const [apiSettings, setApiSettings] = useState<IUsersApiSettings>(getUsersApiSettings());

  const { data: tariffInfo, sendRequest: getTariffInfo } = useApi<ICompanyTariff>(getRequest);
  const { data: usersCounter, sendRequest: getUsersCounter } = useApi<any>(getRequest);

  useEffect(() => {
    getUsersCounter(userCountRemainingUrl());
  }, []);

  useEffect(() => {
    if ((usersCounter || usersCounter === 0) && usersCounter <= 0) getTariffInfo(companyTariffVersionUrl());
  }, [usersCounter]);

  const requestData = useCallback(
    async (reqSettings: IUsersApiSettings = apiSettings, company = companyId) => {
      setApiSettings(reqSettings);
      await getUsers(getUsersListUrl(), reqSettings, company ? { params: { companyId: company } } : {});
    },
    [apiSettings, companyId, getUsers]
  );

  useEffect(() => {
    requestData();
    dispatch(setHeaderTitle('Пользователи'));
  }, []);

  useEffect(() => {
    if (isAdmin) {
      getCompanies(companyUrl());
    }
  }, [isAdmin]);

  const saveFile = useCallback(async () => {
    if (exportData) {
      const fileOptions = {
        suggestedName: 'Users.xls',
        types: [
          {
            description: 'xls',
            accept: {
              'application/msexcel': '.xls',
            },
          },
        ],
        excludeAcceptAllOption: true,
      };
      const fileHandle = await (window as any).showSaveFilePicker(fileOptions);
      const writableStream = await fileHandle.createWritable();
      await writableStream.write(exportData.data);
      await writableStream.close();
    }
  }, [exportData]);

  useEffect(() => {
    saveFile();
  }, [exportData]);

  const handleOnChangeTablePage = useCallback(
    async (page: number) => {
      const newApiSettings = { ...apiSettings, page: page - 1 };
      await requestData(newApiSettings);
    },
    [apiSettings, requestData]
  );

  const handleOnSearch = useCallback(
    async (value: string) => {
      const newApiSettings: IUsersApiSettings = { ...apiSettings, page: 0, search: value };
      requestData(newApiSettings);
    },
    [apiSettings, requestData]
  );

  const onExport = useCallback(() => {
    getExportData(exportUserUrl());
  }, [getExportData]);

  const onCreateClick = useCallback(() => {
    navigate(`${paths.users}/create`);
  }, [navigate]);

  const handleOnChangeCompanyId = useCallback(
    async (id: string | number) => {
      setCompanyId(id);
      requestData(apiSettings, id);
    },
    [apiSettings, requestData]
  );

  const setValuesFromUrl = useCallback(
    (values: any) => {
      const newApiSettings: IUsersApiSettings = { ...apiSettings, page: 0, search: values.search || '' };
      setCompanyId(values.companyId || '');
      requestData(newApiSettings, values.companyId || '');
    },
    [apiSettings, requestData]
  );

  const organizationOptions = useMemo(
    () =>
      isAdmin && companies
        ? companies.map<ISelectOption>((object) => ({
            value: object.id || '',
            title: object.companyName || '',
          }))
        : [],
    [companies, isAdmin]
  );

  return (
    <>
      <CommonHead seo={{ title: 'Пользователи' }} />
      <div className="users">
        {(usersCounter || usersCounter === 0) && tariffInfo && usersCounter <= 0 ? (
          <WarningBlock
            wrapperClassName="systems__warning"
            messages={[
              `Лимит на создание пользователей по тарифу «${tariffInfo?.version}» исчерпан. Для изменения тарифа обратитесь к администратору Eltis.`,
            ]}
          />
        ) : null}

        <FiltersTool
          setValuesFromUrl={setValuesFromUrl}
          rows={[
            [
              {
                type: EFilterTool.select,
                hidden: !isAdmin,
                id: 'companyId',
                props: {
                  isAllOption: true,
                  showClear: true,
                  title: 'Организация',
                  value: companyId,
                  onChange: handleOnChangeCompanyId,
                  options: organizationOptions,
                  containerClassName: isAdmin ? '' : 'filters-tool__item_mb',
                },
              },
              {
                type: EFilterTool.search,
                id: 'search',
                props: {
                  placeholder: 'Поиск по ФИО, E–mail',
                  value: apiSettings.search,
                  onSearch: handleOnSearch,
                  containerClassName: isAdmin ? '' : 'filters-tool__item_mb',
                },
              },
              {
                type: EFilterTool.button,
                hidden: !permissions?.export,
                props: {
                  onClick: onExport,
                  type: ButtonType.outline,
                  rightIcon: <DownloadIcon />,
                  children: 'Экспорт',
                  containerClassName: isAdmin ? '' : 'filters-tool__item_mb',
                },
              },
              {
                type: EFilterTool.extraContent,
                hidden: !permissions?.create,
                props: {
                  children: (
                    <div className="users__create">
                      <Button disabled={usersCounter === '' ? false : (usersCounter || 0) <= 0} onClick={onCreateClick}>
                        Добавить пользователя
                      </Button>
                      {(usersCounter || usersCounter === 0) && (
                        <div className="users__create-count">Доступно пользователей {usersCounter || 0}</div>
                      )}
                    </div>
                  ),
                },
              },
            ],
          ]}
        />
        <UsersTable
          isAdmin={isAdmin}
          permissions={permissions}
          users={users?.items || []}
          loading={usersLoading}
          pageSize={users?.pageSize}
          total={users?.totalCount}
          currentPage={users?.page}
          onChangePage={handleOnChangeTablePage}
          isSearch={!!apiSettings.search}
          requestData={requestData}
        />
      </div>
    </>
  );
};

export default UsersPage;
