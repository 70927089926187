import { DatePicker as DatePickerComponent } from 'antd';
import locale from 'antd/es/date-picker/locale/ru_RU';
import { RangePickerProps } from 'antd/lib/date-picker';
import classNames from 'classnames';
import moment, { Moment } from 'moment';
import 'moment/locale/ru';
// eslint-disable-next-line import/no-extraneous-dependencies
import { RangeInfo, RangeType } from 'rc-picker/lib/RangePicker';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import CalendarIcon from '../../../assets/svg/icons/calendar';
import ChevronDoubleLeft from '../../../assets/svg/icons/chevronDoubleLeft';
import ChevronDoubleRight from '../../../assets/svg/icons/chevronDoubleRight';
import ChevronLeft from '../../../assets/svg/icons/chevronLeft';
import ChevronRight from '../../../assets/svg/icons/chevronRight';
import CloseIcon from '../../../assets/svg/icons/close';
import Button from '../button';
import { ButtonSize, ButtonType } from '../button/types';
import Select from '../select';
import { ISelectOption } from '../select/types';
import { EDatePickerRangeInfo, EDatePickerTimeType, IDatePicker } from './types';
import { dateDefaultFormat, dateFormatNoTime } from '../../../constants/date';
import InfoIcon from '../../../assets/svg/icons/info';
import Tooltip from '../tooltip';

const { RangePicker } = DatePickerComponent;

const isSameDate = (first: Moment | null, second: Moment | null): boolean =>
  first?.format('YYYY-MM-DD') === second?.format('YYYY-MM-DD');

const PeriodDatePicker: FC<IDatePicker> = (props) => {
  const {
    title = '',
    isCanUnlimited = false,
    isError = false,
    isRequired = false,
    containerClassName = '',
    titleClassName = '',
    firstDate = null,
    secondDate = null,
    format = dateDefaultFormat,
    errorText = '',
    errorTextClassName = '',
    onChange = () => {},
    disabled = false,
    isDisabledStyle = false,
    placeholder = 'Выберите период',
    showTime = false,
    textInfo,
    onlyFuture = false,
    showClearIcon = false,
  } = props;

  const listenerRef = useRef<EventListenerOrEventListenerObject | null>(null);

  const [currentStartDate, setCurrentStartDate] = useState<Moment | null>(firstDate ? moment(firstDate) : null);
  const [currentEndDate, setCurrentEndDate] = useState<Moment | null>(secondDate ? moment(secondDate) : null);
  const [startDateHover, setStartDateHover] = useState<string>('');
  const [endDateHover, setEndDateHover] = useState<string>('');

  const [isRangePickerOpen, setIsRangePickerOpen] = useState<boolean>(false);

  const handleOnChange: RangePickerProps['onChange'] = (dates, dateStrings) => {
    onChange(dates?.[0]?.clone(), dates?.[1]?.clone(), dateStrings[0], dateStrings[1]);
  };

  const handleOnClearDatePicker = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
      handleOnChange(null, ['', '']);
      setCurrentStartDate(null);
      setCurrentEndDate(null);
      setStartDateHover('');
      setEndDateHover('');
    },
    [handleOnChange]
  );

  const handleOnChangeCalendar: RangePickerProps['onCalendarChange'] = (
    dates,
    formatString: [string, string],
    info: RangeInfo
  ) => {
    setCurrentStartDate(dates?.[0] || null);
    setCurrentEndDate(dates?.[1] || null);
    if (info.range === EDatePickerRangeInfo.start) {
      setStartDateHover('');
    } else if (info.range === EDatePickerRangeInfo.end) {
      setEndDateHover('');
    }
  };

  useEffect(() => {
    if (!firstDate && !secondDate) {
      setCurrentEndDate(null);
      setCurrentStartDate(null);
    } else if (firstDate && secondDate) {
      setCurrentStartDate(moment(firstDate));
      setCurrentEndDate(moment(secondDate));
    }
  }, [firstDate, secondDate]);

  const resetCurrentDates = useCallback(() => {
    setCurrentStartDate(firstDate ? moment(firstDate) : null);
    setCurrentEndDate(secondDate ? moment(secondDate) : null);
  }, [firstDate, secondDate]);

  const selectHours = useMemo(() => new Array(24).fill(0).map((item, index) => index), []);
  const selectMinutes = useMemo(() => new Array(60).fill(0).map((item, index) => index), []);

  const handleSelectOnChange = useCallback(
    (
      setter: React.Dispatch<React.SetStateAction<moment.Moment | null>>,
      currentDate: moment.Moment | null,
      type: EDatePickerTimeType,
      value: string | number
    ) => {
      if (currentDate) {
        const newDate = moment(currentDate).set(type, Number(value));
        setter(newDate);
      }
    },
    []
  );

  const handleOnSelectTimeStartDate = useCallback(
    (value: string | number, type: EDatePickerTimeType) => {
      const newStartDate = moment(currentStartDate).set(type, Number(value));
      setCurrentStartDate(newStartDate);

      if (newStartDate && currentEndDate && isSameDate(newStartDate, currentEndDate)) {
        const newEndDate = moment(currentEndDate);
        const startDateHour = newStartDate.get(EDatePickerTimeType.hour);
        const endDateHour = currentEndDate.get(EDatePickerTimeType.hour);

        if (startDateHour >= endDateHour) {
          newEndDate.set(EDatePickerTimeType.hour, startDateHour);
          const startDateMinute = newStartDate.get(EDatePickerTimeType.minute);
          const endDateMinute = currentEndDate.get(EDatePickerTimeType.minute);

          if (startDateMinute > endDateMinute) {
            newEndDate.set(EDatePickerTimeType.minute, startDateMinute);
          }
        }

        setCurrentEndDate(newEndDate);
      }
    },
    [currentEndDate, currentStartDate]
  );

  const handleOnClickButtonSave = useCallback(() => {
    if (currentStartDate && (isCanUnlimited ? true : currentEndDate)) {
      handleOnChange(
        [currentStartDate, currentEndDate],
        [currentStartDate.format(format), currentEndDate?.format(format) || '']
      );
      setIsRangePickerOpen(false);
    }
  }, [currentEndDate, currentStartDate, format, handleOnChange, isCanUnlimited]);

  const handleOnClickButtonCancel = useCallback(() => {
    resetCurrentDates();
    setIsRangePickerOpen(false);
  }, [resetCurrentDates]);

  const listener = useCallback(
    (event: Event) => {
      const datePickerDropdownElement = document.querySelectorAll('.date-picker__dropdown');
      const selectDropdownElements = document.querySelectorAll('.select__dropdown');

      if (
        Array.from(datePickerDropdownElement).reduce(
          (result, element) => result || element.contains(event.target as Node),
          false
        ) ||
        Array.from(selectDropdownElements).reduce(
          (result, element) => result || element.contains(event.target as Node),
          false
        )
      ) {
        return;
      }

      handleOnClickButtonCancel();
    },
    [handleOnClickButtonCancel]
  );

  useEffect(() => {
    if (isRangePickerOpen) {
      listenerRef.current = listener;
      document.addEventListener('mousedown', listenerRef.current);
      document.addEventListener('touchstart', listenerRef.current);
    } else {
      if (listenerRef.current) {
        document.removeEventListener('mousedown', listenerRef.current);
        document.removeEventListener('touchstart', listenerRef.current);
      }

      listenerRef.current = null;
    }
  }, [isRangePickerOpen]);

  const getDate = useCallback(
    () =>
      `${currentStartDate ? `${currentStartDate?.format(format)} –` : ''} ${
        currentEndDate ? currentEndDate?.format(format) : currentStartDate && isCanUnlimited ? 'Не ограничен' : ''
      }`,
    [currentEndDate, currentStartDate, format, isCanUnlimited]
  );

  const disabledDateTime = useCallback(
    (current: Moment | null) => ({
      disabledHours: () =>
        onlyFuture && current && new Date(current.valueOf()).toDateString() === new Date().toDateString()
          ? [...Array(moment().hour() + 1)].map((_, index) => index)
          : [],
    }),
    [onlyFuture]
  );

  const disabledDate = useCallback(
    (current: Moment) =>
      onlyFuture && new Date(moment().add(-1, 'day').valueOf()).setHours(0) >= new Date(current.valueOf()).setHours(0),
    [onlyFuture]
  );

  const renderExtraFooter = useMemo(
    () => (
      <div className="date-picker__footer">
        {!showTime ? (
          <div className="date-picker__footer-block">
            <div className="date-picker__footer-info">
              <div className="date-picker__footer-info-icon">
                <CalendarIcon />
              </div>
              <span className="date-picker__footer-info-text">{getDate()}</span>
            </div>
            <div className="date-picker__footer-button-container">
              <Button
                size={ButtonSize.small}
                type={ButtonType.secondary}
                className="date-picker__footer-button"
                onClick={handleOnClickButtonCancel}
              >
                Отмена
              </Button>
              <Button
                disabled={!currentStartDate || isCanUnlimited ? false : !currentEndDate}
                size={ButtonSize.small}
                className="date-picker__footer-button"
                onClick={handleOnClickButtonSave}
              >
                Сохранить
              </Button>
            </div>
          </div>
        ) : (
          <>
            <div className="date-picker__footer-block date-picker__footer-block_time">
              <div className="date-picker__footer-time-wrapper">
                <Select
                  title={currentStartDate?.format(dateFormatNoTime) || ''}
                  placeholder=""
                  containerClassName="date-picker__select"
                  value={currentStartDate?.get(EDatePickerTimeType.hour)}
                  options={selectHours.map<ISelectOption>((item) => ({
                    value: item,
                    title: moment({ hour: item }).format('HH'),
                  }))}
                  disabled={!currentStartDate}
                  placement="topLeft"
                  onChange={(value) => handleOnSelectTimeStartDate(value, EDatePickerTimeType.hour)}
                />
                <span className="date-picker__footer-time-separator">:</span>
                <Select
                  placeholder=""
                  containerClassName="date-picker__select"
                  value={currentStartDate?.get(EDatePickerTimeType.minute)}
                  options={selectMinutes.map<ISelectOption>((item) => ({
                    value: item,
                    title: moment({ minute: item }).format('mm'),
                  }))}
                  disabled={!currentStartDate}
                  placement="topRight"
                  onChange={(value) => handleOnSelectTimeStartDate(value, EDatePickerTimeType.minute)}
                />
              </div>
              <div className="date-picker__footer-time-wrapper">
                <Select
                  title={currentEndDate?.format(dateFormatNoTime) || ''}
                  placeholder=""
                  containerClassName="date-picker__select"
                  value={currentEndDate?.get(EDatePickerTimeType.hour)}
                  options={selectHours.map<ISelectOption>((item) => ({
                    value: item,
                    title: moment({ hour: item }).format('HH'),
                    disabled:
                      isSameDate(currentStartDate, currentEndDate) &&
                      (currentStartDate?.get(EDatePickerTimeType.hour) || -1) > item,
                  }))}
                  disabled={!currentEndDate}
                  placement="topLeft"
                  onChange={(value) =>
                    handleSelectOnChange(setCurrentEndDate, currentEndDate, EDatePickerTimeType.hour, value)
                  }
                />
                <span className="date-picker__footer-time-separator">:</span>
                <Select
                  placeholder=""
                  containerClassName="date-picker__select"
                  value={currentEndDate?.get(EDatePickerTimeType.minute)}
                  options={selectMinutes.map<ISelectOption>((item) => ({
                    value: item,
                    title: moment({ minute: item }).format('mm'),
                    disabled:
                      isSameDate(currentStartDate, currentEndDate) &&
                      currentStartDate?.get(EDatePickerTimeType.hour) ===
                        currentEndDate?.get(EDatePickerTimeType.hour) &&
                      (currentStartDate?.get(EDatePickerTimeType.minute) || -1) > item,
                  }))}
                  disabled={!currentEndDate}
                  placement="topRight"
                  onChange={(value) =>
                    handleSelectOnChange(setCurrentEndDate, currentEndDate, EDatePickerTimeType.minute, value)
                  }
                />
              </div>
            </div>
            <div className="date-picker__footer-button-container date-picker__footer-button-container_indent-top">
              <Button
                size={ButtonSize.small}
                type={ButtonType.secondary}
                className="date-picker__footer-button"
                onClick={handleOnClickButtonCancel}
              >
                Отмена
              </Button>
              <Button
                disabled={!currentStartDate || !currentEndDate}
                size={ButtonSize.small}
                className="date-picker__footer-button"
                onClick={handleOnClickButtonSave}
              >
                Сохранить
              </Button>
            </div>
          </>
        )}
      </div>
    ),
    [
      currentEndDate,
      currentStartDate,
      getDate,
      handleOnClickButtonCancel,
      handleOnClickButtonSave,
      handleOnSelectTimeStartDate,
      handleSelectOnChange,
      isCanUnlimited,
      selectHours,
      selectMinutes,
      showTime,
    ]
  );

  const handleOnHoverDate = useCallback(
    (date: Moment | null, rangeInfo: RangeType) => {
      if (rangeInfo === EDatePickerRangeInfo.start) {
        setStartDateHover(date ? date.format(format) : '');
      } else if (rangeInfo === EDatePickerRangeInfo.end) {
        setEndDateHover(date ? date.format(format) : '');
      }
    },
    [format]
  );

  const renderDate = useCallback(
    (currentDate: Moment, date: Moment, info: RangeInfo) => (
      <div
        onMouseEnter={() => handleOnHoverDate(currentDate, info.range)}
        onMouseLeave={() => handleOnHoverDate(null, info.range)}
        className="ant-picker-cell-inner"
      >
        {currentDate.date()}
      </div>
    ),
    [handleOnHoverDate]
  );

  return (
    <div
      className={classNames('date-picker', containerClassName, {
        'date-picker__default-disabled': disabled && isDisabledStyle,
        'date-picker__error': isError,
      })}
    >
      {title && (
        <span className={classNames('date-picker__title', titleClassName)}>
          {title}
          {isRequired && <span className="date-picker__title-required">*</span>}
          {textInfo && (
            <Tooltip title={textInfo} placement="bottom">
              <div className="date-picker__title-info-icon">
                <InfoIcon />
              </div>
            </Tooltip>
          )}
        </span>
      )}
      <div className="date-picker__content">
        <div
          role="presentation"
          className={classNames('date-picker__input-field', {
            'date-picker__input-field_focus': isRangePickerOpen && !disabled,
            'date-picker__input-field_disabled': disabled,
          })}
          onClick={() => setIsRangePickerOpen(true)}
        >
          {startDateHover ? (
            <span className="date-picker__input-filed-text date-picker__input-filed-text_placeholder">
              {startDateHover}
            </span>
          ) : currentStartDate ? (
            <span className="date-picker__input-filed-text">{currentStartDate?.format(format)}</span>
          ) : (
            <span className="date-picker__input-filed-text date-picker__input-filed-text_placeholder">
              {placeholder}
            </span>
          )}
          {currentStartDate && <span className="date-picker__input-filed-text date-picker__separator">–</span>}
          {endDateHover ? (
            <span className="date-picker__input-filed-text date-picker__input-filed-text_placeholder">
              {endDateHover}
            </span>
          ) : currentEndDate ? (
            <span className="date-picker__input-filed-text">{currentEndDate?.format(format)}</span>
          ) : currentStartDate && isCanUnlimited ? (
            <span className="date-picker__input-filed-text">Не ограничен</span>
          ) : null}

          <div className="date-picker__input-field-icon-container">
            <div className="date-picker__input-filed-icon">
              <CalendarIcon />
            </div>
            {(currentStartDate || currentEndDate) && !disabled && (
              <div
                role="presentation"
                className={classNames('date-picker__input-filed-icon date-picker__input-filed-icon_clear', {
                  'date-picker__input-filed-icon_clear-show': showClearIcon,
                })}
                onClick={handleOnClearDatePicker}
              >
                <CloseIcon />
              </div>
            )}
          </div>
        </div>
        {isRangePickerOpen && (
          <RangePicker
            inputReadOnly
            disabled={disabled}
            locale={locale}
            format={format}
            className={classNames('date-picker__field', {
              'date-picker__field_clear': !currentStartDate && !currentEndDate,
            })}
            dropdownClassName="date-picker__dropdown"
            getPopupContainer={(triggerNode: HTMLElement) => triggerNode.parentNode as HTMLElement}
            suffixIcon={<CalendarIcon />}
            separator={<span className="date-picker__separator">–</span>}
            placeholder={[placeholder, '']}
            value={[currentStartDate, currentEndDate]}
            onOk={() => {}}
            renderExtraFooter={() => renderExtraFooter}
            dateRender={renderDate}
            monthCellRender={(date) => date.format('MMMM')}
            prevIcon={<ChevronLeft />}
            superPrevIcon={<ChevronDoubleLeft />}
            nextIcon={<ChevronRight />}
            superNextIcon={<ChevronDoubleRight />}
            onCalendarChange={handleOnChangeCalendar}
            open={isRangePickerOpen}
            onClick={() => setIsRangePickerOpen(true)}
            disabledDate={disabledDate}
            disabledTime={disabledDateTime}
          />
        )}
      </div>
      {errorText && <span className={classNames('input__error', errorTextClassName)}>{errorText}</span>}
    </div>
  );
};

export default PeriodDatePicker;
