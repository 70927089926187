import React, { FC, useCallback, useEffect, useState } from 'react';
import {
  checkObjectSecurityKeysUrl,
  generateObjectSecurityKeysUrl,
  generateServiceKeysUrl,
  getServiceKeysUrl,
  serviceKeyAliasUrl,
  serviceKeyUrl,
} from '../../../constants/api';
import { useApi } from '../../../hooks/useApi';
import TabNavButtons from '../../tabs/tabNavButtons';
import { tabNavButtonsDefault } from '../../tabs/tabNavButtons/utils';
import TabsCustom from '../../tabsCustom';
import { ICustomTab } from '../../tabsCustom/types';
import { ISystemTabComponent } from '../types';
import KeysUtility from '../../ui/keysUtility';
import { EPagesWithKeys } from '../../ui/keysUtility/types';
import { ButtonType } from '../../ui/button/types';
import { deleteRequest, getRequest, postRequest } from '../../../api';
import { IObjectKey } from '../../../typings/systems/objectKeys';
import SectionServiceKeys from './sectionServiceKeys';
import { checkSecurityKeysInObject, generateSecurityKeysInObject } from '../../../api/objectKeys';
import UniversalModal from '../../ui/universalModal';
import { IConfirmData } from '../../ui/universalModal/types';
import { defaultConfirm, deleteModal } from '../../ui/universalModal/config';
import EditIcon from '../../../assets/svg/icons/edit';
import TrashIcon from '../../../assets/svg/icons/trash';
import EditKeyModal from '../objectKeysTab/editKeyModal';
import FiltersTool from '../../ui/filtersTool';
import { EFilterTool } from '../../../typings/filtersTool';
import AddNodeIcon from '../../../assets/svg/icons/addNote';
import SearchFailIcon from '../../../assets/svg/icons/searchFail';
import { ESystemDeviceMode } from '../../../typings/systems/base';

const ServiceKeysTab: FC<ISystemTabComponent> = (props) => {
  const {
    systemId = '',
    onNextTab = () => {},
    tabId = '',
    activeTabKey = '',
    updateAvailableTabs = () => {},
    nextTabIsAvailable,
    permissions = {},
    system,
  } = props;
  const [activeTabId, setActiveTabId] = useState<string>('');

  const { data: keys, sendRequest: requestKeys, loading: keysLoading } = useApi<IObjectKey[]>(getRequest);
  const { sendRequest: generateKeys, loading: generateKeysLoading } = useApi(postRequest);
  const { sendRequest: sendRequestGenerateSecurityKeys } = useApi(generateSecurityKeysInObject);
  const { sendRequest: deleteKey } = useApi(deleteRequest);
  const { sendRequest: updateKey } = useApi(postRequest);

  const { data: isValidSecurityKey, sendRequest: sendRequestSecurityKey } = useApi<boolean>(checkSecurityKeysInObject);

  const [searchValue, setSearch] = useState('');

  const [confirmData, setConfirmData] = useState<IConfirmData>(defaultConfirm);

  const closeConfirm = useCallback(() => setConfirmData(defaultConfirm), []);

  const [editKey, setEditKey] = useState<IObjectKey | null>(null);

  const getKeys = useCallback(
    (search = searchValue) => {
      if (systemId) requestKeys(getServiceKeysUrl(systemId), { params: { search } });
    },
    [requestKeys, searchValue, systemId]
  );

  const handleOnGenerateSecurityKeys = useCallback(async () => {
    if (systemId) {
      closeConfirm();
      await sendRequestGenerateSecurityKeys(generateObjectSecurityKeysUrl(systemId));
      sendRequestSecurityKey(checkObjectSecurityKeysUrl(systemId));
    }
  }, [closeConfirm, sendRequestGenerateSecurityKeys, sendRequestSecurityKey, systemId]);

  useEffect(() => {
    if (systemId) {
      sendRequestSecurityKey(checkObjectSecurityKeysUrl(systemId));
    }
  }, [systemId]);

  useEffect(() => {
    if (tabId === activeTabKey) getKeys();
  }, [activeTabKey, tabId]);

  useEffect(() => {
    if (keys?.length && !activeTabId) setActiveTabId(keys[0].id);
  }, [keys]);

  const onSearch = useCallback(
    (search: string) => {
      setSearch(search);
      getKeys(search);
    },
    [getKeys]
  );

  const tryToGenerateKeys = useCallback(async () => {
    if (system?.secureKeyMode === ESystemDeviceMode.EMF ? isValidSecurityKey : true) {
      setConfirmData({
        isOpen: true,
        description: 'Запущен процесс генерации ключа. Следуйте указаниям в приложении для работы со считывателем',
        buttons: [
          {
            label: 'Хорошо',
            type: ButtonType.primary,
            onClick: closeConfirm,
          },
        ],
      });
      await generateKeys(generateServiceKeysUrl(systemId), 1);
      getKeys();
    } else {
      setConfirmData({
        isOpen: true,
        description: 'Для того, чтобы сгенерировать служебные ключи, сначала сгенерируйте ключи безопасности.',
        buttons: [
          {
            label: 'Сгенерировать',
            type: ButtonType.primary,
            onClick: handleOnGenerateSecurityKeys,
          },
        ],
      });
    }
  }, [
    closeConfirm,
    generateKeys,
    getKeys,
    handleOnGenerateSecurityKeys,
    isValidSecurityKey,
    system?.secureKeyMode,
    systemId,
  ]);

  const onTryDelete = useCallback(
    (e: React.MouseEvent<HTMLSpanElement>, key: IObjectKey) => {
      e.stopPropagation();
      setConfirmData(
        deleteModal(
          'ключ',
          async () => {
            closeConfirm();
            await deleteKey(serviceKeyUrl(systemId, key.id));
            getKeys();
          },
          closeConfirm
        )
      );
    },
    [closeConfirm, deleteKey, getKeys, systemId]
  );

  const onEditKey = useCallback(
    async (keyAlias: string) => {
      if (editKey) {
        await updateKey(serviceKeyAliasUrl(systemId, editKey.id), { ...editKey, keyAlias });
        setEditKey(null);
        getKeys();
      }
    },
    [editKey, updateKey, systemId, getKeys]
  );

  const onEdit = useCallback((e: React.MouseEvent<HTMLSpanElement>, key: IObjectKey) => {
    e.stopPropagation();
    setEditKey(key);
  }, []);

  const setValuesFromUrl = useCallback(
    (values: any) => {
      onSearch(values.search);
    },
    [onSearch]
  );

  return (
    <div className="service-codes-tab">
      <EditKeyModal editKey={editKey} isOpen={!!editKey} onOk={onEditKey} onCancel={() => setEditKey(null)} />
      <UniversalModal data={confirmData} onClose={closeConfirm} />
      <KeysUtility pageStyle={EPagesWithKeys.keysPage} />
      <FiltersTool
        setValuesFromUrl={setValuesFromUrl}
        rows={[
          [
            {
              type: EFilterTool.search,
              id: 'search',
              props: {
                placeholder: 'Поиск по номеру и псевдониму ключа',
                value: searchValue,
                onSearch: onSearch,
              },
            },
            {
              type: EFilterTool.button,
              hidden: !permissions.edit,
              props: {
                loading: generateKeysLoading,
                hidden: !permissions.create,
                type: ButtonType.outline,
                onClick: tryToGenerateKeys,
                children: 'Сгенерировать',
              },
            },
          ],
        ]}
      />
      <TabsCustom
        emptyText={searchValue ? 'По вашему запросу ничего не найдено' : 'Пока не добавлено ни одного ключа'}
        emptyIcon={searchValue ? <SearchFailIcon /> : <AddNodeIcon />}
        activeTabKey={activeTabId}
        onChangeActiveTab={setActiveTabId}
        loading={keysLoading}
        tabs={keys?.map<ICustomTab>((key) => ({
          tabId: key.id,
          data: [
            {
              fieldTitle: 'Уникальный номер',
              fieldData: key.key,
              extraContent: permissions.edit && (
                <span
                  className="service-codes-tab__extra-icon"
                  role="presentation"
                  onClick={(e) => onTryDelete(e, key)}
                >
                  <TrashIcon />
                </span>
              ),
            },
            {
              fieldTitle: 'Псевдоним',
              fieldData: key.keyAlias,
              extraContent: permissions.edit && (
                <span className="service-codes-tab__extra-icon" role="presentation" onClick={(e) => onEdit(e, key)}>
                  <EditIcon />
                </span>
              ),
            },
          ],
          children: (
            <SectionServiceKeys
              systemId={systemId}
              tabId={tabId}
              activeTabKey={activeTabKey}
              keyId={key.id}
              updateAvailableTabs={updateAvailableTabs}
              permissions={permissions}
            />
          ),
        }))}
      />
      <TabNavButtons
        buttons={tabNavButtonsDefault(
          { isHidden: true },
          { isHidden: true },
          {
            isHidden: !permissions.edit,
            callBack: onNextTab,
            classNameOption: 'service-codes-tab__button-next',
            disabled: !nextTabIsAvailable,
          }
        )}
      />
    </div>
  );
};

export default ServiceKeysTab;
