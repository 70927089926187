import classNames from 'classnames';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Select as SelectComponent } from 'antd';
import ChevronDown from '../../../assets/svg/icons/chevronDown';
import SearchFailIcon from '../../../assets/svg/icons/searchFail';
import ErrorPlaceholder from '../errorPlaceholder';
import Scrollbar from '../scrollbar';
import { ISelect, ISelectOption } from './types';
import CloseIcon from '../../../assets/svg/icons/close';
import Tooltip from '../tooltip';
import InfoIcon from '../../../assets/svg/icons/info';

const { Option } = SelectComponent;

const allOption: any = {
  value: '',
  title: 'Все',
};

const Select: FC<ISelect> = (props) => {
  const {
    searchWithDelay = false,
    title = '',
    containerClassName = '',
    titleClassName = '',
    selectClassName = '',
    value = '',
    defaultValue,
    onChange = () => {},
    options = [],
    labelInValue = false,
    placeholder = '– выберите –',
    disabled = false,
    showArrow = true,
    isRequired = false,
    isError = false,
    errorText = '',
    errorTextClassName = '',
    showSearch = false,
    onSearch = () => {},
    searchValue = '',
    loading = false,
    notFoundContent = <ErrorPlaceholder text="Ничего не найдено" icon={<SearchFailIcon />} />,
    isDisabledStyle = false,
    lengthLimit = true,
    placement,
    onClick = () => {},
    isAllOption = false,
    onLoadData,
    showClear,
    searchMode = false,
    textInfo,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const isOpenRef = useRef(false);

  const [realValue, setRealValue] = useState('');

  const lastCall = useRef<number>();
  const lastCallTimer = useRef<any>();

  useEffect(() => {
    if (realValue !== searchValue) {
      setRealValue(searchValue);
    }
  }, [searchValue]);

  const debounce = useCallback(
    (callback: (a: string) => void, timeoutMs: number) => (val: string) => {
      setRealValue(val);
      const previousCall = lastCall.current;
      lastCall.current = Date.now();
      if (previousCall && lastCall.current - previousCall <= timeoutMs) {
        clearTimeout(lastCallTimer.current);
      }
      lastCallTimer.current = setTimeout(() => callback(val), timeoutMs);
    },
    []
  );

  const handleOnSearch = useCallback(
    (val: string) => {
      onSearch(val, isOpenRef.current);
    },
    [onSearch]
  );

  const handleOnChange = useCallback(
    (currentValue: { value: any; label: React.ReactNode }) => {
      onChange(currentValue.value);
    },
    [onChange]
  );

  const handleOnChangeSelectValue = useCallback(
    (option: ISelectOption) => {
      if (labelInValue) {
        handleOnChange({ value: option.value, label: option.data });
      } else {
        onChange(option.value);
      }
      if (!searchMode || options.length === 1) {
        isOpenRef.current = false;
        setIsOpen(false);
      }
    },
    [handleOnChange, labelInValue, onChange, options.length, searchMode]
  );

  const filteredOptions = useCallback(
    (items: ISelectOption[]) =>
      (isAllOption ? [allOption, ...items] : items).filter((option) => option.value !== value),
    [isAllOption, value]
  );

  const dropdownRender = useMemo(() => {
    const filterOption = filteredOptions(options);

    return (
      <div className="select__dropdown-content">
        {filterOption.length > 0 ? (
          <Scrollbar>
            {filterOption.map((option, index) => (
              <div
                role="presentation"
                key={`select-option-${index}-${option.value}`}
                className={classNames('select__dropdown-item', {
                  'select__dropdown-item_disabled': disabled,
                  'select__dropdown-item_selected': option.value === value,
                })}
                onClick={() => handleOnChangeSelectValue(option)}
              >
                {option.data || option.reactNodeTitle || option.title}
              </div>
            ))}
          </Scrollbar>
        ) : value ? (
          ''
        ) : (
          notFoundContent
        )}
      </div>
    );
  }, [disabled, filteredOptions, handleOnChangeSelectValue, notFoundContent, options, value]);

  const onOpen = useCallback(
    async (open: boolean) => {
      isOpenRef.current = open;
      if (open && onLoadData) {
        await onLoadData();
      }
      setIsOpen(open);
    },
    [onLoadData]
  );

  const handleOnClear = useCallback(() => {
    onChange('');
  }, [onChange]);

  const propsForSelectComponent = useMemo(
    () => ({
      placeholder,
      filterOption: false,
      suffixIcon: <ChevronDown />,
      showArrow,
      className: classNames('select__select', selectClassName),
      showSearch: showSearch,
      onSearch: searchWithDelay ? debounce(handleOnSearch, 500) : onSearch,
      searchValue: realValue,
      labelInValue: labelInValue,
      dropdownClassName: 'select__dropdown',
      dropdownRender: () => dropdownRender,
      dropdownAlign: placement?.includes('top') ? { offset: [0, -6] } : { offset: [0, 6] },
      disabled: disabled || (lengthLimit && value ? options.length === 1 : false),
      loading,
      placement,
      onClick,
      getPopupContainer: (triggerNode: HTMLElement) => triggerNode.parentNode as HTMLElement,
      onDropdownVisibleChange: onOpen,
    }),
    [
      placeholder,
      showArrow,
      selectClassName,
      showSearch,
      searchWithDelay,
      debounce,
      handleOnSearch,
      onSearch,
      realValue,
      labelInValue,
      placement,
      disabled,
      lengthLimit,
      value,
      options.length,
      loading,
      onClick,
      onOpen,
      dropdownRender,
    ]
  );

  const renderChild = useMemo(
    () =>
      filteredOptions(options).map((option, index) => (
        <Option value={option.value} disabled={option.disabled} key={`select-option-${index}-${option.value}`}>
          {option.data || option.title}
        </Option>
      )),
    [filteredOptions, options]
  );

  const findValue = useMemo(() => options.find((option) => option.value === value)?.title, [options, value]);

  return (
    <div
      className={classNames('select', containerClassName, {
        'select__default-disabled': isDisabledStyle,
        'select__clear-icon': showClear,
        'select__hide-dropdown': searchMode && options.length === 0,
        select_error: isError,
      })}
    >
      {title && (
        <span className={classNames('select__title', titleClassName)}>
          {title}
          {isRequired && <span className="select__title-required">*</span>}
          {textInfo && (
            <Tooltip title={textInfo} placement="bottom">
              <div className="select__title-info-icon">
                <InfoIcon />
              </div>
            </Tooltip>
          )}
        </span>
      )}

      {((showClear && value) || (searchMode && value)) && (
        <div
          className={classNames('select__icon-clear-wrapper close-icon', {
            'select__icon-clear-wrapper_search': searchMode,
          })}
          onClick={handleOnClear}
          role="presentation"
        >
          <CloseIcon />
        </div>
      )}

      {labelInValue ? (
        <SelectComponent
          open={isOpen}
          value={{
            value: `${options.find((option) => option.value === value)?.title || value}`,
            label: options.find((option) => option.value === value)?.data || value,
          }}
          defaultValue={{ value: `${defaultValue}`, label: '' }}
          {...propsForSelectComponent}
        >
          {renderChild}
        </SelectComponent>
      ) : (
        <SelectComponent
          open={isOpen}
          value={findValue || (isAllOption ? 'Все' : null)}
          defaultValue={defaultValue}
          {...propsForSelectComponent}
        >
          {renderChild}
        </SelectComponent>
      )}
      {errorText && <span className={classNames('input__error', errorTextClassName)}>{errorText}</span>}
    </div>
  );
};

export default Select;
